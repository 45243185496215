import React from "react";

export default function DetailSection(props){

    const {name,year,occupation,intro,active}=props

    return (<div className="detail-section-wrapper">

        <span style={{animationDelay:"0.5s"}} className={`name ${active ? "animated fadeInRight " : ""} ` }>{name}</span>
        <span style={{animationDelay:"0.5s"}} className={`year ${active ? "animated fadeInRight " : ""} ` }>{year}</span>

        <div style={{animationDelay:"0.5s"}} className={`details ${active ? "animated fadeInLeft " : ""}`} >
            {/* <span className="subtitle">Birth-year: <span className="text">{year}</span></span> */}
            <span className="subtitle">Occupation: <span className="text">{occupation}</span></span>
            <br/>
            <span className="text">{intro}</span>
            {/* <span className="subtitle">Who's Who: <span className="text">{intro}</span></span> */}
        </div>
        

    </div>)
}