import React from "react";
import {btn_white_cross} from "../../utils/images"
import {Carousel} from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {main_data} from ".././store"

const data=main_data.book_launch


export default function BookLaunch(props){
    const {display,setDisplay,setDisplayingOverlay}=props;

    var slides=[];
    
    let index=0;
    for (var item in data){   
        var slide=( <div className="image-wrapper">
            <img alt="img" src={data[index].img}></img>

            <div className="text-wrapper container overflow-hidden">
            <div className="row">
                <div className="offset-1 col-10">
                    <div className="border-bottom-insert">{index+1}/14</div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="offset-1 col-10">
                    <span className='paragraph'>{data[index].cap}</span>
                </div>
            </div>
            </div>
            
        </div> )
        slides.push(slide)
        index+=1;
    }

    var onClose=()=>{
        setDisplay(false);
        setDisplayingOverlay(false);
    }

    if (display===false){
        return(<></>)
    }

    return (<div className="book-launch-container animated fadeIn">

        <div className="container">
            <div className="row">
                <div className="offset-1 col-10">
                    <div className="header w-100 border-bottom-insert text-center">
                        <span>Book launch and exhibition opening ceremony</span>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-10 offset-1">

                    <Carousel infiniteLoop={true} showStatus={false}  showArrows={true} showThumbs={false} showIndicators={false} swipeable={true} emulateTouch={true}>
                       {slides}
                    </Carousel>
      
                </div>
            </div>
        </div>
        <div className="btn-top-right"  >
            <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
        </div>
    </div>)
}