import React,{useState} from "react";

export default function AudioButtons(props){
    const {indexes,playSpeech,positions}=props;

    return (
        <div className="audio-btns-wrapper">
            <div style={{left:positions[0],top:positions[1]}} onClick={()=>{playSpeech(indexes[0])}} className="audio-item">
                <span><i  style={{fontSize:"1.7rem"}} className="fas fa-volume-up "></i></span>
                <span className="paragraph pre-line">{`What is my superpower`}</span>
            </div>

            <div style={{left:positions[2],top:positions[3]}} onClick={()=>{playSpeech(indexes[1])}} className="audio-item">
            <span><i  style={{fontSize:"1.7rem"}} className="fas fa-volume-up "></i></span>
                <span className="paragraph pre-line">{`How do I put
                my superpower to good use`}</span>
            </div>

            <div style={{left:positions[4],top:positions[5]}} onClick={()=>{playSpeech(indexes[2])}} className="audio-item">
                <span><i style={{fontSize:"1.7rem"}}  className="fas fa-volume-up "></i></span>
                <span className="paragraph pre-line">{`Why is this my superpower`}</span>
            </div>
        </div>
    )
}