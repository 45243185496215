import React,{createRef, useState,useEffect, useCallback} from "react";
import {useScreenshot} from "use-react-screenshot";
import {artwork_logo} from "../../utils/images"

import {btn_white_cross,paint_anim,transparent} from "../../utils/images"
import {} from "../../utils/utility"
import $ from 'jquery';

import BackMainButton from "./overlay_content/back_main_button"
import UsefulIcons from "./useful_icons"
import {ROOT_URL} from "../../config"
import ShareModal from "./share_modal"
import {isMobile} from "react-device-detect"

import Loader from "react-loader-spinner";



var canvas;
var c;
var mouse={
    x:undefined,
    y:undefined
};

var colorArray=[
    '#484F73',
    '#7C83A6',
    '#F2EA79',
    '#F2A25C',
    '#F25244',

]
var maxRadius=50;

var requestId;

var circleArray=[];

var dragCoolDownDuration=100;
var lastDrag=0;
export default function ArtTherapy(props){
    const {display,setDisplay,openCallback,closeCallback,isStandalone}=props;

    const [displayCanvas, setDisplayCanvas] = useState(false);
    const [displayWatermark, setDisplayWatermark] = useState(false);
    const [loading, setLoading] = useState(false);

    const [displayEnding,setDisplayEnding]=useState(false);
    const [displayIntro,setDisplayIntro]=useState(true)

    const [endingTimeout,setEndingTimeout]=useState(0)
    const [displayShareModal, setDisplayShareModal] = useState(false);
    const [animation,setAnimation]=useState(transparent)

    const ref = createRef(null);
    const [printScreen, setPrintScreen] = useScreenshot();

    const [imageArt, setImageArt] = useState('');

    const [filename, setFilename] = useState('');

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
</div>)
:   (<BackMainButton display={true}/>);

   

    useEffect(() => {
        if(!isStandalone){

            if(display){
                
                openCallback();
            }
            else{
                
                closeCallback();
            }

        }
        
    }, [display]);


    useEffect(() => {
        
        if(display){
        
        setAnimation(paint_anim)
        
        canvas = document.querySelector('canvas');
        canvas.width=window.innerWidth;
        canvas.height=window.innerHeight;
        c = canvas.getContext('2d');

        
        
        window.addEventListener('resize',handleWindowResize());

    }

        return () => {
            
            if(canvas!==undefined){
            window.removeEventListener('resize',handleWindowResize);
            canvas.removeEventListener('click',handleCanvasClick);
            canvas.removeEventListener('mousemove',handleCanvasMouseMove);
            if(requestId){
            
                window.cancelAnimationFrame(requestId);
                requestId=undefined;
            }
            setDisplayCanvas(false);
            circleArray=[];
        }
           
        };
    }, [display]);


    var onClose=()=>{
        
        // console.log(endingTimeout)
        clearTimeout(endingTimeout);
        setDisplay(false);
        setDisplayEnding(false);
        setDisplayIntro(true);
        setAnimation(transparent)
        
        
    }

    var handleCanvasClick=(event)=>{
        addCircle(mouse.x,mouse.y)
    }

    var handleCanvasMouseMove=(event)=>{
        mouse.x=event.x;
        mouse.y=event.y;
        if(event.buttons===1 && new Date()-lastDrag>=dragCoolDownDuration){
            lastDrag=new Date()
            addCircle(mouse.x,mouse.y)
        }
    }

    var handleWindowResize=()=>{
        canvas.width=window.innerWidth;
        canvas.height=window.innerHeight;
    }

    var handleClick=()=>{
        $('.art-therapy-container').css('opacity','0')
        setDisplayCanvas(true);
        setDisplayIntro(false);

        canvas.addEventListener('click',(event)=>handleCanvasClick(event));
        canvas.addEventListener('mousemove',(event)=>handleCanvasMouseMove(event))
        animate();
        setTimeout(()=>{
            $('.art-therapy-intro-container').css('display','none')
            $('.hint-text').css("opacity","1");
        },2000)

        setTimeout(()=>{
            $('.hint-text').css("opacity","0");
        },5000)

        setEndingTimeout(setTimeout(() => {
            setDisplayEnding(true)
        }, 10000))
    }

    const shareArtwork=(string)=>{
        if(string === "yes"){
            setLoading(true);
            setTimeout(
                setDisplayWatermark(true), 1000
            )
        }
        else {
            setDisplayShareModal(true);
        }
    }

    //reduce image size
    function reduceImage(source, width, height){
        var canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        var context = canvas.getContext("2d");
        var deferred = $.Deferred();
        $("<img/>").attr("src", "data:image/jpeg;base64," + source).on('load',function() {
            context.scale(width/this.width,  height/this.height);
            context.drawImage(this, 0, 0); 
            deferred.resolve(canvas.toDataURL());               
        });
        return deferred.promise();
    }

    useEffect(()=>{
        if(displayWatermark){
            setPrintScreen(ref.current);
            setTimeout(()=>{
                setDisplayWatermark(false);
                setLoading(false);
                setDisplayShareModal(true);
            }, 2000);
            setDisplayWatermark(false);
        }
    }, [displayWatermark])

    useEffect(()=>{
        if(printScreen !== null){
            var imgdata = printScreen.match(/data:(image\/.+);base64,(.+)/);
            reduceImage(imgdata[2], 1200, 600).then(function(newImg){
                setImageArt(newImg);
            });
            var date = new Date();
            var filename = "my_artwork_"+date.getTime()+".jpeg"
            setFilename(filename)
        }
    }, [printScreen])

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone_3.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone3_ComServ.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    if (!display){
        return (<></>)
    }

    return (
    <div className="full-screen full-screen-black-bg__half animated fadeIn position-fixed">

        <div className="z-index-2 container art-therapy-intro-container h-100" style={{display: displayIntro ? "" : "none"}}>

            <div className="row h-100">
                <div className="col-5 d-flex flex-column justify-content-center">

                    <div className="row">
                        <div className="col-12"><h1 className="header overflow-hidden"><span className="border-bottom-insert">The art of healing</span></h1></div>
                    </div>

                    <div className="row mt-1">
                        <div className="col-auto">
                            <p className="paragraph pre-line">{`Tan Tock Seng Hospital (TTSH) uses art as an avenue for their patients to express their experiences and feelings, at times when words fail. 

                                As Dr Eugene Fidelis Soh, Chief Executive Officer at TTSH & Central Health, says, “While we treat our patients with medicine, we can heal with art.” 
                                
                                Have fun on our digital canvas!
                                `}
                            </p>
                        </div>
                    
                    </div>

                    <div className="row mt-3">
                        <div className="col-auto">
                            <div onClick={()=>handleClick()} ><span className="theme-btn theme-hover">Let's Go</span></div>
                        </div>
                    </div>
                    
                </div>

                <div className="offset-2 col-5 d-flex flex-column justify-content-center">
                
                    <div className="row">
                        <div className="col-12">
                            <img  className="paint-anim" alt="paint"  src={animation}></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div ref={ref} className="artcanvas-container">
            <canvas id="art-therapy-canvas" className="art-therapy-canvas" style={{display: displayCanvas ? "block" : 'none'}}/>
            <div className="artwork-border" style={{display: displayWatermark? "":"none"}}/>
            <img className="watermark-logo" src={artwork_logo} style={{display: displayWatermark? "":"none"}}/>
        </div>

        <div className="hint-text container-fluid h-100 position-absolute top-left">
            <div className="row h-100 align-items-center justify-content-center">
                {isMobile? (
                    <div className="col-auto">Tap around and watch something happen</div>
                ):(
                    <div className="col-auto">Click around and watch something happen</div>
                )}
            </div>
            
        </div>

        <div className="art-therapy-ending-container container-fluid position-absolute top-left animated fadeIn full-screen-black-bg__half" style={{display:displayEnding ? "" : "none"}}>

            <div className="row h-100 ">
                <div className="col-12 d-flex flex-column justify-content-center ">

                    <div className="row">
                    <div className="offset-2 col-8 col-md-6 offset-md-3  col-lg-4 offset-lg-4">
                        <div className="header overflow-hidden">
                            <span className="border-bottom-insert">Art is a form of expression.</span>
                        </div>
                    </div>
                    </div>
                    <div className="row mt-2">
                        <div className="offset-2 col-8 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                            <div className="paragraph pre-line">
                                <span>{`Expressing yourself through art is a form of unspoken communication. It inspires others and lets them see who you really are. Just let yourself free.`}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4" >
                        <div className="offset-2 col-8 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                            <button onClick={()=>{setDisplayEnding(false)}} className="theme-btn-auto theme-hover">Back to canvas</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <div className="art-therapy-loading-container container-fluid position-absolute top-left animated fadeIn" style={{display: loading? "" : "none"}}>

            <div className="row h-100 ">
                <div className="col-12 d-flex flex-column justify-content-center ">

                    <div className="row">
                        <div className="offset-2 col-8 col-md-6 offset-md-3  col-lg-4 offset-lg-4">
                            <Loader type="TailSpin" color="#f1b148" height={80} width={80} />
                            <div className="header overflow-hidden">
                                <span className="border-bottom-insert">Preparing your artwork</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <div style={{display:displayEnding ? "none" : ""}}>
            {topRightBtn}
            <div style={{display: displayCanvas? "none" : ""}}>
                <UsefulIcons color="white" displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>shareArtwork("no")} onClickRead={()=>handleReadMore()}></UsefulIcons>
            </div>

            <div style={{display: displayCanvas? "" : "none"}}>
                <UsefulIcons color="white" displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>shareArtwork("yes")} onClickRead={()=>handleReadMore()}></UsefulIcons>
            </div>
        </div>

        {displayCanvas? (
            <ShareModal isArtwork={true} filename={filename} image={imageArt} display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/art-therapy`} des={"Share with your friends!"}></ShareModal>
        ):(
            <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/art-therapy`} des={"Share with your friends!"}></ShareModal>
        )}
        
    </div>)

}


export function AutoCircle(x,y,dx,dy,radius){
    this.x=x;
    this.y=y;
    this.dx=dx;
    this.dy=dy;
    this.radius=radius;
    this.minRadius=radius;
    this.fillStyle=colorArray[Math.floor(Math.random()*colorArray.length)];
  
    this.draw=function(){
        c.beginPath();
        c.arc(this.x,this.y,this.radius,0,Math.PI*2,false)
        c.fillStyle=this.fillStyle
        
        c.fill();
        
    }
  
    this.update=function(){
        if(this.x+this.radius>canvas.width || this.x-this.radius<0){
            this.dx=-this.dx
        }
        if(this.y+this.radius>canvas.height || this.y-this.radius<0){
            this.dy=-this.dy
        }
        this.x=this.x+this.dx;
        this.y=this.y+this.dy;
  
        //interactivity
  
        if(Math.abs(mouse.x - this.x)<50  && Math.abs(mouse.y - this.y)<50){
  
            if (this.radius<maxRadius)
            this.radius+=1
        }
  
        else if(this.radius>this.minRadius){
             
            this.radius-=0.5;
            
        }
  
        this.draw()
        
    }
  }


  function addCircle(x,y){
    
    var radius= Math.random()*20 + 1;
    
    if (x>canvas.width-radius){
        x=canvas.width-radius;
        
    }
    if(x<radius){
        x=radius
        
    }
    if (y>canvas.height-radius){
        y=canvas.height-radius;
        
    }
    if(y<radius){
        y=radius
        
    }
    
    var dx=(Math.random()-0.5);
    var dy=(Math.random()-0.5);
    circleArray.push(new AutoCircle(x,y,dx,dy,radius));
    
}

function animate(){
    requestId=undefined

    if (!requestId){
        requestId=requestAnimationFrame(animate);
    }
    c.clearRect(0,0,canvas.width,canvas.height);
    for (var i =0; i < circleArray.length;i++){
        
        circleArray[i].update();
    
    }
}