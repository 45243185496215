import React,{useState} from "react";
import {community_love_bubble_red,community_love_bubble_yello} from "../../../utils/images"
export default function LoveBubbles(props){
    const {positions,groupIndex} = props;

    const imgArr=[community_love_bubble_red,community_love_bubble_yello]

    
   
    return (<div className={`bubbles-wrapper bubble-group__${groupIndex}`}>
        <img className="bubble animated" style={{left:`${positions[0][0]}%`, top:`${positions[0][1]}%`}} src={imgArr[0]} alt="bubble"></img>
        <img className="bubble animated" style={{left:`${positions[1][0]}%`, top:`${positions[1][1]}%`}} src={imgArr[0]} alt="bubble"></img>
        <img className="bubble animated" style={{left:`${positions[2][0]}%`, top:`${positions[2][1]}%`}} src={imgArr[0]} alt="bubble"></img>
        <img className="bubble animated" style={{left:`${positions[3][0]}%`, top:`${positions[3][1]}%`}} src={imgArr[0]} alt="bubble"></img>
    </div>)

    

    

}
