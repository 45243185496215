import React,{useState,useEffect} from "react";
import { isMobileOnly, isMobile } from "react-device-detect";
import {btn_white_cross, hero_group_mobile, btn_black_cross, mr_tan, background_popup, 
    prize_01, prize_02, prize_03, video_qr,
    sticker_pack, whatsapp_icon, telegram_icon, sticker_qr} from "../../../utils/images"

export default function PopUp(props){
    const {display, setDisplay}=props;

    var onClose=()=>{
        setDisplay(false);
    }

    const tapMeHandler=()=>{
        window.open('http://tcktl.sg/introduction-video', '_blank');
        
    }

    const tapMeStickerHandler=()=>{
        window.open('https://www.givedangerously.today/tcktl-sticker-packs', '_blank');
    }

   if(!display){
        return (<></>)
    }

    return(
        <>
        <div  className="pop-up-bg position-absolute z-index-0" /> 
        <div className="pop-up-container container animated fadeIn">
            <div className="pop-up">
                <img className="bg-img" src={background_popup} alt="Background"/>
                {/* close button */}
                <div className="btn-top-right"  >
                    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
                </div>

                <div className="row">
                    <div className="header-container col-12 d-flex justify-content-start">
                        <div className="header">Psst! While you’re here ...</div>
                    </div>
                </div>

                <div className="row">
                    <div className="popup-details-container col-12 justify-content-center">
                        <div className="details-header col-12">We have something for you.</div>
                        <img className="col-12 sticker-gif" src={sticker_pack} alt="Prize 1" />
                        <div className="sticker-header" style={{padding: "20px 0 0 0"}}>Download our exclusive <br/>sticker pack today!</div>
                        
                        <div className="sticker-download d-flex">
                            {isMobile? (
                                <div className="col-6">
                                    <div className="sticker-qr-container-tap" onClick={()=>{tapMeStickerHandler()}}>
                                        <p>Tap to download!</p>
                                        <img className="qr-code" src={sticker_qr}/>
                                    </div>
                                </div>
                            ):(
                                <div className="col-6">
                                    <div className="sticker-qr-container">
                                        <p>Scan to download!</p>
                                        <img className="qr-code" src={sticker_qr}/>
                                    </div>
                                </div>
                            )}
                            <div className="col-6 available-text-container">
                                <div className="row">
                                    <div className="paragraph">Available now on:</div>
                                </div>
                                <div className="row">
                                    <img className="icon-app" src={telegram_icon} /> 
                                    <div className="paragraph app-name">Telegram</div>
                                </div>
                                <div className="row">
                                    <img className="icon-app" src={whatsapp_icon} /> 
                                    <div className="paragraph app-name">WhatsApp</div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="d-flex prize02-03-container">
                            <div className="prize-02-container col-6">
                                <img className="prize-02" src={prize_02} alt="Prize 2" />
                                <div className="prize-header">Are you one of the first 50 participants?</div>
                                <div className="small-text">Receive a pair of tickets to the Lee Kong Chian Natural History Museum!</div>
                            </div>

                            <div className="prize-03-container col-6">
                                <img className="prize-03" src={prize_03} alt="Prize 3" />
                                <div className="prize-header">Want to upgrade your Netflix experience?</div>
                                <div className="small-text">Win a LUMOS Ray Smart Projector for for more enjoyable chillaxing sessions.</div>
                            </div>
                        </div>

                        <div className="header">...and other attractive prizes <br/>worth up to $500!</div>
                        <div className="instructions-bg">
                            <div className="medium-text">
                            <b className="medium-large-text">Share with us about your favourite part</b><br/>of the tour you could win one or more of these prizes.
                            <br/><br/>For more information about the giveaway, <br/>check out the side panel in the tour.
                            </div>
                        </div> */}
                        
                        <div className="header montserrat" style={{paddingBottom: "0"}}>Scan and listen to the <br/>opening speech of our Founder <br/>and Managing Director, <br/>Mr HH Tan!</div>

                        <div className="col-12" style={{height: "246px"}}>
                            <div className="image-container">
                                <div className="mr-tan-img"><img src={mr_tan} alt="Mr Tan Hsuan Heng"/></div>
                                {/* <img className="ar-video-qr" src={tommy_koh_ar_2}/> */}
                                {isMobileOnly? (
                                    <div className="qr-container-tap row" onClick={()=>tapMeHandler()}>
                                        <p>Tap me!</p>
                                        <img className="video-qr" src={video_qr}/>
                                    </div>
                                ):(
                                    <div className="qr-container row">
                                        <p>Hover & <br/>Scan me!</p>
                                        <img className="video-qr" src={video_qr}/>
                                    </div>
                                )}
                            </div>

                            <div className="mr-tan-text">
                                <div className="mr-tan-name pre-line">
                                    Mr Tan Hsuan Heng
                                </div>
                                <div className="paragraph pre-line">
                                    Founder and Managing Director<br/>
                                    Give Dangerously
                                </div>
                                <div className="photo-credit">
                                    (Photo courtesy of Community Chest/ONEfor10)
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="header montserrat" style={{padding: "0", marginTop: "100px"}}>
                                Celebrate the spirit of giving <br/>
                                and be part of the legacy now!
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="copyright">
                                &#169; Give Dangerously 2021. All rights reserved.
                            </div>

                            <div className="hol">
                                Powered by <a className="destyled-link" href="https://www.hol.sg/" target="_blank" rel="noopener noreferrer"><span>HOL Experiences</span></a>
                            </div>

                            <div className="col-lg-12 col-md-10 col-12 begin-btn-container">
                                    <button className="begin-btn btn m-auto" onClick={()=>onClose()}>Begin The Tour</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}