import React,{useState} from "react";
import { useEffect } from "react";
import {btn_white_cross,ending_heart_red,ending_heart_yellow} from "../../utils/images"
import Matter  from "matter-js"
import { isMobileOnly } from "react-device-detect";

const Engine = Matter.Engine,
Render = Matter.Render,
Runner = Matter.Runner,
Common = Matter.Common,
MouseConstraint = Matter.MouseConstraint,
Mouse = Matter.Mouse,
World = Matter.World,
Vertices = Matter.Vertices,
Svg = Matter.Svg,
Bodies = Matter.Bodies,
Body=Matter.Body,
Composites=Matter.Composites,
Events = Matter.Events;

var engine;
var world;
var render;
var runner;
var mouse;
var mouseConstraint;
var hearts=[]
var heart_radius=10;
var texture_scale=0.5;
var textures=[ending_heart_red,ending_heart_yellow]

export default function Ending(props){
    const {display,setDisplay}=props;

    const [played,setPlayed]=useState(false)
    const [initialized, setInitialized] = useState(false);
    

    var onClose=()=>{
        setDisplay(false);
        setPlayed(true);
        setInitialized(false)
        Engine.clear(engine);
        Render.stop(render);
        Runner.stop(runner);
        render.canvas.remove();
        render.canvas = null;
        render.context = null;
        render.textures = {};
        hearts=[];
        
        
    }

    useEffect(() => {

        if(display&&!initialized){
            initRadius();
            initializeCanvas();
            
        }

        return () => {
            
        };
    }, [display]);


    function initRadius(){
        if (window.innerWidth>=1200){
            heart_radius=55;
            texture_scale=0.5;
        }
        
        else if (window.innerWidth<1200 && window.innerWidth>=1000){
            heart_radius=55;
            texture_scale=0.5;
        }
        
        else if (window.innerWidth<1000 && window.innerWidth>=768){
            heart_radius=35;
            texture_scale=0.35;
        }
        
        else if (window.innerWidth<768){
            heart_radius=25;
            texture_scale=0.25;
        }
    }

    function initializeCanvas(){
        setInitialized(true);
        engine=Engine.create();
        world=engine.world;
        world.gravity.y=-0.05;
        render = Render.create(
            {
              element: document.querySelector('.canvas-container'),
              engine: engine,
              options:{
                width: Math.round(window.innerWidth),
                height: Math.round(window.innerHeight),
                background: 'transparent',
                showAngleIndicator: false,
                wireframes: false,
                //background: "#ffffff",
                //wireframeBackground:"transparent"  
              }
            }
          );
          
          runner=Runner.create();
          mouse = Mouse.create(render.canvas);
          render.mouse = mouse;
          mouseConstraint = MouseConstraint.create(engine, {
              mouse: mouse,
              constraint: {
                  stiffness: 0.2,
                  render: {
                      visible: false
                  }
              }
          });
  
          World.add(world, mouseConstraint);
        //   Render.lookAt(render, {
        //     min: { x: -50, y: -200 },
        //     max: { x: window.innerWidth+50, y: window.innerHeight+200 }
        //   });

          spawnHearts();

          Events.on(engine,'beforeUpdate',function(event){
            for(var i=0;i<hearts.length;i++){
                if(hearts[i].position.y<-window.innerHeight){
                    var newX=Math.random()*(window.innerWidth-(2*heart_radius))+heart_radius;
                    var newY=window.innerHeight+Math.random()*window.innerHeight;
                    Body.setPosition(hearts[i],Matter.Vector.create(newX,newY))
                }
            }
            

        })

        runMatter();
        

    }


    function spawnHearts(){
    
        for (var i =0;i<50;i++){

            
            var radius=heart_radius;
            var x = Math.random()*(window.innerWidth-(2*radius))+radius
            var y = Math.random()*2*window.innerHeight+window.innerHeight

            var texture=textures[i%2]
            var frictionAir=Math.random()*0.01+0.01
            
            var body=Bodies.circle(x,y,radius,{
                density: 0.000001,
                frictionAir: frictionAir,
                restitution: 0.3,
                friction: 0.01,
                
            
                render: {
                    
                    sprite:{
                        texture:texture,
                        xScale:texture_scale,
                        yScale:texture_scale
                        
                    }
                    
                }})
            
            hearts.push(body);
            
        }
        World.add(world,hearts)

        
    }

    function runMatter(){
        Render.run(render);
        Runner.run(runner, engine);
    }

    if(!display || played){
        return null;
    }

    return(
        <div>
            {isMobileOnly? (
            <div className="ending-container-mobile animated fadeIn">
                <div className="canvas-container"></div>
                <div className="container animated fadeIn delay-4s unclickable">
                    <div className="row d-flex justify-content-center">
                        <div className="col-auto">
                            <div className="header border-bottom-insert text-center">
                                <span>Thank you for staying with us till the end</span>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2 d-flex justify-content-center">
                        <div className="col-auto">
                            <div className="paragraph text-center pre-line">
                                Amazing! You’ve made it all the way to the end of the exhibition.<br/>
                                However, <i>The Char Kway Teow Legacy</i> does not end here.
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5 d-flex justify-content-center">
                        <div className="col-4 d-flex justify-content-end">
                            <a href="https://www.givedangerously.today/" target="_blank" rel="noopener noreferrer">
                                <button className="theme-btn-auto theme-hover clickable">Visit our website</button>
                            </a>
                        </div>
                        <div className="col-4">
                            <button onClick={()=>{onClose()}} className="theme-btn-auto theme-hover clickable">Back to exhibition</button>
                        </div>
                    </div>
                </div>
                <div className="btn-top-right"  >
                    <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
                </div>
            </div>
            ):(
            <div className="ending-container animated fadeIn">
                <div className="canvas-container"></div>
                <div className="container animated fadeIn delay-4s unclickable">
                    <div className="row d-flex justify-content-center">
                        <div className="col-auto">
                            <div className="header border-bottom-insert text-center">
                                <span>Thank you for staying with us till the end</span>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2 d-flex justify-content-center">
                        <div className="col-auto">
                            <div className="paragraph text-center pre-line">
                                Amazing! You’ve made it all the way to the end of the exhibition.<br/>
                                However, <i>The Char Kway Teow Legacy</i> does not end here.
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5 d-flex justify-content-center">
                        <div className="col-4 d-flex justify-content-end">
                            <a href="https://www.givedangerously.today/" target="_blank" rel="noopener noreferrer">
                                <button className="theme-btn-auto theme-hover clickable">Visit our website</button>
                            </a>
                        </div>
                        <div className="col-4">
                            <button onClick={()=>{onClose()}} className="theme-btn-auto theme-hover clickable">Back to exhibition</button>
                        </div>
                    </div>
                </div>
                <div className="btn-top-right"  >
                    <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
                </div>
            </div>
            )}
        </div>)
}