//Background
export const overlay_bg_olive = require("../static/image/overlay_bg/overlay_bg_olive.png")

//Landscape Check
//export const rotate_phone = require("../static/image/mobile/rotate_device.gif")
export const rotate_phone = require("../static/image/mobile/landscape-mode.webp")

//Welcome
export const welcome_img_mrtan = require("../static/image/welcome/img_mrtan.png")
export const welcome_img_cjkoh = require("../static/image/welcome/img_cjkoh.png")
export const welcome_img_irenesendoff = require("../static/image/welcome/img_irenesendoff.png")
export const welcome_img_beach = require("../static/image/welcome/img_beach.png")
export const welcome_img_mrtan_irenetan = require("../static/image/welcome/img_mrtan_irenetan.png")


//causes
export const ellipse_01=require("../static/image/causes/ellipse_01.png")
export const ellipse_02=require("../static/image/causes/ellipse_02.png")
export const ellipse_03=require("../static/image/causes/ellipse_03.png")
export const ellipse_04=require("../static/image/causes/ellipse_04.png")
export const arrow_down=require("../static/image/causes/arrow_down.png")
export const arrow_up=require("../static/image/causes/arrow_up.png")
export const pie_all=require("../static/image/causes/pie_all.png")

//beginning
export const begin_1997 = require("../static/image/beginning/begin_1997.png")
export const begin_bdcard = require("../static/image/beginning/begin_bdcard.png")
export const begin_ckt = require("../static/image/beginning/begin_ckt.png")
export const begin_ttongcjkoh = require("../static/image/beginning/begin_ttongcjkoh.png")
export const begin_weekimwee = require("../static/image/beginning/begin_weekimwee.png")


//founders
export const founder_cjkohpaintings = require("../static/image/founders/founder_cjkohpaintings.png")
export const founder_cjpradap = require("../static/image/founders/founder_cjpradap.png")
export const founder_irenebday = require("../static/image/founders/founder_irenebday.png")
export const founder_ongttirenetan = require("../static/image/founders/founder_ongttirenetan.png")
export const founder_youngcj = require("../static/image/founders/founder_youngcj.png")


//four_cards
export const who_irenetan=require("../static/image/four_cards/irenetan_img.png")
export const who_mrtan=require("../static/image/four_cards/mrtan_img.png")
export const who_cjkoh=require("../static/image/four_cards/cjkoh_img.png")
export const who_ongtt=require("../static/image/four_cards/ongtt_img.png")

export const who_chillipaste=require("../static/image/four_cards/chillipaste.webp")
export const who_porkfat=require("../static/image/four_cards/porklard.webp")
export const who_cockles=require("../static/image/four_cards/cockles.webp")
export const who_ckt = require("../static/image/four_cards/kwayteow.webp")

export const who_front_bg=require("../static/image/four_cards/who_front_bg.png")
export const who_back_bg=require("../static/image/four_cards/who_back_bg.png")




//timeline
export const timeline_ongttirenetan = require("../static/image/timeline/ongttirenetan.png");
export const timeline_tanhh_giving = require("../static/image/timeline/tanhh_giving.png")
export const timeline_bg = require("../static/image/timeline/timeline_bg.png")
export const timeline_cjkohongtt = require("../static/image/timeline/timeline_cjkohongtt.png")
export const timeline_cjkohongtt_flip = require("../static/image/timeline/timeline_cjkohongtt_flip.png")
export const timeline_line_all = require("../static/image/timeline/timeline_line_all.png")
export const timeline_voiceover_button = require("../static/image/timeline/voiceover_button.png")
export const timeline_weekimwee = require("../static/image/timeline/weekimwee.png")
export const timeline_whygive = require("../static/image/timeline/whygive.png")

//Buttons
export const btn_white_cross = require("../static/image/button/white_cross.png")
export const btn_black_cross = require("../static/image/button/black_cross.png")
export const mute_icon=require("../static/image/tour/muteIcon.png");
export const unmute_icon=require("../static/image/tour/unmuteIcon.png");

//community help

export const community_love_bubble_red = require("../static/image/community_service_help/heart-red.webp")
export const community_love_bubble_yello = require("../static/image/community_service_help/heart-yellow.webp")
export const community_heart_end = require("../static/image/community_service_help/heart_end.webp")
export const community_singapore_map = require("../static/image/community_service_help/island_grey.webp")
export const community_hand=require("../static/image/community_service_help/comserv_hand.png")

//comm service
export const comserv_graphic=require("../static/image/community_service_intro/comserv_graphic.webp")


//dementia
export const dementia_card_front = require("../static/image/dementia/dcard_front.webp")
export const dementia_healthcare_heart = require("../static/image/dementia/healthcare_heart.webp")

export const dementia_card_01=require("../static/image/dementia/dcard_ckt.webp")
export const dementia_card_02=require("../static/image/dementia/dcard_comm.webp")
export const dementia_card_03=require("../static/image/dementia/dcard_edu.webp")
export const dementia_card_04=require("../static/image/dementia/dcard_give.webp")
export const dementia_card_05=require("../static/image/dementia/dcard_legal.webp")
export const dementia_card_06=require("../static/image/dementia/dcard_olive.webp")


//superhero
export const hero_student_03= require("../static/image/super_hero/satv_front.webp")
export const hero_student_02= require("../static/image/super_hero/yz_front.webp")
export const hero_student_01= require("../static/image/super_hero/gabr_front.webp")

export const hero_student_color_03= require("../static/image/super_hero/satv_color.png")
export const hero_student_color_02= require("../static/image/super_hero/yz_color.webp")
export const hero_student_color_01= require("../static/image/super_hero/gabr_color.png")

export const hero_student_animation_03= require("../static/image/super_hero/satviender.gif")
export const hero_student_animation_02= require("../static/image/super_hero/Yizheng.gif")
export const hero_student_animation_01= require("../static/image/super_hero/gabriel.gif")


//education intro
export const classroom_01= require("../static/image/education_intro/edu_classroom.webp")
export const classroom_02= require("../static/image/education_intro/edu_kitchen.webp")
export const classroom_03= require("../static/image/education_intro/edu_museum.webp")
export const edu_student=require("../static/image/education_intro/edu_students.webp")

//story cards
export const story_card_01=require("../static/image/what_legacy/what_img1.webp")
export const story_card_02=require("../static/image/what_legacy/what_img2.webp")
export const story_card_03=require("../static/image/what_legacy/what_img3.webp")
export const story_card_04=require("../static/image/what_legacy/what_img4.webp")

//mt everest
// export const mt_anim_idle=require("../static/image/mt_everest/mt_aim-idle");
export const ME_pic_1=require("../static/image/mt_everest/basecamp.gif")
export const ME_pic_2=require("../static/image/mt_everest/camp1and2.gif")
export const ME_pic_3=require("../static/image/mt_everest/summit.gif")
export const ME_pic_4=require("../static/image/mt_everest/summit.gif")
export const ME_ending_pic=require("../static/image/mt_everest/ending.webp")


//misc
export const misc_glow=require("../static/image/misc/glow.png")
export const transparent=require("../static/image/misc/transparent.png")



//TCM
export const TCM_ear=require("../static/image/TCM/ear.png")
export const TCM_ear_with_lines=require("../static/image/TCM/ear_with_lines.png")
export const TCM_ear_with_des=require("../static/image/TCM/ear_with_description.webp")

export const TCM_ear_diagram=require("../static/image/TCM/ear_diagram.webp")
export const TCM_ear_how_01=require("../static/image/TCM/ear_how1.webp")
export const TCM_ear_how_02=require("../static/image/TCM/ear_how2.webp")
export const TCM_ear_how_03=require("../static/image/TCM/ear_how3.webp")
export const TCM_ear_how_04=require("../static/image/TCM/ear_how4.webp")

export const TCM_acidreflux=require("../static/image/TCM/acidreflux.png");
export const TCM_acne=require("../static/image/TCM/acne.png")
export const TCM_common=require("../static/image/TCM/commoncough.png")
export const TCM_constipation=require("../static/image/TCM/constipation.png")
export const TCM_diabetes=require("../static/image/TCM/diabetes.png")
export const TCM_diarrhoea=require("../static/image/TCM/diarrhoea.png")
export const TCM_dryeyes=require("../static/image/TCM/dryeyes.png")
export const TCM_eczema=require("../static/image/TCM/eczema.png")
export const TCM_fatigue=require("../static/image/TCM/fatigue.png")
export const TCM_generalpain=require("../static/image/TCM/generalpain.png")
export const TCM_gsc=require("../static/image/TCM/generalskinconditions.png")

export const TCM_hbp=require("../static/image/TCM/highbloodpressure.png")
export const TCM_headache=require("../static/image/TCM/headache.png")
export const TCM_insomnia=require("../static/image/TCM/insomnia.png")
export const TCM_myopia=require("../static/image/TCM/myopia.png")
export const TCM_obesity=require("../static/image/TCM/obesity.png")
export const TCM_stomachache=require("../static/image/TCM/stomachache.png");
export const TCM_withdrawal=require("../static/image/TCM/withdrawalsyndrome.png")


//caregiver
export const D_svg=require("../static/image/caregiver/D .svg")
export const o_svg=require("../static/image/caregiver/o .svg")
export const y_svg=require("../static/image/caregiver/y .svg")
export const u_svg=require("../static/image/caregiver/u .svg")
export const t_svg=require("../static/image/caregiver/t .svg")
export const h_svg=require("../static/image/caregiver/h .svg")
export const i_svg=require("../static/image/caregiver/i .svg")
export const n_svg=require("../static/image/caregiver/n .svg")
export const k_svg=require("../static/image/caregiver/k .svg")
export const a_svg=require("../static/image/caregiver/a .svg")
export const r_svg=require("../static/image/caregiver/r .svg")
export const e_svg=require("../static/image/caregiver/e .svg")
export const A_capital_svg=require("../static/image/caregiver/A_capital.svg")

export const c_yellow_svg=require("../static/image/caregiver/c_yellow.svg")
export const a_yellow_svg=require("../static/image/caregiver/a_yellow.svg")
export const r_yellow_svg=require("../static/image/caregiver/r_yellow.svg")
export const e_yellow_svg=require("../static/image/caregiver/e_yellow.svg")
export const g_yellow_svg=require("../static/image/caregiver/g_yellow.svg")
export const i_yellow_svg=require("../static/image/caregiver/i_yellow.svg")
export const v_yellow_svg=require("../static/image/caregiver/v_yellow.svg")

export const questionmark_svg=require("../static/image/caregiver/questionmark.svg")


export const D_png=require("../static/image/caregiver/D .png")
export const o_png=require("../static/image/caregiver/o .png")
export const y_png=require("../static/image/caregiver/y .png")
export const u_png=require("../static/image/caregiver/u .png")
export const t_png=require("../static/image/caregiver/t .png")
export const h_png=require("../static/image/caregiver/h .png")
export const i_png=require("../static/image/caregiver/i .png")
export const n_png=require("../static/image/caregiver/n .png")
export const k_png=require("../static/image/caregiver/k .png")
export const a_png=require("../static/image/caregiver/a .png")
export const r_png=require("../static/image/caregiver/r .png")
export const e_png=require("../static/image/caregiver/e .png")
export const A_capital_png=require("../static/image/caregiver/A_capital.png")

export const c_yellow_png=require("../static/image/caregiver/c_yellow.png")
export const a_yellow_png=require("../static/image/caregiver/a_yellow.png")
export const r_yellow_png=require("../static/image/caregiver/r_yellow.png")
export const e_yellow_png=require("../static/image/caregiver/e_yellow.png")
export const g_yellow_png=require("../static/image/caregiver/g_yellow.png")
export const i_yellow_png=require("../static/image/caregiver/i_yellow.png")
export const v_yellow_png=require("../static/image/caregiver/v_yellow.png")

//art-therapy
export const paint_anim = require("../static/image/art_therapy/paint_anim.gif")
export const artwork_logo = require("../static/image/art_therapy/arttherapy_logo.png")

//superpower
export const arrow_btn=require("../static/image/superpower/arrow_btn.png")


//intro-options

export const tan_ireneTan=require("../static/image/intro_option/MrTan_IreneTan_Airport.png")
export const char_kway_teow_1=require("../static/image/intro_option/char_kway_teow_1.png")
export const panel_questionmark=require("../static/image/intro_option/panel_questionmark.png")

//sidebar
export const sidebar_bg=require("../static/image/sidebar/sidebar_bg.webp")


//library
export const library=require("../static/image/library/library.png")





//sidebar

//feedback

export const feedback_leaf_white=require("../static/image/sidebar/feedback/leaf_white.png");
export const feedback_leaf_yellow=require("../static/image/sidebar/feedback/leaf_yellow.png");

//booklaunch
export const book_launch_opening_01=require("../static/image/sidebar/book_launch/opening_01.webp");
export const book_launch_opening_02=require("../static/image/sidebar/book_launch/opening_02.webp");
export const book_launch_opening_03=require("../static/image/sidebar/book_launch/opening_03.webp");
export const book_launch_opening_04=require("../static/image/sidebar/book_launch/opening_04.webp");
export const book_launch_opening_05=require("../static/image/sidebar/book_launch/opening_05.webp");
export const book_launch_opening_06=require("../static/image/sidebar/book_launch/opening_06.webp");
export const book_launch_opening_07=require("../static/image/sidebar/book_launch/opening_07.webp");
export const book_launch_opening_08=require("../static/image/sidebar/book_launch/opening_08.webp");
export const book_launch_opening_09=require("../static/image/sidebar/book_launch/opening_09.webp");
export const book_launch_opening_10=require("../static/image/sidebar/book_launch/opening_10.webp");
export const book_launch_opening_11=require("../static/image/sidebar/book_launch/opening_11.webp");
export const book_launch_opening_12=require("../static/image/sidebar/book_launch/opening_12.webp");
export const book_launch_opening_13=require("../static/image/sidebar/book_launch/opening_13.webp");
export const book_launch_opening_14=require("../static/image/sidebar/book_launch/opening_14.webp");

//ack schools
export const ack_schools_01=require("../static/image/sidebar/ack_schools/school_01.webp");
export const ack_schools_02=require("../static/image/sidebar/ack_schools/school_02.webp");
export const ack_schools_03=require("../static/image/sidebar/ack_schools/school_03.webp");
export const ack_schools_04=require("../static/image/sidebar/ack_schools/school_04.webp");

//ack orgs
export const ack_orgs_01=require("../static/image/sidebar/ack_orgs/organisations.webp");

//olive branch
export const olive_branch_white=require("../static/image/sidebar/olive_branch/olivebranch_white.webp");

//causes
export const causes_pie_chart = require("../static/image/sidebar/causes/causes.webp");

//map
export const map=require("../static/image/sidebar/map/map_bg.webp");
export const h1_intro=require("../static/image/sidebar/map/h1_intro.webp")
export const h2_who=require("../static/image/sidebar/map/h2_who.webp")
export const h3_what=require("../static/image/sidebar/map/h3_what.webp")
export const h4_legaled=require("../static/image/sidebar/map/h4_legaled.webp")
export const h5_superhero=require("../static/image/sidebar/map/h5_superhero.webp")
export const h6_lawlib=require("../static/image/sidebar/map/h6_lawlib.webp")
export const h7_comserv=require("../static/image/sidebar/map/h7_comserv.webp")
export const h8_give=require("../static/image/sidebar/map/h8_give.webp")
export const h9_arttherapy=require("../static/image/sidebar/map/h9_arttherapy.webp")
export const h10_howtohelp=require("../static/image/sidebar/map/h10_howtohelp.webp")
export const h11_edu=require("../static/image/sidebar/map/h11_edu.webp")
export const h12_mtev=require("../static/image/sidebar/map/h12_mtev.webp")
export const h13_nie=require("../static/image/sidebar/map/h13_nie.webp")
export const h14_caregiver=require("../static/image/sidebar/map/h14_caregiver.webp")
export const h15_tcm=require("../static/image/sidebar/map/h15_tcm.webp")
export const h16_dementia=require("../static/image/sidebar/map/h16_dementia.webp")

export const h1_intro_bw=require("../static/image/sidebar/map/bw/h1_intro_bw.webp")
export const h2_who_bw=require("../static/image/sidebar/map/bw/h2_who_bw.webp")
export const h3_what_bw=require("../static/image/sidebar/map/bw/h3_what_bw.webp")
export const h4_legaled_bw=require("../static/image/sidebar/map/bw/h4_legaled_bw.webp")
export const h5_superhero_bw=require("../static/image/sidebar/map/bw/h5_superhero_bw.webp")
export const h6_lawlib_bw=require("../static/image/sidebar/map/bw/h6_lawlib_bw.webp")
export const h7_comserv_bw=require("../static/image/sidebar/map/bw/h7_comserv_bw.webp")
export const h8_give_bw=require("../static/image/sidebar/map/bw/h8_give_bw.webp")
export const h9_arttherapy_bw=require("../static/image/sidebar/map/bw/h9_arttherapy_bw.webp")
export const h10_howtohelp_bw=require("../static/image/sidebar/map/bw/h10_howtohelp_bw.webp")
export const h11_edu_bw=require("../static/image/sidebar/map/bw/h11_edu_bw.webp")
export const h12_mtev_bw=require("../static/image/sidebar/map/bw/h12_mtev_bw.webp")
export const h13_nie_bw=require("../static/image/sidebar/map/bw/h13_nie_bw.webp")
export const h14_caregiver_bw=require("../static/image/sidebar/map/bw/h14_caregiver_bw.webp")
export const h15_tcm_bw=require("../static/image/sidebar/map/bw/h15_tcm_bw.webp")
export const h16_dementia_bw=require("../static/image/sidebar/map/bw/h16_dementia_bw.webp")


//logo
export const CKTL_logo=require("../static/image/logo/CKTL_Logo.webp")
export const CKTL_logo_subheader=require("../static/image/logo/CKTL_Logo_Subheader.webp")


//tutorial
export const icon_help=require("../static/image/misc/icon_help.webp")
export const tutorial_ss=require("../static/image/tutorial/tutorial.webp")

//ending
export const ending_heart_red=require("../static/image/ending/heart_red.png")
export const ending_heart_yellow=require("../static/image/ending/heart_yellow.png")

//mobile version
//// superheros
export const hero_group_mobile= require("../static/image_mobile/superheros/suss_combined.webp")
export const hero_student_03_mobile= require("../static/image_mobile/superheros/satv_m2.webp")
export const hero_student_02_mobile= require("../static/image_mobile/superheros/yz_m2.webp")
export const hero_student_01_mobile= require("../static/image_mobile/superheros/gab_m2.webp")

export const hero_student_animation_03_mobile= require("../static/image_mobile/superheros/satv_m1.gif")
export const hero_student_animation_02_mobile= require("../static/image_mobile/superheros/yz_m1.gif")
export const hero_student_animation_01_mobile= require("../static/image_mobile/superheros/gabriel_m1.gif")

//// map
export const map_mobile=require("../static/image_mobile/tour/map_mobile.webp")

//// tutorial
export const tutorial_mobile=require("../static/image_mobile/tutorial/tutorial_mobile.webp")

//// sidebar
export const ack_orgs_mobile=require("../static/image_mobile/sidebar/org_mobile.png")

//// sidebar
export const low_power_icon=require("../static/image_mobile/lowpowermode/low-power.png")

//// virtual launch
export const mr_tan=require("../static/launch/sidebar/1.0 mr tan.png")

// export const flip_01=require("../static/launch/sidebar/2.1 need for speed-timeclock.png")
// export const flip_02=require("../static/launch/sidebar/3.1 pop corn frame.png")
// export const flip_03=require("../static/launch/sidebar/4.1 getaway and chill beach chair.png")
// export const flip_04=require("../static/launch/sidebar/5.1 shop at home-trolley.png")

// export const flipback_01=require("../static/launch/sidebar/2.2 NEED FOR SPEED-LKC MUSEUM.png")
// export const flipback_02=require("../static/launch/sidebar/3.2 CHILL AT HOME-APPLE TV.png")
// export const flipback_03=require("../static/launch/sidebar/4.2 GET AWAY AND CHILL PIC.png")
// export const flipback_04=require("../static/launch/sidebar/5.2 Shop at home grabshopee.png")

export const sticker_pack=require("../static/launch/sidebar/6.0 telegram-final-stickers gif.gif")
export const whatsapp_icon=require("../static/launch/sidebar/6.3 whatsapp logo.png")
export const telegram_icon=require("../static/launch/sidebar/6.2 telegram logo.png")
export const sticker_qr=require("../static/launch/sidebar/sticker-pack-qr.png")

export const background_popup=require("../static/launch/popup/0.bg with olive branch.png")
export const prize_01=require("../static/launch/popup/staycation.gif")
export const prize_02=require("../static/launch/popup/3. LKC museum pic.png")
export const prize_03=require("../static/launch/popup/4.apple tv picture.png")
export const tommy_koh_ar_2=require("../static/launch/popup/qr.png")

export const minimise_video=require("../static/launch/accept.png")
export const decline_btn=require("../static/launch/decline.png")
export const remind_btn=require("../static/launch/remind_icon.png")
export const message_btn=require("../static/launch/message_icon.png")
export const battery_icon=require("../static/launch/battery_icon.png")
export const camera_icon=require("../static/launch/camera.png")
export const mute_call=require("../static/launch/mute.png")
export const flip_icon=require("../static/launch/flip.png")
export const end_btn=require("../static/launch/end.png")
export const video_qr=require("../static/launch/lauch-video-QRcode.png")

export const mr_tan_bg=require("../static/launch/launch_video.mp4")
