import React from "react";
import {isMobileOnly} from "react-device-detect"

export default function TUtorialCard(props){

    const {title,text,image,delay}=props

    return (
        <div className="tutorial-card-container container d-flex flex-column">
            {isMobileOnly ? (
                <div className='mobile-tutorial-card'>

                    <div className="row">

                        <div className="header  col-12">
                            <span>{title}</span>
                        </div>
                    </div>


                    <div className="row ">
                        <div className="col-12 ">
                            <div className="paragraph overflow-hidden border-left-insert">
                            <span style={{animationDelay:delay}}>
                                {text}
                            </span>
                            </div>
                        </div>
                    </div>

                    <div className="row flex-grow-1 img-wrapper align-items-end">
                        <img className="col-12 p-0" alt="img" src={image}></img>
                    </div>
                </div>
            ):(
                <div>
                    <div className="row">

                        <div className="header  col-12">
                            <span>{title}</span>
                        </div>
                    </div>


                    <div className="row ">
                        <div className="col-12 ">
                            <div className="paragraph overflow-hidden border-left-insert">
                            <span style={{animationDelay:delay}}>
                                {text}
                            </span>
                            </div>
                        </div>
                    </div>

                    <div className="row flex-grow-1 align-items-end">
                        <img className="col-12 p-0" alt="img" src={image}></img>
                    </div>
                </div>
            )}
        </div>
    )
}