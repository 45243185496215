import React,{useEffect} from "react";
import {isMobile} from 'react-device-detect';
import UsefulIcons from "./useful_icons";
import {btn_white_cross,comserv_graphic} from "../../utils/images"
import {setStateWithDelay,navigateFunctionSet} from "../../utils/utility"

export default function CommunityServiceIntro(props){
    const {display,setDisplay,setDisplayWicare,sdk,openCallback,closeCallback}=props;

    useEffect(() => {

        
        if(display){
            openCallback();
        }
        else{
            closeCallback();
        }
        
        
        return () => {
            
        };
    }, [display]);
    
    var onClose=()=>{
        
        setDisplay(false);
        
    }

    var navigateToWicare=()=>{
        onClose()
        
        navigateFunctionSet(sdk,"c4ZQGaWlcAH")
        
        setStateWithDelay(setDisplayWicare,true,1500)
    }

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone_3.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone3_ComServ.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    useEffect(() => {

        

        return () => {
        };
    }, [display]);
    
    if (display===false ){
        return(<></>)
    }

    return (<div className="full-screen animated fadeIn">
        <div className="full-screen-black-bg__half position-absolute z-index-0 ">

        
        </div>
        <div className="z-index-2 container community-service-intro-container h-100">
            
        <div className="row h-100">
                <div  className="col-5 left d-flex flex-column justify-content-center">
                    
                    <div className="container p-0 state__0 animated fadeIn">
                        <div className="row mt-4">
                            <div className="col-12 ">
                                <h1 className="header overflow-hidden"><span className="border-bottom-insert">Community services</span></h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <p className="paragraph pre-line">
                                    {`The English word “philanthropy” comes from the Greek word “philanthrôpia”, which means “love for mankind”. 

Indeed, the giving philosophy of Irene, Ong and CJ was rooted in their empathy and compassion for humanity. They donated widely, out of pure altruism, to enable the disadvantaged in the community.
`}
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-auto">
                                <div onClick={()=>{navigateToWicare()}} className="theme-btn-auto theme-hover">
                                    TELL ME MORE
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className="col-7 d-flex flex-column justify-content-center align-items-center">
                        <img className="w-100" src={comserv_graphic} alt="care"></img>
                    </div>        
                </div>
                </div>

                <div className="btn-top-right"  >
                    <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
                </div>
                <UsefulIcons displayQues={true} displayShare={false} onClickRead={()=>handleReadMore()}></UsefulIcons>
            
        </div>

        
    )

}