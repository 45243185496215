import React,{useState,useEffect,useRef} from "react";

import {btn_white_cross,btn_black_cross} from "../../utils/images"
import {values_anim} from "../../utils/videos"
import {} from "../../utils/utility"
import {isMobileOnly,isMobile} from "react-device-detect"

import ShareModal from "./share_modal"
import UsefulIcons from "./useful_icons"
import {ROOT_URL} from "../../config"
import BackMainButton from "./overlay_content/back_main_button"

export default function EducationValues(props){

    const {display,setDisplay,openCallback,closeCallback,isStandalone}=props;
    const [displayShareModal, setDisplayShareModal] = useState(false);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>):(<BackMainButton display={true}/>);

    const [videoEnd, setVideoEnd] = useState(false);

    var vid;

    var onClose=()=>{
        setDisplay(false);
        setVideoEnd(false);
    }

    var onVideoEnd=(event)=>{
        setVideoEnd(true);
        
        
    }

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone4_Education.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone4_Education.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    useEffect(() => {
        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
    }, [display]);

    if (!display){
        return (<></>)
    }

    return (
        <div className="animated fadeIn full-screen position-absolute education-values-master">
            <div className="full-screen-black-bg__half position-absolute z-index-0 "></div>

            

            <video onEnded={(event)=>{onVideoEnd(event)}} autoPlay controls={false} playbackRate={0.7} webkit-playsinline="true" playsInline={true} className={`position-absolute top-left vid unclickable`}>
                <source src={values_anim} ></source>
            </video>


            <div style={{display:videoEnd? "flex" : "none"}} className="container education-values-container flex-column justify-content-center align-items-center h-100 animated fadeIn">
                <div className="row justify-content-center  w-100" >
                    {isMobileOnly? (
                        <div className="col-10 col-xl-8">
                            <div className="header mobile-values border-bottom-insert"><span>Be a Role Model</span></div>
                        </div>
                    ):(
                        <div className="col-8 col-xl-6">
                            <div className="header border-bottom-insert"><span>Be a Role Model</span></div>
                        </div>
                    )}
                </div>

                <div className="row justify-content-center w-100 mt-3">
                    {isMobileOnly ? (
                        <div className="col-10  col-xl-8">
                            <div className="mobile-values border-left-insert overflow-hidden">
                                <span className="pre-line paragraph-mobile">{`Teachers are influential and life-changing role models.
                                That’s why it is important to enable them, for they will go on to foster their students’ curiosity and shape their values.`}</span>
                                
                            </div>
                        </div>
                    ):(
                        <div className="col-8  col-xl-6">
                            <div className="paragraph border-left-insert overflow-hidden">
                                <span className="pre-line paragraph">{`Teachers are influential and life-changing role models.
                                That’s why it is important to enable them, for they will go on to foster their students’ curiosity and shape their values.`}</span>
                                
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {topRightBtn}

            <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
            <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/values`} des={"Share the exhibit with your friends!"}></ShareModal>

        </div>
    )


}