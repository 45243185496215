import React from "react"

export default function LinkRow(props){

    const {rowStyle,rowClass,link,copyLink}=props;

    return (
    <div style={rowStyle} className={`row ${rowClass}`}>

        <div className="offset-1 col-8 link-area">
            <textarea readOnly id="link-field" defaultValue={link}></textarea>
        </div>

        <div className="col-2 link-icon-div d-flex flex-column justify-content-center align-items-center">
             <i onClick={()=>{copyLink()}} className="fas fa-link fa-2x clickable link-icon" aria-hidden="true"></i>
        </div>

        
    </div>
)
}