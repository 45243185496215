import React,{useState,useEffect,useRef} from "react";
import {btn_white_cross,btn_black_cross,dementia_card_front,dementia_healthcare_heart,dementia_card_01,dementia_card_02,dementia_card_03,dementia_card_04,dementia_card_05,dementia_card_06} from "../../utils/images";
import {clock_ticking} from "../../utils/sounds"
import MatchingCard from "./overlay_content/matching_card"
import {shuffle,sleep} from "../../utils/utility"
import {isMobileOnly,isMobile} from "react-device-detect"

import ShareModal from "./share_modal"
import UsefulIcons from "./useful_icons"
import {ROOT_URL,ZONE_5} from "../../config"
import BackMainButton from "./overlay_content/back_main_button"

import $ from 'jquery';


// Credit: Mateusz Rybczonec

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 30;
const ALERT_THRESHOLD = 20;

const COLOR_CODES = {
  info: {
    color: "green"
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD
  }
};

const TIME_LIMIT = 60;
let timePassed = 0;


let remainingPathColor = COLOR_CODES.info.color;

const totalPair=8;

// var timer=(<div className="base-timer">
// <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
//   <g className="base-timer__circle">
//     <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
//     <path
//       id="base-timer-path-remaining"
//       stroke-dasharray="283"
//       className="base-timer__path-remaining ${remainingPathColor}"
//       d="
//         M 50, 50
//         m -45, 0
//         a 45,45 0 1,0 90,0
//         a 45,45 0 1,0 -90,0
//       "
//     ></path>
//   </g>
// </svg>
// <span id="base-timer-label" className="base-timer__label">${formatTime(
//   timeLeft
// )}</span>
// </div>)


// startTimer();

var endingText={
  0:{
    header:"You did it! Was it challenging?",
    paragraph:`For patients with neurodegenerative diseases such as Alzheimer’s disease and Parkinson’s disease, this game will be challenging. 

    In the near future, we hope that LKCMedicine will have breakthrough research that will improve the lives of the elderly and their caregivers. 
    `,
  },
  1:{
    header:"Come on. Give it another try!",
    paragraph:`For patients with neurodegenerative diseases such as Alzheimer’s disease and Parkinson’s disease, this game will be challenging. 

    In the near future, we hope that LKCMedicine will have breakthrough research that will improve the lives of the elderly and their caregivers. 
    `
  }
  
}



var cards=[];
var values=[];
for (var i=0;i<6;i++){
    values.push(i);
    values.push(i);
}


var images={
    0:dementia_card_01,
    1:dementia_card_02,
    2:dementia_card_03,
    3:dementia_card_04,
    4:dementia_card_05,
    5:dementia_card_06,
    
}

var defaultDisplayCardState=[];
var defaultClickableState=[];
var defaultCardActiveState=[];

var selectedCards=[];

for (var i=0;i<12;i++){
    defaultDisplayCardState.push(true);
    defaultClickableState.push(false);
    defaultCardActiveState.push(false);
}

var score;
var elapsed;


export default function Dementia(props){
    const {display,setDisplay,openCallback,closeCallback,isStandalone}=props;

    const [displayShareModal, setDisplayShareModal] = useState(false);
    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>):(<BackMainButton display={true}/>);

    const [displayIntro, setDisplayIntro] = useState(true);
    const [displayGame,setDisplayGame]=useState(false)

    const [gameRunning,setGameRunning]=useState(false)

    const [gameFinished,setGameFinished]=useState(false);
    const [gameWon, setGameWon] = useState(0);

    
    const [displayCards,setDisplayCards]=useState([...defaultDisplayCardState])
    
    const [cardsClickable,setCardsClickable]=useState([...defaultClickableState])
    const [cardsActive,setCardsActive]=useState([...defaultCardActiveState])

    const [noOfMatched,setNoOfMatched]=useState(0)
    const noOfMatchedRef=useRef({})
    noOfMatchedRef.current=noOfMatched

    const [timeLeft,setTimeLeft]=useState(TIME_LIMIT)
    const timeLeftRef=useRef({})
    timeLeftRef.current=timeLeft


    const [cardValues,setCardValues]=useState(values)

    const [gameStartTimeout,setGameStartTimeout]=useState(0)
    const [intervalState,setIntervalState]=useState(0);
    const intervalStateRef=useRef({});
    intervalStateRef.current=intervalState;
    var timerInterval=0;



    useEffect(() => {
      if(!isStandalone){
        if(display){
            openCallback();
        }
        else{
            closeCallback();
        }
    }
  }, [display]);


    function shuffleValues(){
      setCardValues([...shuffle(values)])
    }

    var gameStart=()=>{
      if(gameRunning===false){
        shuffleValues();
        setGameRunning(true)
        setDisplayIntro(false)
        setNoOfMatched(0);

        

        $(".flip-card").removeClass("inactive").addClass("active")

        setGameStartTimeout(setTimeout(()=>{
            
            $(".flip-card").removeClass("active").addClass("inactive after-start")
            $('.unclickable.flip-card').removeClass('unclickable').addClass('clickable')
            startTimer();
        },5000))
      }
    }

    var selectCard=(index,value)=>{
        
        
        var duplicated=false;
        for (var i =0; i<selectedCards.length;i++){
            if (selectedCards[i].index===index){
                duplicated=true;
            }
        }
        if(selectedCards.length<2 && duplicated===false){

            selectedCards.push({index:index,value:value});

            $(`#card-${index}`).removeClass('clickable').addClass('unclickable');
            $(`#card-${index}`).removeClass('inactive').addClass('active');

            // var newCardsClickableState=[...cardsClickable]
            // newCardsClickableState[index]=false;
            // setCardsClickable(newCardsClickableState);

            // var newCardsActiveState=[...cardsActive]
            // newCardsActiveState[index]=true;
            // setCardsActive(newCardsActiveState);
        }
        
        if(selectedCards.length===2){
            $('.flip-card.opacity-1').removeClass('clickable').addClass('unclickable')


            if (selectedCards[0].value===selectedCards[1].value){
              $(`#card-${selectedCards[0].index}`).addClass('matched')
              $(`#card-${selectedCards[1].index}`).addClass('matched')
              
              if(noOfMatchedRef.current+1===6){
                gameComplete(0);
              }
              setNoOfMatched(noOfMatchedRef.current+1)
            }

            else{
              var cards=[]
              for (var i =0;i<selectedCards.length;i++){
              // $(`#card-${selectedCards[i].index}`).addClass('clickable').removeClass('unclickable');
                var card=$(`#card-${selectedCards[i].index}`);
                card.addClass('not-matched')
                cards.push(card)

                  
              }

              setTimeout(()=>{
                cards[0].removeClass('active not-matched');
                cards[1].removeClass('active not-matched');
              },1000)
              
              
              
            
        
          }

          setTimeout(()=>{
            selectedCards=[];
            $('.flip-card').removeClass('unclickable').addClass('clickable')
            $('.matched').removeClass('clickable').addClass('unclickable')
          },1000)
                
          
    
    }
        
    }

    var gameComplete=(result)=>{
      stopTimer(intervalStateRef.current);
      setGameRunning(false);
      setGameWon(result);
      setGameFinished(true);
    }

    var gameRestart=()=>{
      setCardsActive(defaultCardActiveState)
      setCardsClickable(defaultClickableState)
      values=shuffle(values)
      setGameWon(0);
      setGameFinished(false);
      setTimeLeft(TIME_LIMIT);
      
      timePassed=0;

      setCircleDasharray()

      $("#base-timer-path-remaining").removeClass("orange red").addClass("green")
      $(".base-timer__label").removeClass("orange red").addClass("green")

      $(".flip-card").removeClass("active matched after-start").addClass("inactive")
      setTimeout(() => {
        gameStart();
      }, 500);
      
    }

    var onClose=()=>{
        clearInterval(timerInterval);
        clearTimeout(gameStartTimeout)
        stopTimer(intervalStateRef.current);
        setDisplay(false);
        setDisplayIntro(true);
        setDisplayGame(false);
        setDisplayCards(defaultDisplayCardState)
        setCardsClickable(defaultClickableState)
        setCardsActive(defaultCardActiveState);
        setGameRunning(false)
        timePassed=0;
        cards=[];
        selectedCards=[]; 
    }

    var onQuitStandalone=()=>{
      clearInterval(timerInterval);
      clearTimeout(gameStartTimeout)
      stopTimer(intervalStateRef.current);
      setDisplayIntro(true);
      setDisplayCards(defaultDisplayCardState)
      setCardsClickable(defaultClickableState)
      setCardsActive(defaultCardActiveState);
      setGameRunning(false)
      timePassed=0;
      cards=[];
      selectedCards=[]; 
  }


    function stopTimer(interval) {
      
      clearInterval(interval);
      
      $('.hidden-audio-player')[0].pause();
    }
    
    function startTimer() {
      if($('.hidden-audio-player')[0]!==undefined){
      $('.hidden-audio-player')[0].play();
      timerInterval=setInterval(() => {
        timePassed = timePassed += 1;
        setTimeLeft(TIME_LIMIT - timePassed) ;
        
        setCircleDasharray();
        setRemainingPathColor(timeLeftRef.current);
    
        if (timeLeftRef.current === 0 ) {
          gameComplete(1);

        }
      }, 1000);
      setIntervalState(timerInterval)
      // console.log(timerInterval)
    }
    }
    
    function formatTime(time) {
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;
    
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
    
      return `${minutes}:${seconds}`;
    }
    
    function setRemainingPathColor(timeLeft) {
      const { alert, warning, info } = COLOR_CODES;
      if (timeLeft <= alert.threshold) {
        document
          .getElementById("base-timer-path-remaining")
          .classList.remove(warning.color);
        document
          .getElementById("base-timer-path-remaining")
          .classList.add(alert.color);

          $('.base-timer__label').removeClass(warning.color).addClass(alert.color)
         
      } else if (timeLeft <= warning.threshold) {
        document
          .getElementById("base-timer-path-remaining")
          .classList.remove(info.color);
        document
          .getElementById("base-timer-path-remaining")
          .classList.add(warning.color);

        $('.base-timer__label').removeClass(info.color).addClass(warning.color)
          
      }
    }
    
    function calculateTimeFraction() {
      const rawTimeFraction = timeLeftRef.current / TIME_LIMIT;
      return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    }
    
    function setCircleDasharray() {
      if(document.getElementById("base-timer-path-remaining")){
        const circleDasharray = `${(
          calculateTimeFraction() * FULL_DASH_ARRAY
        ).toFixed(0)} 283`;
        document
          .getElementById("base-timer-path-remaining")
          .setAttribute("stroke-dasharray", circleDasharray);
      }
      
    }

    const handleReadMore=()=>{
      if(isMobile){
          const newWindow = window.open("https://tcktl.sg/pdf/Zone5_Healthcare.png", '_blank', 'noopener,noreferrer');
          if (newWindow) newWindow.opener = null
      }
      else {
          const newWindow = window.open("https://tcktl.sg/pdf/Zone5_Healthcare.pdf", '_blank', 'noopener,noreferrer');
          if (newWindow) newWindow.opener = null
      }
    }

    if (!display){
        return (<></>)
    }

    return (
        <div>
          {isMobileOnly? (
            <div className="full-screen animated fadeIn">
                <div className="full-screen-black-bg__half position-absolute z-index-0 "></div>

                <div className="dementia-game-container container h-100">
                  <div className="row h-100">
                    <div className="col-5 counter-col h-100">
                      <div style={{display: displayIntro ? "none" : ""}} className="base-timer-mobile animated fadeIn">
                        <svg className="base-timer__svg" viewBox="0 0 100 100">
                          <g className="base-timer__circle">
                            <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                            <path
                              id="base-timer-path-remaining"
                              strokeDasharray="283"
                              className="base-timer__path-remaining green"
                              d="
                                M 50, 50
                                m -45, 0
                                a 45,45 0 1,0 90,0
                                a 45,45 0 1,0 -90,0
                              "
                            ></path>
                          </g>
                        </svg>
                        <span id="base-timer-label" className="base-timer__label green">{formatTime(timeLeft)}</span>

                        <img style={{position:"absolute",top:'15%',left:'15%',width:'70%',opacity:'0.5'}} alt="heart" src={dementia_healthcare_heart}></img>
                        <button style={{display: isStandalone? "none":"" ,position:"absolute",marginTop:'15px',left:'15%',width:'70%'}} onClick={()=>{onClose()}} className="theme-btn-auto theme-hover">Quit Game</button>
                        <button style={{display: isStandalone? "":"none" ,position:"absolute",marginTop:'15px',left:'15%',width:'70%'}} onClick={()=>{onQuitStandalone()}} className="theme-btn-auto theme-hover">Quit Game</button>
                      </div>
                    </div>

                    <div className="col-7 cards-col h-100">

                      <div className="row cards-wrapper h-100">
                        <div className="col-12 ">

                        <div  className="row m-3 cards-row">
                          <MatchingCard index={0} onSelect={selectCard} value={cardValues[0]} active={cardsActive[0]} display={displayCards[0]} clickable={cardsClickable[0]} image={images[cardValues[0]]}  />
                          <MatchingCard index={1} onSelect={selectCard} value={cardValues[1]} active={cardsActive[1]} display={displayCards[1]} clickable={cardsClickable[1]} image={images[cardValues[1]]}  />
                          <MatchingCard index={2} onSelect={selectCard} value={cardValues[2]} active={cardsActive[2]} display={displayCards[2]} clickable={cardsClickable[2]} image={images[cardValues[2]]}  />
                          <MatchingCard index={3} onSelect={selectCard} value={cardValues[3]} active={cardsActive[3]} display={displayCards[3]} clickable={cardsClickable[3]} image={images[cardValues[3]]}  />
                                
                        </div>
                        <div  className="row m-3 cards-row">
                          <MatchingCard index={4} onSelect={selectCard} value={cardValues[4]} active={cardsActive[4]} display={displayCards[4]} clickable={cardsClickable[4]} image={images[cardValues[4]]}  />
                          <MatchingCard index={5} onSelect={selectCard} value={cardValues[5]} active={cardsActive[5]} display={displayCards[5]} clickable={cardsClickable[5]} image={images[cardValues[5]]}  />
                          <MatchingCard index={6} onSelect={selectCard} value={cardValues[6]} active={cardsActive[6]} display={displayCards[6]} clickable={cardsClickable[6]} image={images[cardValues[6]]}  />
                          <MatchingCard index={7} onSelect={selectCard} value={cardValues[7]} active={cardsActive[7]} display={displayCards[7]} clickable={cardsClickable[7]} image={images[cardValues[7]]}  />
                                
                        </div>
                        <div  className="row m-3 cards-row">
                          <MatchingCard index={8} onSelect={selectCard} value={cardValues[8]} active={cardsActive[8]} display={displayCards[8]} clickable={cardsClickable[8]} image={images[cardValues[8]]}  />
                          <MatchingCard index={9} onSelect={selectCard} value={cardValues[9]} active={cardsActive[9]} display={displayCards[9]} clickable={cardsClickable[9]} image={images[cardValues[9]]}  />
                          <MatchingCard index={10} onSelect={selectCard} value={cardValues[10]} active={cardsActive[10]} display={displayCards[10]} clickable={cardsClickable[10]} image={images[cardValues[10]]}  />
                          <MatchingCard index={11} onSelect={selectCard} value={cardValues[11]} active={cardsActive[11]} display={displayCards[11]} clickable={cardsClickable[11]} image={images[cardValues[11]]}  />
                                
                        </div>
                      

                        </div>
                      </div>
                      


                    </div>
                  </div>
                </div>


                <div className={`full-screen-black-bg__half position-absolute z-index-0 unclickable ${displayIntro ? "" : "animated fadeOut"}`}></div>


                <div style={{display: displayIntro ? "" : "none"}} className="dementia-intro-container position-absolute container h-100">
                  <div className="row h-100">
                    <div className="col-5 intro-col">
                      <div className="row">
                        <div className="col-12">
                          <h2 className="header overflow-hidden" style={{fontSize: "1.8rem"}}><span className="border-bottom-insert">Test your memory</span></h2>
                        </div>
                      </div>

                    

                      <div className="row">
                        <div className="col-12">
                          <p className="paragraph">{`Match the cards within 60 seconds.`}</p>
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className='col-auto'>
                          <button onClick={()=>{gameStart()}} className="theme-btn-auto theme-hover">Let's Start</button>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>


                <div style={{display: gameFinished ? "" : "none"}} className={`full-screen-black-bg__half position-absolute z-index-0 unclickable animated fadeIn`}></div>

                <div style={{display: gameFinished ? "" : "none" }} className="animated fadeIn dementia-ending-container-mobile container">
                  <div className="row h-100">

                    <div className="col-5 d-flex align-items-center">
                      <img className='w-100' alt="heart" src={dementia_healthcare_heart}></img>
                    </div>
                    <div style={{padding:"0 5% 0 5%"}} className="col-7 d-flex flex-column justify-content-center">
                      <div className="row">
                        <div className="col-12">
                          <div className="header text-center w-100">
                          <span>{endingText[gameWon].header}</span>
                        </div>
                        </div>
                      </div>
                      
                      <div className="row mt-2">
                        <div className="col-12">
                        <div className="paragraph">{endingText[gameWon].paragraph}</div>
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <div className="row w-100 btn-row mt-4 justify-content-center">
                            <div className="col-auto"><button onClick={()=>{gameRestart()}} className="theme-btn theme-hover h-100">Play Again</button></div>
                        </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div style={{display: gameRunning? "none":""}}>
                  {topRightBtn}
                  <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
                  <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/memory-game`} des={"Share the exhibit with your friends!"}></ShareModal>
                </div>

                <audio className="hidden-audio-player" controls={false} loop={true} >
                  <source src={clock_ticking}></source>
                </audio>
            </div>
          ):(
            <div className="full-screen animated fadeIn">
                <div className="full-screen-black-bg__half position-absolute z-index-0 "></div>

                <div className="dementia-game-container container h-100">
                  <div className="row h-100">
                    <div className="col-5 counter-col h-100">
                      <div style={{display: displayIntro ? "none" : ""}} className="base-timer animated fadeIn">
                        <svg className="base-timer__svg" viewBox="0 0 100 100">
                          <g className="base-timer__circle">
                            <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                            <path
                              id="base-timer-path-remaining"
                              strokeDasharray="283"
                              className="base-timer__path-remaining green"
                              d="
                                M 50, 50
                                m -45, 0
                                a 45,45 0 1,0 90,0
                                a 45,45 0 1,0 -90,0
                              "
                            ></path>
                          </g>
                        </svg>
                        <span id="base-timer-label" className="base-timer__label green">{formatTime(timeLeft)}</span>

                        <img style={{position:"absolute",top:'15%',left:'15%',width:'70%',opacity:'0.5'}} alt="heart" src={dementia_healthcare_heart}></img>
                        <button style={{display: isStandalone? "":"none" ,position:"absolute",marginTop:'20px',left:'17%',width:'70%'}} onClick={()=>{onQuitStandalone()}} className="theme-btn-auto theme-hover">Quit Game</button>
                      </div>
                    </div>

                    <div className="col-7 cards-col h-100">

                      <div className="row cards-wrapper h-100">
                        <div className="col-12 ">

                        <div  className="row m-3 cards-row">
                          <MatchingCard index={0} onSelect={selectCard} value={cardValues[0]} active={cardsActive[0]} display={displayCards[0]} clickable={cardsClickable[0]} image={images[cardValues[0]]}  />
                          <MatchingCard index={1} onSelect={selectCard} value={cardValues[1]} active={cardsActive[1]} display={displayCards[1]} clickable={cardsClickable[1]} image={images[cardValues[1]]}  />
                          <MatchingCard index={2} onSelect={selectCard} value={cardValues[2]} active={cardsActive[2]} display={displayCards[2]} clickable={cardsClickable[2]} image={images[cardValues[2]]}  />
                          <MatchingCard index={3} onSelect={selectCard} value={cardValues[3]} active={cardsActive[3]} display={displayCards[3]} clickable={cardsClickable[3]} image={images[cardValues[3]]}  />
                                
                        </div>
                        <div  className="row m-3 cards-row">
                          <MatchingCard index={4} onSelect={selectCard} value={cardValues[4]} active={cardsActive[4]} display={displayCards[4]} clickable={cardsClickable[4]} image={images[cardValues[4]]}  />
                          <MatchingCard index={5} onSelect={selectCard} value={cardValues[5]} active={cardsActive[5]} display={displayCards[5]} clickable={cardsClickable[5]} image={images[cardValues[5]]}  />
                          <MatchingCard index={6} onSelect={selectCard} value={cardValues[6]} active={cardsActive[6]} display={displayCards[6]} clickable={cardsClickable[6]} image={images[cardValues[6]]}  />
                          <MatchingCard index={7} onSelect={selectCard} value={cardValues[7]} active={cardsActive[7]} display={displayCards[7]} clickable={cardsClickable[7]} image={images[cardValues[7]]}  />
                                
                        </div>
                        <div  className="row m-3 cards-row">
                          <MatchingCard index={8} onSelect={selectCard} value={cardValues[8]} active={cardsActive[8]} display={displayCards[8]} clickable={cardsClickable[8]} image={images[cardValues[8]]}  />
                          <MatchingCard index={9} onSelect={selectCard} value={cardValues[9]} active={cardsActive[9]} display={displayCards[9]} clickable={cardsClickable[9]} image={images[cardValues[9]]}  />
                          <MatchingCard index={10} onSelect={selectCard} value={cardValues[10]} active={cardsActive[10]} display={displayCards[10]} clickable={cardsClickable[10]} image={images[cardValues[10]]}  />
                          <MatchingCard index={11} onSelect={selectCard} value={cardValues[11]} active={cardsActive[11]} display={displayCards[11]} clickable={cardsClickable[11]} image={images[cardValues[11]]}  />
                                
                        </div>
                      

                        </div>
                      </div>
                      


                    </div>
                  </div>
                </div>


                <div className={`full-screen-black-bg__half position-absolute z-index-0 unclickable ${displayIntro ? "" : "animated fadeOut"}`}></div>


                <div style={{display: displayIntro ? "" : "none"}} className="dementia-intro-container position-absolute container h-100">
                  <div className="row h-100">
                    <div className="col-5 intro-col">
                      <div className="row">
                        <div className="col-12">
                          <h1 className="header overflow-hidden"><span className="border-bottom-insert">Test your memory</span></h1>
                        </div>
                      </div>

                    

                      <div className="row">
                        <div className="col-12">
                          <p className="paragraph">{`Match the cards within 60 seconds.`}</p>
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className='col-auto'>
                          <button onClick={()=>{gameStart()}} className="theme-btn-auto theme-hover">Let's Start</button>
                        </div>
                      </div>
                      
                    </div>

                    {/* <div className="col-7 button-col">
                      <div className="row">
                        <div className="col-auto">
                            <p>{`Test your memory.
                            Could you match all the cards within 60 seconds?`}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className='col-auto'>
                          <button onClick={()=>{gameStart()}} className="theme-btn theme-hover">Let's Start</button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>


                <div style={{display: gameFinished ? "" : "none"}} className={`full-screen-black-bg__half position-absolute z-index-0 unclickable animated fadeIn`}></div>

                <div style={{display: gameFinished ? "" : "none" }} className="animated fadeIn dementia-ending-container container">
                  <div className="row h-100">

                    <div className="col-5 d-flex align-items-center">
                      <img className='w-100' alt="heart" src={dementia_healthcare_heart}></img>
                    </div>
                    <div style={{padding:"0 5% 0 5%"}} className="col-7 d-flex flex-column justify-content-center">
                      <div className="row">
                        <div className="col-12">
                          <div className="header text-center w-100">
                          <span>{endingText[gameWon].header}</span>
                        </div>
                        </div>
                      </div>
                      
                      <div className="row mt-2">
                        <div className="col-12">
                        <div className="paragraph">{endingText[gameWon].paragraph}</div>
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <div className="row w-100 btn-row mt-4 justify-content-center">
                            <div className="col-auto"><button onClick={()=>{gameRestart()}} className="theme-btn theme-hover h-100">Play Again</button></div>
                            <div className="col-auto"><button onClick={()=>{onClose()}} className="theme-btn theme-hover h-100">Done</button></div>
                          
                          
                        </div>
                        </div>
                      </div>
                      
                      
                      
                      </div>

                  </div>
                </div>

                <audio className="hidden-audio-player" controls={false} loop={true} >
                  <source src={clock_ticking}></source>
                </audio>
              

                {topRightBtn}
                <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
          <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/memory-game`} des={"Share the exhibit with your friends!"}></ShareModal>
            </div>
          )}
        </div>
    )

}