import React,{useState,useEffect,useRef} from "react";
import {btn_white_cross,arrow_btn, btn_black_cross} from "../../utils/images"
import Matter  from "matter-js"
import $ from "jquery"

import {lerpColor,setStateWithDelay,navigateFunctionSet} from "../../utils/utility"
import Words from "arwes/lib/Words";

import UsefulIcons from "./useful_icons"
import ShareModal from "./share_modal"
import {ROOT_URL} from "../../config"
import BackMainButton from "./overlay_content/back_main_button"

import ControlHint from "./overlay_content/control_hint"
import { isMobileOnly,isMobile } from "react-device-detect";

const Engine = Matter.Engine,
Render = Matter.Render,
Runner = Matter.Runner,
Common = Matter.Common,
MouseConstraint = Matter.MouseConstraint,
Mouse = Matter.Mouse,
World = Matter.World,
Vertices = Matter.Vertices,
Svg = Matter.Svg,
Bodies = Matter.Bodies,
Body=Matter.Body,
Composites=Matter.Composites,
Events = Matter.Events;

var engine;
var world;
var render;
var runner;
var mouse;
var mouseConstraint;

var canvasScale=2;

var superpower_text=["Courage","Honesty","Sensitivity","Efficiency","Reliability","Empathy","Adaptability","Integrity","Flexibility","Responsibility","Productivity","Curiosity"]
var superpower_bodies=[];
var blackVeil;



const  lineHeight=15;

var blueColor="#3F5392",
yellowColor="#D89F41",
greyColor="#8093CD",
colors=[blueColor,yellowColor,greyColor];


var interpolateFlag=false;




export default function Superpower(props){
    const {display,setDisplay,setDisplaySuperHero,sdk,openCallback,closeCallback, isStandalone, pauseBGM}=props;
    const [displayShareModal, setDisplayShareModal] = useState(false);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>):(<BackMainButton display={true}/>);

    const [stage,setStage]=useState(0)
    const [instantiated,setInstantiated]=useState(false)

    var textColor="#ffffff";
    var newColor="#808080";
    var lerpAmount=0;

    var superpower_min_radius=50;
    var superpower_radius_range=100;

    function initSizeRange(){
        if (window.innerWidth>=1200){
            superpower_min_radius=50;
            superpower_radius_range=100;
        }
        
        else if (window.innerWidth<1200 && window.innerWidth>=1000){
            superpower_min_radius=45;
            superpower_radius_range=70;
        }
        
        else if (window.innerWidth<1000 && window.innerWidth>=768){
            superpower_min_radius=25;
            superpower_radius_range=40;
        }
        
        else if (window.innerWidth<768){
            superpower_min_radius=20;
            superpower_radius_range=30;
        }
    }
    

    var onClose=()=>{
        
        setStage(0)
        Engine.clear(engine);
        Render.stop(render);
        Runner.stop(runner);
        render.canvas.remove();
        render.canvas = null;
        render.context = null;
        render.textures = {};
        superpower_bodies=[]
        blackVeil=undefined;
        
        setInstantiated(false);
        setDisplay(false);
    }

    var navigateToSuperHero=()=>{
        navigateFunctionSet(sdk,"cBBdVrK5Xxz")
        pauseBGM(true);
        setStateWithDelay(setDisplaySuperHero,true,1500)
        onClose();
    }

    function init_superpowers(){
        var radius_modifier=1;

        for (var i =0;i<superpower_text.length;i++){

            var radius= superpower_min_radius+superpower_radius_range*radius_modifier;
            radius_modifier-=0.06
            var x = Math.random()*(window.innerWidth-(2*radius))+radius
            var y = Math.random()*(window.innerHeight-(2*radius))+radius
            
            
            var body=Bodies.circle(x,y,radius,{
                density: 0.0005,
                frictionAir: 0.005,
                restitution: 0.3,
                friction: 0.01,
                collisionFilter:{
                    mask:1,
                    
                },
            
                render: {
                    fillStyle:colors[Math.floor(Math.random() * colors.length)]
                }})
            
            superpower_bodies.push(body);
            
        }
        World.add(world,superpower_bodies)

        blackVeil=Bodies.rectangle(window.innerWidth/2,window.innerHeight/2,render.canvas.width,render.canvas.height,{
            isStatic:true,
            
            collisionFilter:{
                category:2,
                mask:2,
                group:0
            },
            render:{
                opacity:0,
                fillStyle:"black"
            }
        })

        World.add(world,blackVeil)
    }

    function runMatter(){
        Render.run(render);
        Runner.run(runner, engine);
    }

    var handleInput=()=>{
        var message = $('input')[0].value;
        if(message!==""){
            message=message.replace(/</g, "&lt;").replace(/>/g, "&gt;");

            var radius= 80;
            var x = Math.random()*(window.innerWidth-(2*radius))+radius
            var y = -200;

            var body=Bodies.circle(x,y,radius,{
                density: 0.0002,
                frictionAir: 0.003,
                restitution: 0.3,
                friction: 0.01,
                collisionFilter:{
                    mask:1,
                    
                },
                
                render: {
                    fillStyle:yellowColor
            }})
            superpower_bodies.push(body);
            superpower_text.push(message);
            World.add(world,body)
            setStage(1);

            setTimeout(()=>{
                setStage(2)
                interpolateFlag=true;
                
            },5000)
            
        }
    }

    var handleKeyDown=(event)=>{
        if(event.keyCode===13){
            handleInput();
        }
    }

    useEffect(() => {

        
        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
        
        
        return () => {
            
        };
    }, [display]);

    useEffect(() => {

        

        if(display && !instantiated){
        
            
        initSizeRange();
        setInstantiated(true);
        

        
        engine=Engine.create({
            // enableSleeping: true
        });
        world=engine.world;
        
        render = Render.create(
            {
              element: $('.canvas-wrapper')[0],
              engine: engine,
          
              
              options:{
                width: Math.round(window.innerWidth*canvasScale),
                height: Math.round(window.innerHeight*canvasScale),
                background: 'transparent',
                showAngleIndicator: false,
                wireframes: false,
                //background: "#ffffff",
                //wireframeBackground:"transparent"
                
              }
            }
          );
        
        render.context.imageSmoothingEnabled=false;
        runner=Runner.create();
        mouse = Mouse.create(render.canvas);
        render.mouse = mouse;
        mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }
        });

        mouseConstraint.collisionFilter.mask=0x0001;
        

        var wallWidth=50;

        var wallLeft=Bodies.rectangle(-wallWidth/2,window.innerHeight/2,wallWidth,window.innerHeight,{ isStatic: true,render:{visible:true },collisionFilter:{
            category:1,
            mask:1
        } })
        var wallBottom=Bodies.rectangle(window.innerWidth/2,window.innerHeight+wallWidth/2,window.innerWidth,wallWidth,{ isStatic: true,render:{visible:true },collisionFilter:{
            category:1,
            mask:1
        } })
        var wallRight=Bodies.rectangle(window.innerWidth+wallWidth/2,window.innerHeight/2,wallWidth,window.innerHeight,{ isStatic: true,render:{visible:true },collisionFilter:{
            category:1,
            mask:1
        } })
        var wallTop=Bodies.rectangle(window.innerWidth/2,-wallWidth/2,window.innerWidth,wallWidth,{ isStatic: true,render:{visible:true },collisionFilter:{
            category:1,
            mask:1
        } })
        
        
        

        World.add(world, [wallLeft,wallBottom,wallRight]);
        
        init_superpowers()
       
        World.add(world, mouseConstraint);
        Render.lookAt(render, {
            min: { x: 0, y: 0 },
            max: { x: window.innerWidth, y: window.innerHeight }
          });

        Events.on(engine,'beforeUpdate',function(event){
            
            for (var i=0;i<superpower_bodies.length;i++){
                var currentColor=textColor;
                // if(superpower_bodies[i].render.fillStyle==="#C4C4C4"){
                //     textColor="black"
                // }
                if (superpower_bodies.length-1===i){
                    currentColor="#ffffff";
                }

                if(superpower_bodies[i].position.x>window.innerWidth || superpower_bodies[i].position.x<0){
                    Body.setPosition(superpower_bodies[i],Matter.Vector.create(window.innerWidth/2,-300))
                }
                
                render.context.fillStyle=currentColor
                render.context.font="400 30px Barlow"
                render.context.stokeStyle=currentColor
                render.context.save();
                var textX;
                var textY;

                textX=(superpower_bodies[i].position.x)*canvasScale
                textY=(superpower_bodies[i].position.y)*canvasScale

                render.context.translate(textX,textY);
                render.context.rotate(superpower_bodies[i].angle);
                render.context.textAlign='center';
                render.context.strokeText(superpower_text[i], 0, lineHeight / 2)
                render.context.fillText(superpower_text[i], 0, lineHeight / 2);
                render.context.restore();
        }
            if(interpolateFlag){
                if(blackVeil.render.opacity<0.8){
                    blackVeil.render.opacity+=0.005;
                    textColor=lerpColor("#ffffff",newColor,lerpAmount);
                    
                    
                    lerpAmount+=0.01;
                    
                }
                else{
                    interpolateFlag=false;
                }
            }


        })

        runMatter();

    }
        
        return () => {
            
        };
    }, [display,instantiated]);

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone2_Legaled.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone2_Legaled.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    if (!display){
        return (<></>)
    }

    return(
        <div>
            {isMobileOnly ? (
                <div onKeyDown={(event)=>handleKeyDown(event)} className="full-screen animated fadeIn superpower-container-mobile">
                    <div className="full-screen-black-bg__half position-absolute"></div>

                    <div className="full-screen position-absolute top-left  canvas-wrapper"></div>

                    <div style={{display:stage===0 ? "" : "none",opacity:"0.5"}} className="full-screen-black-bg__half position-absolute unclickable z-index-2"></div>
                    
                    
                    <div className="container h-100 unclickable">
                    
                        <div className="row h-25"></div>
                        
                        <div style={{display:stage===0 ? "flex" : "none"}} className="row mt-xl-5  justify-content-center">
                            <div  className="col-10 unclickable">
                                <div className="header text-center pre-line">{`Every one of us has a superpower.
                                What is yours?`}</div>
                            </div>
                        </div>

                        <div style={{display:stage===0 ? "flex" : "none"}} className="row clickable  justify-content-center input-row mt-5">
                            <div className="col-5 h-100 input-wrapper">
                                <input spellcheck={true} placeholder=""></input>
                            </div>
                            <div  onClick={()=>handleInput()} className="clickable col-2 col-sm-1 h-100 btn-wrapper d-flex justify-content-center align-items-center">
                                <img alt="btn" src={arrow_btn} className="w-100 "></img>
                            </div>
                        </div>

                        <div style={{display:stage===2 ? "" : "none", top:"-9%"}} className="row unclickable  mt-5 animated fadeIn">
                            <div className="offset-1 col-10 d-flex flex-column justify-content-center align-items-center">
                                <p className="header quote text-center text-capitalize pre-line">{`“Heroes are made by the path
                                they choose, not the powers
                                they are graced with.”`}</p>
                                <p className="paragraph text-center">{`Iron Man`}</p>

                                <button onClick={()=>navigateToSuperHero()} className="theme-hover theme-btn-auto clickable mt-4">Talk to some of our superheroes</button>
                            </div>
                        </div>

                    </div>

                    <ControlHint text={`Play around with the balls at the back`} top="30px" left="30px" isAbsolute={true}></ControlHint>

                    {/* <div className="btn-top-right"  >
                        <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
                    </div> */}
                </div>
            ):(
                <div onKeyDown={(event)=>handleKeyDown(event)} className="full-screen animated fadeIn superpower-container">
                    <div className="full-screen-black-bg__half position-absolute"></div>

                    <div className="full-screen position-absolute top-left  canvas-wrapper"></div>

                    <div style={{display:stage===0 ? "" : "none",opacity:"0.5"}} className="full-screen-black-bg__half position-absolute unclickable z-index-2"></div>
                    
                    
                    <div className="container h-100 unclickable">
                    
                        <div className="row h-25"></div>
                        
                        <div style={{display:stage===0 ? "flex" : "none"}} className="row mt-xl-5  justify-content-center">
                            <div  className="col-8 unclickable">
                                <div className="header text-center pre-line">{`Every one of us has a superpower.
                                What is yours?`}</div>
                            </div>
                        </div>

                        <div style={{display:stage===0 ? "flex" : "none"}} className="row clickable  justify-content-center input-row mt-5">
                            <div className="col-5 h-100 input-wrapper">
                                <input spellcheck={true} placeholder=""></input>
                            </div>
                            <div  onClick={()=>handleInput()} className="clickable col-2 col-sm-1 h-100 btn-wrapper d-flex justify-content-center align-items-center">
                                <img alt="btn" src={arrow_btn} className="w-100 "></img>
                            </div>
                        </div>

                        <div style={{display:stage===2 ? "" : "none"}} className="row unclickable  mt-5 animated fadeIn">
                            <div className="offset-2 col-8 d-flex flex-column justify-content-center align-items-center">
                                <p className="header quote text-center text-capitalize pre-line">{`“Heroes are made by the path
                                they choose, not the powers
                                they are graced with.”`}</p>
                                <p className="paragraph text-center">{`Iron Man`}</p>

                                <button onClick={()=>navigateToSuperHero()} className="theme-hover theme-btn-auto clickable mt-4">Talk to some of our superheroes</button>
                            </div>
                        </div>

                    </div>

                    <ControlHint text={`Play around with the balls at the back`} top="30px" left="30px" isAbsolute={true}></ControlHint>

                    {/* <div className="btn-top-right"  >
                        <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
                    </div> */}
                    
                </div>
            )}
            {topRightBtn}
            <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
            <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/superpower`} des={"Share the exhibit with your friends!"}></ShareModal>
        </div>
    
    )

}