import React from "react"
import {CKTL_logo_subheader, rotate_phone} from "../utils/images"
import DeviceOrientation, {Orientation} from 'react-screen-orientation';

//check if mobile device orientation is landscape
class MobileOrientation extends React.Component {

    render(){
        return(
            <DeviceOrientation lockOrientation={'landscape'}>
                <Orientation orientation='portrait' alwaysRender={false}>
                    <div className="landscape-check-bg position-absolute">
                        <img className="landscape-message-logo" alt="logo" src={CKTL_logo_subheader}></img>
                            <p className="message">
                                <img className="rotate-img" src={rotate_phone}/><br/>
                                For the best experience, <br/>please rotate your phone horizontally.
                            </p>
                        </div>
                </Orientation>
            </DeviceOrientation>
        )
    }
}

export default MobileOrientation;