import React,{useState,useEffect,useRef} from "react";
import {btn_white_cross,btn_black_cross,
D_svg,a_svg,e_svg,h_svg,i_svg,k_svg,n_svg,o_svg,r_svg,t_svg,u_svg,y_svg,a_yellow_svg,c_yellow_svg,e_yellow_svg,g_yellow_svg,i_yellow_svg,r_yellow_svg,v_yellow_svg,questionmark_svg,
D_png,a_png,e_png,h_png,i_png,k_png,n_png,o_png,r_png,t_png,u_png,y_png,a_yellow_png,c_yellow_png,e_yellow_png,g_yellow_png,i_yellow_png,r_yellow_png,v_yellow_png,
A_capital_svg} from "../../utils/images"
import Matter  from "matter-js"
import $ from "jquery"

import ControlHint from "./overlay_content/control_hint"

import ShareModal from "./share_modal"
import UsefulIcons from "./useful_icons"
import {ROOT_URL} from "../../config"
import BackMainButton from "./overlay_content/back_main_button"

import {precise} from "../../utils/utility"
import {glass_hit} from "../../utils/sounds"
import { isMobileOnly,isMobile } from "react-device-detect";

const Engine = Matter.Engine,
Render = Matter.Render,
Runner = Matter.Runner,
Common = Matter.Common,
MouseConstraint = Matter.MouseConstraint,
Mouse = Matter.Mouse,
World = Matter.World,
Vertices = Matter.Vertices,
Svg = Matter.Svg,
Bodies = Matter.Bodies,
Body=Matter.Body,
Composites=Matter.Composites,
Events = Matter.Events;



const sentence=[[A_capital_svg,r_svg,e_svg],[y_svg,o_svg,u_svg],[a_svg],[c_yellow_svg,a_yellow_svg,r_yellow_svg,e_yellow_svg,g_yellow_svg,i_yellow_svg,v_yellow_svg,e_yellow_svg,r_yellow_svg,questionmark_svg]]
const sentence_png=[[D_png,o_png],[y_png,o_png,u_png],[t_png,h_png,i_png,n_png,k_png],[y_png,o_png,u_png],[a_png,r_png,e_png],[a_png],[c_yellow_png,a_yellow_png,r_yellow_png,e_yellow_png,g_yellow_png,i_yellow_png,v_yellow_png,e_yellow_png,r_yellow_png,questionmark_svg]]
const offsetsY=[[-5,0,0],[4,0,0],[0],[0,0,0,0,4,-5,0,0,0,0]]
const offsetsX=[[0,3,-2],[0,0,0],[0],[0,0,0,-3,2,-3,-4,0,-2,0]]
var letters=[];
const defaultCategory= 0x0001;
const firstCategory=0x0002;
const secondCategory=0x0003;
const thirdCategory=0x0004;

const categories=[2,3,4,5,6]


var lineHeight=15;

var engine;
var world;
var render;
var runner;
var mouse;
var mouseConstraint;

var canvasScale=2;

var audioPlayers=[];

var numOfLetters=0

if(audioPlayers.length===0){
for (var subArray of sentence){
    
    for (var letter of subArray){
        numOfLetters+=1;
        
        audioPlayers.push(<audio key={`audio-${numOfLetters}`} className="hidden-audio-player" controls={false} >
        <source src={glass_hit}></source>
    </audio>)
    }
}

}
export default function Caregiver(props){
    const {display,setDisplay,openCallback,closeCallback,isStandalone}=props;
    const [exploded,setExploded]=useState(false)
    
    const [instantiated,setInstantiated]=useState(false)

    const [displayShareModal, setDisplayShareModal] = useState(false);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>):(<BackMainButton display={true}/>);

    
    
    var currentPlayerIndex=0;

    useEffect(() => {
        if(!isStandalone){
            if(display){
                openCallback();
                $('.hidden-audio-player').prop("volume", 0.1);
            }
            else{
                closeCallback();
            }
        }
    }, [display]);

    var onClose=()=>{
        setExploded(false);
        setInstantiated(false);
        Engine.clear(engine);
        Render.stop(render);
        Runner.stop(runner);
        render.canvas.remove();
        render.canvas = null;
        render.context = null;
        render.textures = {};
        setDisplay(false);    
    }

   




    var explode=()=>{
        if(!exploded){
        
        
        setExploded(true)
         
        for (var i=0;i<letters.length;i++){
            var body=letters[i];
            var force=100;
            var velocity=Matter.Vector.create((Math.random()-0.5)*force,(Math.random()-0.5)*force)
            Body.setVelocity(body,velocity)
            

        }
    }
        
    }

    
    function runMatter(){
        Render.run(render);
        Runner.run(runner, engine);
    }

   

    useEffect(() => {

        

        if(display && !instantiated){
        // console.log("rerendered")
        setInstantiated(true);
        var letterWidth=25;
        var letterCount=16;
        var wordGap=15;
        var gapCount=3;
        var currentLetterX=Math.round((window.innerWidth-(letterWidth*letterCount+wordGap*gapCount))/2)+25
        var currentLetterY=Math.round(window.innerHeight*0.4)
        var letterRadius=10;
        letters=[];

        
        engine=Engine.create({
            // enableSleeping: true
        });
        world=engine.world;
        world.gravity.y = 0;
        render = Render.create(
            {
              element: $('.caregiver-container')[0],
              engine: engine,
          
              
              options:{
                width: Math.round(window.innerWidth*canvasScale),
                height: Math.round(window.innerHeight*canvasScale),
                background: 'transparent',
                showAngleIndicator: false,
                wireframes: false,
                //background: "#ffffff",
                //wireframeBackground:"transparent"
                
              }
            }
          );
        
        render.context.imageSmoothingEnabled=false;
        runner=Runner.create();
        mouse = Mouse.create(render.canvas);
        render.mouse = mouse;
        mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }
        });
        

        var wallWidth=50;

        var wallLeft=Bodies.rectangle(-wallWidth/2,window.innerHeight/2,wallWidth,window.innerHeight,{ isStatic: true,render:{visible:true } })
        var wallBottom=Bodies.rectangle(window.innerWidth/2,window.innerHeight+wallWidth/2,window.innerWidth,wallWidth,{ isStatic: true,render:{visible:true } })
        var wallRight=Bodies.rectangle(window.innerWidth+wallWidth/2,window.innerHeight/2,wallWidth,window.innerHeight,{ isStatic: true,render:{visible:true } })
        var wallTop=Bodies.rectangle(window.innerWidth/2,-wallWidth/2,window.innerWidth,wallWidth,{ isStatic: true,render:{visible:true } })
        
        
        var currentCategoryIndex=0;
        for (var i=0;i<sentence.length;i++){
            for (var j =0; j<sentence[i].length;j++){
                var category=categories[(currentCategoryIndex)%(categories.length)]
                currentLetterX=currentLetterX+offsetsX[i][j]
                var letter=Bodies.circle(currentLetterX,currentLetterY+offsetsY[i][j],letterRadius,{
                    density: 0.0005,
                    frictionAir: 0.005,
                    restitution: 0.3,
                    friction: 0.01,
                    
                    // collisionFilter: {
                    //     category: category,
                    //     mask:defaultCategory | category
                    // },
                    render: {
                        sprite: {
                            texture: sentence[i][j],
                            xScale:0.25,
                            yScale:0.25,
                            
                        }
                    }})

                currentLetterX+=letterWidth;
                letters.push(letter)
                currentCategoryIndex+=1;

                
                
                
                
            }
            currentLetterX=currentLetterX+wordGap
        }

        World.add(world, [wallLeft,wallBottom,wallRight,wallTop]);
        World.add(world,letters)
        World.add(world, mouseConstraint);
        Render.lookAt(render, {
            min: { x: 0, y: 0 },
            max: { x: window.innerWidth, y: window.innerHeight }
          });

        


        Events.on(engine, 'collisionStart', function(event) {
            currentPlayerIndex+=1;
            $('.hidden-audio-player')[currentPlayerIndex%audioPlayers.length].currentTime=0;
            $('.hidden-audio-player')[currentPlayerIndex%audioPlayers.length].play();
            
        });

        Events.on(engine,'beforeUpdate',function(event){
            // for (var k=0;k<letters.length;k++){
            //     if(letters[k].speed<=0.001){
                    
            //         var roundedX=precise(letters[k].position.x,4);
            //         var roundedY=precise(letters[k].position.y,4);
            //         var newPos=Matter.Vector.create(roundedX,roundedY);
                    
            //         Body.setPosition(letters[k],newPos)



                    
                    
                   
            //     }
            // }
            // render.context.fillStyle="#F1B148"
            // render.context.font="600 80px Barlow"
            // render.context.stokeStyle='#F1B148'
            // render.context.save();
            // var textX;
            // var textY;

            // textX=(letters[0].position.x)
            // textY=(letters[0].position.y)

            // render.context.translate(textX,textY);
            // render.context.rotate(letters[0].angle);
            // render.context.textAlign='center';
            // render.context.strokeText('D', 0, lineHeight / 2)
            // render.context.fillText('D', 0, lineHeight / 2);
            // render.context.restore();


        })

        runMatter();

    }
        
        return () => {
            
        };
    }, [display,exploded]);

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone5_Healthcare.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone5_Healthcare.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    if (!display){
        return (<></>)
    }

    return(
    
    <div className="full-screen animated fadeIn caregiver-container">
        <div className="background position-absolute"/>
        <div className="container h-100 option-stage" style={{display: exploded ? "none" : ""}}>
            <div className="row unclickable" style={{height:"60%"}}></div>

            <div className="option-wrapper clickable  row justify-content-center">
                <div className="col-auto">
                    <div onClick={()=>{explode()}}  className="theme-btn theme-hover h-100"><span>Of Course</span></div>
                </div>
                <div className="col-auto">
                    <div onClick={()=>{explode()}}  className="theme-btn theme-hover h-100"><span>I'm not sure</span></div>
                </div>
            </div>

            
        </div>

        <div style={{display: exploded ? "" : "none"}} className="container h-100 end-stage animated fadeIn delay-2s unclickable ">
            {isMobileOnly? (
                <div className="row " style={{height:"25%"}}></div>
            ):(
                <div className="row " style={{height:"35%"}}></div>
            )}
            <div className="row d-flex justify-content-center">
                <div className="col-6">
                    <h1 className="header overflow-hidden"><span className="border-bottom-insert">Because you care, you have the right to be called a caregiver</span></h1>
                </div>

            </div>

            <div className="row  d-flex justify-content-center">
                <div className="col-6 ">
                    <ControlHint delay={"2.5s"} text={`Try clicking and dragging the letters around`} isAbsolute={false}></ControlHint>
                </div>
            </div>

            <div className="row mt-1 d-flex justify-content-center">
                <div className="col-6">
                    <p className="paragraph pre-line">{`It could simply be helping an elderly cross the road, lending a weaker student your tutorial notes, or offering a listening ear to a friend.`}
                    </p>
                </div>
            </div>
        </div>

            
        <div className="audio-wrapper">{audioPlayers}</div>
        
        {/* <ControlHint text={`Try clicking and dragging the letters around`} top="30px" left="30px" isAbsolute={true}></ControlHint> */}
        {topRightBtn}
        <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>

        {isMobileOnly? (
            <div className="share-container-mobile justify-content-center">
                <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/caregiver`} des={"Share the exhibit with your friends!"}></ShareModal>
            </div>
        ):(
            <div className="share-container justify-content-center">
                <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/caregiver`} des={"Share the exhibit with your friends!"}></ShareModal>
            </div>
        )}
        
    </div>)
}