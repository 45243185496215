// Detect request animation frame
import $ from 'jquery';
import { Bezier } from "bezier-js";

var scroll = window.requestAnimationFrame ||
             // IE Fallback
             function(callback){ window.setTimeout(callback, 1000/60)};


export function scrollLoop(elementsToShow) {
    
    for (var i=0;i<elementsToShow.length;i++){
        var element=elementsToShow[i];
        if (isElementInViewport(element)) {
            if(i===7){
                
            }
            $(element).removeClass(' show-on-scroll').css('opacity','1');
            
          } 
        }

    }
   
    

// Call the loop for the first time


// Helper function from: http://stackoverflow.com/a/7557433/274826
function isElementInViewport(el) {
  // special bonus for those using jQuery
  if (typeof $ === "function" && el instanceof $) {
    el = el[0];
  }
  var rect = el.getBoundingClientRect();
  // console.log(rect)
  return (
    // (rect.top <= 0
    //   && rect.bottom >= 0)
    // ||
    // (rect.bottom >= (window.innerHeight ) &&
      rect.top <= (window.innerHeight*0.8 )//)
    // ||
    // (rect.top >= 0 &&
    //   rect.bottom <= (window.innerHeight ))
  );
}

export function detectLeftButton(event) {
  if ('buttons' in event) {
      return event.buttons === 1;
  } else if ('which' in event) {
      return event.which === 1;
  } else {
      return event.button === 1;
  }
}

export function detectWheelDirection(event){
  if (event.originalEvent.deltaY>0){
    return 0
  }
  else{
    return 1
  }
}

export function detectWheelDirectionReact(event){
  if (event.deltaY>0){
    return 0
  }
  else{
    return 1
  }
}


export function Circle(x,y,radius,color,canvas,context,range=3,text=""){
  this.x=x;
  this.y=y;
  this.radius=radius;
  
  this.fillStyle=color
  this.text=text;
  this.range=range;

  
  this.moveFlag=false;
  this.startTime=0;

  this.startingX=0;
  this.startingY=0;
  this.newX=0;
  this.newY=0;
  this.controlX=0;
  this.controlY=0;

  this.newRadius=this.radius;
  this.radiusGrow=0;
  this.canvas=canvas;
  this.context=context;

  this.transitionDuration=1500;

  this.shadowColor="white"

  this.hover=false;
  this.lastHover=this.hover;
  this.hoverable=false;

  this.hoverCallback=undefined;


  

  function convertCoord(coord,axis){

    if (axis===0){
      return (coord/100)*canvas.width*0.65+canvas.width*0.175
    }
    else{
      return (coord/100)*canvas.height*0.65+canvas.height*0.175
    }
  }

  function convertRadius(radius){
    return (radius/100)*canvas.width
  }

  this.draw=function(){
      var X=convertCoord(this.x,0);
      var Y=convertCoord(this.y,1);
      var r=convertRadius(this.radius)

      // console.log(X);
      // console.log(Y);

      if(this.hover && this.hoverable){
        context.shadowColor=this.shadowColor;
        context.shadowBlur=20;
        
      }
      else{
        context.shadowColor="transparent"
        context.shadowBlur=0;
      }

      context.beginPath();

      context.arc(X,Y,r,0,Math.PI*2,false)
      context.fillStyle=this.fillStyle
      
      context.fill();

      if(this.text!=""){

        var lineHeight=10;

        var fontSize=this.radius;
        
        context.fillStyle="white"
        context.font=`400 30px Barlow`
        context.stokeStyle="white"
        context.textAlign='center';
        // render.context.strokeText(this.text, x, y)
        context.fillText(this.text, X, Y+lineHeight);
      }

      

      
      
  }

  this.update=function(mouse={},hoverable=false){
      if (this.moveFlag===true){
          var elapsed = (performance.now()-this.startTime)/this.transitionDuration;
          
          
          var curve=new Bezier(this.startingX,this.startingY,this.controlX,this.controlY,this.newX,this.newY)

          var currentPoint=curve.get(elapsed);
          // console.log(currentPoint)

          this.x=currentPoint.x;
          this.y=currentPoint.y;
          // this.x=bezierThreePoints(this.x,this.newX,2500,elapsed)
          // this.y=bezierThreePoints(this.y,this.newY,2500,elapsed)
          // this.radius=bezierThreePoints(this.radius,this.newRadius,2500,elapsed)
          
      }

      else{

      }

      if(Math.abs(this.radius-this.newRadius)>0.01){
        this.radiusGrow=(this.newRadius-this.radius)/45;
        this.radius=this.radius+this.radiusGrow;
      }

      if(performance.now()-this.startTime>this.transitionDuration){
        this.moveFlag=false;
      }

      if(mouse!=={}){
          this.hoverable=hoverable;
          var rect=canvas.getBoundingClientRect();
          var relativeMouse={
            x:mouse.x-rect.left,
            y:mouse.y-rect.top
          }

          var X=convertCoord(this.x,0);
          var Y=convertCoord(this.y,1);
          var r=convertRadius(this.radius)

          var dx=relativeMouse.x-X;
          var dy=relativeMouse.y-Y;
          var mouseDis=Math.sqrt(dx*dx+dy*dy)
          
          
          if(Math.abs(mouseDis)<r){
            this.hover=true;
            
            
          }
          else{
            this.hover=false;
          }
      }
      else{
        this.hover=false;
      }

      
      
      if(this.hoverCallback!==undefined && this.hover!==this.lastHover && this.hoverable){
        
        this.hoverCallback();
      }

      this.lastHover=this.hover

      this.draw()
      
  }

  this.setNewPosition=function(x,y,radius,text=""){
    this.moveFlag=true;
    this.startingX=this.x;
    this.startingY=this.y;
    this.newX=x;
    this.newY=y;
    this.newRadius=radius;
    this.controlY=this.startingY;
    this.controlX=this.newX;
    this.startTime=performance.now()
    this.text=text;

    

    this.radiusGrow=(this.newRadius-this.radius)/10;
  }

  this.setNewSizeByRatio=function(ratio,anchor){
    this.newRadius=ratio/100*50;

    if(anchor===1){
      this.newX=100-this.newRadius;
      this.newY=this.newRadius;
    }
    else if(anchor===0){
      this.newX=this.newRadius;
      this.newY=100-this.newRadius;
    }
    
    
    
    this.moveFlag=true;
    this.startingX=this.x;
    this.startingY=this.y;
    
    // this.controlY=this.startingY;
    // this.controlX=this.newX;
    this.controlY=this.newY;
    this.controlX=this.newX;
    this.startTime=performance.now()
    this.text=text;


  }

  this.setHoverCallback=function(callback){
    
    this.hoverCallback=callback
    
  }
}


// function bezierThreePoints(p1,p3,duration,t){

//   var p2= (p1+p3)/2
//   var p = Math.pow((duration-t),2)*p1 + 2*(duration-t)*t*p2 + Math.pow(t,2)*p3

//   return p;
// }

// var curve = new Bezier(100,100,20,20,50,50)
// console.log("Bezier text")
// console.log(curve)
// console.log(curve.get(0.5))

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function precise(x,digits){
  return Number.parseFloat(x).toPrecision(digits);
}


export function lerpColor(a, b, amount) { 

  var ah = +a.replace('#', '0x'),
      ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
      bh = +b.replace('#', '0x'),
      br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
      rr = ar + amount * (br - ar),
      rg = ag + amount * (bg - ag),
      rb = ab + amount * (bb - ab);

  return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}
export function setStateWithDelay(setState,value,delay=1000){
  setTimeout(()=>{setState(value)},delay)
}

export function navigateFunctionSet(sdk,sid){

  sdk.Mattertag.preventAction(sid,{
    navigating:false
  });
  sdk.Mattertag.navigateToTag(sid)
  sdk.Mattertag.preventAction(sid,{
      navigating:true
  });
  
}

