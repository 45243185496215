import React from "react";
import {btn_white_cross} from "../../utils/images"






export default function ContactUs(props){
    const {display,setDisplay,setDisplayingOverlay}=props;

   
   

    var onClose=()=>{
        setDisplay(false);
        setDisplayingOverlay(false);
    }

    if (display===false){
        return(<></>)
    }

    return (<div className="contact-container animated fadeIn">

        <div className="container">
            <div className="row w-100 justify-content-center">
                <div className="col-lg-6 col-md-8 col-10">
                    <div className="header border-bottom-insert text-center">
                        <span>Stay in touch!</span>
                    </div>
                </div>
            </div>
            <div className="row w-100 justify-content-center mt-2">
                <div className="col-lg-6 col-md-8 col-10">
                    <div className="paragraph pre-line text-center">
                    Follow us on our social media or drop us a message on our email to stay updated on news about <i>GiveDangerously</i>.
                    </div>
                </div>
            </div>
            {/* <div className="row  mt-5 justify-content-center">
                <a href="" target="_blank">
                    <div className="media-wrapper o col-auto d-flex justify-content-start align-items-center">
                        <i className="fab fa-facebook-square  fa-2x"></i>
                        <span className="paragraph">GiveDangerously</span>
                    </div>
                </a>
                
            </div> */}
            <div className="row  mt-3 justify-content-center">
                <a href="https://instagram.com/givedangerously.sg" target="_blank" rel="noopener noreferrer">
                    <div  className="media-wrapper  col-auto d-flex justify-content-start align-items-center">
                        <i className="fab fa-instagram  fa-2x"></i>
                        <span className="paragraph">givedangerously.sg</span>
                    </div>
                </a>
            </div>
            <div className="row  mt-3 justify-content-center">
                <a href="https://www.facebook.com/Give-Dangerously-108938484759511" target="_blank" rel="noopener noreferrer">
                    <div  className="media-wrapper  col-auto d-flex justify-content-start align-items-center">
                        <i className="fab fa-facebook  fa-2x"></i>
                        <span className="paragraph">Give Dangerously</span>
                    </div>
                </a>
            </div>
            <div className="row  mt-3 justify-content-center">
                <a href="https://www.youtube.com/channel/UCiZgOUq1AiQ-arJJk9O2LIg" target="_blank" rel="noopener noreferrer">
                    <div  className="media-wrapper  col-auto d-flex justify-content-start align-items-center">
                        <i className="fab fa-youtube  fa-2x"></i>
                        <span className="paragraph">GiveDangerously</span>
                    </div>
                </a>
            </div>
            <div className="row  mt-3 justify-content-center">
                <a href="mailto:hello@givedangerously.today" >
                    <div  className="media-wrapper  col-auto d-flex justify-content-start align-items-center">
                        <i className="far fa-envelope fa-2x"></i>
                        <span className="paragraph">hello@givedangerously.today</span>
                    </div>
                </a>
            </div>

            <div className="row  mt-3 justify-content-center">
                <a href="https://www.givedangerously.today/" target="_blank" rel="noopener noreferrer">
                    <div  className="media-wrapper  col-auto d-flex justify-content-start align-items-center">
                        <i className="fas fa-globe fa-2x"></i>
                        <span  className="paragraph ">Givedangerously.today</span>
                    </div>
                </a>
            </div>
        </div>
        <div className="btn-top-right"  >
            <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
        </div>
    </div>)
}