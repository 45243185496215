import React from "react";
import {btn_white_cross,olive_branch_white} from "../../utils/images"


export default function OliveBranch(props){
    const {display,setDisplay,setDisplayingOverlay}=props;


    var onClose=()=>{
        setDisplay(false);
        setDisplayingOverlay(false);
    }

    if (display===false){
        return(<></>)
    }

    return (<div className="olive-branch-container animated fadeIn">

        <div className="container ">
            <div className="row">
                <div className="col-6 d-flex flex-column justify-content-center">
                    <div className="row">
                        <div className="col-12">
                            <div className="header border-bottom-insert">
                                <span>Significance of the olive branch</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="paragraph pre-line">
                                The olive branch is the symbol of <i>The Char Kway Teow Legacy</i>.<br/><br/>

The olive tree is a gift that keeps giving. Every part of the plant, from the leaves to the fruits to the trunk, is used as food, oil, wood, medicine and more. <br/><br/>

Like this giving tree, the Estates of CJ Koh, Ong Tiong Tat and Irene Tan Liang Kheng strive to be sources of meaningful giving.

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4 ml-4 d-flex flex-column justify-content-center">
                    <img alt="olive branch" style={{transform:"scaleX(-1)"}} src={olive_branch_white} className="w-100" ></img>
                </div>
            </div>
        </div>
        <div className="btn-top-right"  >
            <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
        </div>
    </div>)

}