import React from "react";
import {btn_white_cross} from "../../utils/images"
import {Carousel} from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {main_data} from ".././store"
import { isMobileOnly } from "react-device-detect";

const data=main_data.ack_orgs


export default function AckOrgs(props){
    const {display,setDisplay,setDisplayingOverlay}=props;

    
    
   

    const slides=data.map((item,index)=>{
        return (<div className="image-wrapper">
        {isMobileOnly? (
            <img alt="img" src={item.img_mobile}></img>
        ):(
            <img alt="img" src={item.img}></img>
        )}

        <div className="text-wrapper container overflow-hidden">
        <div className="row">
            <div className="offset-1 col-10">
                <div className="border-bottom-insert"></div>
            </div>
        </div>
        <div className="row mb-4">
            <div className="offset-1 col-10">
                <span className='paragraph'>{item.cap}</span>
            </div>
        </div>
        </div>
        
    </div>)
    })

   
    var onClose=()=>{
        setDisplay(false);
        setDisplayingOverlay(false);
    }

    if (display===false){
        return(<></>)
    }

    return (<div className="ack-orgs-container animated fadeIn">

        <div className="container">
            <div className="row">
                <div className="offset-1 col-10">
                    <div className="header w-100 border-bottom-insert text-center">
                        <span>Acknowledgement</span>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-10 offset-1">

                    <Carousel infiniteLoop={false} showStatus={false}  showArrows={true} showThumbs={false} showIndicators={false} swipeable={true} emulateTouch={false}>
                       {slides}
                    </Carousel>
      
                </div>
            </div>
        </div>
        <div className="btn-top-right"  >
            <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
        </div>
    </div>)
}