import React,{useState,useEffect} from "react";
import {CKTL_logo} from "../../../utils/images"
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

//mobile version
import {bool} from 'prop-types';

MobileSideBar.propTypes = {
    open: bool.isRequired,
}

export default function MobileSideBar(props){
    const {
        open,
        setOpen,
        setDisplayingOverlay,
        displayFeedback,
        setDisplayFeedback,
        displayAuthor,
        setDisplayAuthor,
        displayBookLaunch,
        setDisplayBookLaunch,
        displayOliveBranch,
        setDisplayOliveBranch,
        displayCauses,
        setDisplayCauses,
        displayAckSchools,
        setDisplayAckSchools,
        displayAckOrgs,
        setDisplayAckOrgs,
        displayFlythrough,
        setDisplayFlythrough,
        displayContactUs,
        setDisplayContactUs
    }=props
    const [showSlideDown, setShowSlideDown] = useState(false);

    const [glowGiveaway, setGlowGiveaway] = useState(true);

    function hideAll(){
        setOpen(false)
        setDisplayFeedback(false)
        setDisplayAuthor(false)
        setDisplayBookLaunch(false)
        setDisplayOliveBranch(false)
        setDisplayCauses(false)
        setDisplayAckSchools(false)
        setDisplayAckOrgs(false)
        setDisplayFlythrough(false)
        setDisplayContactUs(false);
        setShowSlideDown(false);
    }

    function onItemClick(setTarget){
        hideAll();
        setTarget(true);
        setDisplayingOverlay(true);
    }

    // function redeemBtn(target){
    //     setGlowGiveaway(false);
    //     onItemClick(target);
    // }

    useEffect(() => {
        setTimeout(()=>{
            if(glowGiveaway)
                setGlowGiveaway(false);
        }, 200000)
    },[glowGiveaway])

    return(
        <div className = "mobile-menu" open={open} style={{ transform: open ? 'translateX(0)' : 'translateX(-100%)' }}>
            <a className="menu-item logo_container" target="_blank" href="https://www.givedangerously.today">
                <img className="logo" alt="logo" src={CKTL_logo} width="100"></img>
            </a>

            <div className="menu-item">
                <div onClick={()=>{onItemClick(setDisplayAuthor)}} className={`item ${displayAuthor?"active":""}`}>Introduction</div>
            </div>

            <div className="menu-item">
                <div onClick={()=>{onItemClick(setDisplayFlythrough)}} className={`item ${displayFlythrough?"active":""}`}>Exhibition Flythrough</div>
            </div>

            <div className="menu-item">
                <div onClick={()=>{onItemClick(setDisplayBookLaunch)}} className={`item ${displayBookLaunch?"active":""}`}>Book Launch and Exhibition Opening Ceremony</div>
            </div>

            <div className="menu-item">
                <div onClick={()=>{onItemClick(setDisplayOliveBranch)}} className={`item ${displayOliveBranch?"active":""}`}>Significance of the Olive Branch</div>
            </div>

            <div className="menu-item">
                <div onClick={()=>{onItemClick(setDisplayCauses)}} className={`item ${displayCauses?"active":""}`}>Causes Supported by the Legacy</div>
            </div>

            {/* <div className="menu-item-redeem">
                <div style={{animation: glowGiveaway? "glow 1.5s ease-in-out infinite alternate" : ""}} onClick={()=>{redeemBtn(setDisplayRedeem)}} className={`item ${displayRedeem?"active":""}`}>Enter the Giveaway</div>
            </div> */}

            <div className="menu-item">
                <div  onClick={()=>setShowSlideDown(!showSlideDown)} className="item akn"><span className="d-flex flex-row">Acknowledgements {showSlideDown ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}</span></div>
            </div>

            <div className="menu-item dropdown">
                <SlideDown>
                        {showSlideDown ? (<div className="col-12 slidedown-wrapper">
                            <div onClick={()=>{onItemClick(setDisplayAckSchools)}} className={`item schools ${displayAckSchools?"active":""}`}>Schools</div>
                            <div onClick={()=>{onItemClick(setDisplayAckOrgs)}} className={`item orgs ${displayAckOrgs?"active":""}`}>Organisations</div>
                        </div>) : null}
                </SlideDown>
            </div>

            <div className="menu-item">
                <div onClick={()=>{onItemClick(setDisplayFeedback)}} className={`item ${displayFeedback?"active":""}`}>Feedback</div>
            </div>

            <div className="menu-item">
                <div onClick={()=>{onItemClick(setDisplayContactUs)}} className={`item ${displayContactUs?"active":""}`}>Contact Us</div>
            </div>

            <div className="footer copyright">
                <span className="bold">&#169; Give Dangerously 2021</span>
            </div>

            <div className="footer hol">
            Powered by <a className="destyled-link" href="https://www.hol.sg/" target="_blank"><span className="bold">HOL Experiences</span></a>
            </div>
        </div>
    );
};
