import React from "react";

export default function ScrollPrompt(props){

    var {color,display,left,top,bottom,right} = props

    if(!display){
        return (<></>)
    }

    return (<a style={{left:left,right:right,top:top,bottom:bottom}} className={`scroll-prompt scroll-prompt__${color}`}><span></span><span></span><span></span></a>)

}