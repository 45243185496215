import React,{useState,useEffect,useRef} from "react";


export default  function UsefulIcons(props){

    const {onClickQues,onClickShare,display,displayQues,displayShare,onClickRead}=props;

    


    return (
    <>
        <div style={{display: display === undefined || display ===true ? "" : "none"}} className="icons-wrapper justify-content-center d-flex">
            <div style={{display: displayShare === undefined || displayShare ===true ? "" : "none"}} className="circle-icon">
                <i style={{color:"white",display: displayQues === undefined || displayQues ===true ? "" : "none"}} onClick={()=>onClickQues()} className="fa fa-question-circle-o fa-lg clickable" aria-hidden="true"></i>
                <i style={{color:"white",display: displayShare === undefined || displayShare ===true ? "" : "none"}} onClick={()=>onClickShare()} className="fa fa-share-alt fa-lg clickable" aria-hidden="true"></i>
            </div>

            <div style={{display: displayShare === undefined || displayShare ===true ? "" : "none"}} className="circle-icon">
                <i style={{color:"white",display: displayShare === undefined || displayShare ===true ? "" : "none"}} onClick={()=>onClickRead()} className="fas fa-book-open fa-lg clickable" aria-hidden="true"></i>
            </div>

            <div style={{display: displayQues === undefined || displayQues ===true ? "" : "none"}} className="circle-icon">
                <i style={{color:"white",display: displayQues === undefined || displayQues ===true ? "" : "none"}} onClick={()=>onClickRead()} className="fas fa-book-open fa-lg clickable" aria-hidden="true"></i>
            </div>
        </div>
    </>
    )
}