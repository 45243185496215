import React from "react"
import {isMobileOnly} from 'react-device-detect';

export default function ControlHint(props){

    const {isAbsolute,left,right,bottom,top,text,display,delay,classes,}=props;

    return (
    <div className = 'info-component'>
        {isMobileOnly ? (
            <div className={`hint-wrapper-mobile paragraph overflow-hidden border-left-insert ${classes}`} style={{display:display || display===undefined ? "" :"none",position:isAbsolute? "absolute":"relative",left:left,right:right,bottom:bottom,top:top,zIndex:"5"}} >
                <span style={{animationDelay: delay ===undefined ? "0.5s" : delay}} className=" d-flex align-items-center">
                <i className='fas fa-info-circle' style={{fontSize:'1.3rem'}}></i> &nbsp; <span className="ml-1">{text}</span>
                </span>
            </div>
        ) :  (
            <div className={`hint-wrapper paragraph overflow-hidden border-left-insert ${classes}`} style={{display:display || display===undefined ? "" :"none",position:isAbsolute? "absolute":"relative",left:left,right:right,bottom:bottom,top:top,zIndex:"5"}} >
                <span style={{animationDelay: delay ===undefined ? "0.5s" : delay}} className=" d-flex align-items-center">
                <i className='fas fa-info-circle' style={{fontSize:'1.5rem'}}></i> &nbsp; <span className="ml-1">{text}</span>
                </span>
            </div>
        )}
    </div>
)
}