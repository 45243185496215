import React from 'react';
import {isMobileOnly} from "react-device-detect"
import {low_power_icon} from "../utils/images"

export default function LowPowerMessage(props){
    const {
        showMessage,
        setShowMessage
    }=props

    var onClick=()=>{
        setShowMessage(false)
        window.location.reload();
    }

    if(showMessage && isMobileOnly){
        return(
            <div className="low-power-message-bg h-100">
                <div className="row justify-content-center">
                    {/* <img className="landscape-message-logo" alt="logo" src={CKTL_logo_subheader}></img> */}
                    <img className="col-auto d-flex flex-row img" src={low_power_icon}/>
                    <p className="message col-12 d-flex flex-row">
                        For the best experience, <br/>please turn off Low-Power Mode on <br/>your device.
                    </p>

                    <button className="theme-btn col-5 close-btn" onClick={()=>onClick()}>Low Power Mode is turned off</button>
                </div>
            </div>
        )
    } else {
        return null;
    }
};