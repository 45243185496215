import React,{useState,useEffect,useRef} from "react";
import {isMobileOnly,isMobile} from "react-device-detect";

import {main_data} from "../store"
import {btn_black_cross,btn_white_cross,classroom_01,classroom_02,classroom_03,edu_student} from "../../utils/images"
import {detectWheelDirectionReact,setStateWithDelay,navigateFunctionSet} from "../../utils/utility"

import ScrollPrompt from "./overlay_content/scroll_prompt"
import ControlHint from "./overlay_content/control_hint"

import UsefulIcons from "./useful_icons"
import ShareModal from "./share_modal"
import {ROOT_URL} from "../../config"
import BackMainButton from "./overlay_content/back_main_button"

import $ from 'jquery'

const data=main_data.education_intro;
const bg=[classroom_01,classroom_02,classroom_03,classroom_03,classroom_03]

const headerText=[`Learning in the classroom`,`Learning in the kitchen`,`Learning in the museums`]

var scrollCounter=0;
var coolDownDuration=2500;
var lastScroll=0;
export default function SuperHero(props){

    const {display,setDisplay,setDisplayMTEverest,sdk,openCallback,closeCallback,isStandalone, pauseBGM}=props;
    const [displayShareModal, setDisplayShareModal] = useState(false);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
    </div>):(<BackMainButton display={true}/>);

    const [state,setState]=useState(0)
    const stateRef=useRef({})
    stateRef.current=state

    const [displayHeader,setDisplayHeader]=useState(true)

    const [touchStartY,setTouchStartY]=useState(0);

    useEffect(() => {
        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
    }, [display]);
   
    var onClose=()=>{
        setDisplay(false);
        setState(0)
        
    }

    var onFindOut=()=>{
        onClose()
        navigateFunctionSet(sdk,"axS9GKxksFj")
        pauseBGM(true);
        setStateWithDelay(setDisplayMTEverest,true,1500)
    }

    var onWheel=(event)=>{
        if (new Date()-lastScroll>coolDownDuration){
            scrollCounter+=1;
            
            var newState=stateRef.current;
            var isValide=false;
            var wheelDirection=detectWheelDirectionReact(event);
            if (scrollCounter>=3){
                if(wheelDirection===0 && stateRef.current<3){    
                    isValide=true
                    newState=stateRef.current+1
                }

                else if(wheelDirection===1 && stateRef.current>0){
                    isValide=true;
                    newState=stateRef.current-1
                }
                
                if (isValide){
                    var changeClassroom=true;
                    if (stateRef.current===3){
                        // $('.black-veil').addClass('animated fadeOut')
                        changeClassroom=false;
                    }
                    else if(stateRef.current===2 && newState ===3){
                        // $('.black-veil').removeClass('fadeOut d-none').addClass('animated fadeIn')
                        changeClassroom=false;
                    }
                    if(changeClassroom){
                        if (wheelDirection===0){
                            $('.classroom-bg').addClass('animated fadeOutDown')
                            setTimeout(()=>{
                                $('.classroom-bg').removeClass('fadeOutDown fadeInUp').addClass('fadeInDown')
                            },500)
                        }
                        else{
                            $('.classroom-bg').addClass('animated fadeOutUp')
                            setTimeout(()=>{
                                $('.classroom-bg').removeClass('fadeOutUp fadeInDown').addClass('fadeInUp')
                            },500)
                        }
                    }
                    $('.intro-text').css('display','none')

                    setDisplayHeader(false)
                    
                    setTimeout(()=>{
                        
                        setState(newState);
                    },500)
                    setTimeout(()=>{
                        setDisplayHeader(true)
                        $('.intro-text').css('display','block')
                    },1000)             
                }             
                scrollCounter=0;
                lastScroll=new Date();
            }
            }
    }

    var onTouchStart=(event)=>{
        if(event.touches[0].clientY){
            setTouchStartY(event.touches[0].clientY)  
        }
           
    }

    var onTouchEnd=(event)=>{
        if(event.changedTouches[0].clientY){
            var newState=state;
            var changeClassroom=true;
            if(event.changedTouches[0].clientY-touchStartY>50 && state<3){
                
                newState=state+1
                
                    

                    
                
                if(stateRef.current===2 && newState ===3){
                    // $('.black-veil').removeClass('fadeOut d-none').addClass('animated fadeIn')
                    changeClassroom=false;
                }
                if(changeClassroom){
                    
                    $('.classroom-bg').addClass('animated fadeOutDown')
                    setTimeout(()=>{
                        $('.classroom-bg').removeClass('fadeOutDown fadeInUp').addClass('fadeInDown')
                    },500)
                      
                }
            }
            else if(event.changedTouches[0].clientY-touchStartY<-50&&state>0){
                newState=state-1
                if (stateRef.current===3){
                    // $('.black-veil').addClass('animated fadeOut')
                    changeClassroom=false;
                }
                $('.classroom-bg').addClass('animated fadeOutUp')
                        setTimeout(()=>{
                            $('.classroom-bg').removeClass('fadeOutUp fadeInDown').addClass('fadeInUp')
                        },500)
            }

                $('.intro-text').css('display','none')
                
                
                setTimeout(()=>{
                    
                    setState(newState);
                },500)
                setTimeout(()=>{
                    
                    $('.intro-text').css('display','block')
                },1000)             
                         
                }

    }

    var onTouchEndMobile=(event)=>{
        if(event.changedTouches[0].clientY){
            var newState=state;
            var changeClassroom=true;
            if(event.changedTouches[0].clientY-touchStartY<5 && state<3){
                
                newState=state+1
                
                if(stateRef.current===2 && newState ===3){
                    changeClassroom=false;
                }
                if(changeClassroom){
                    
                    $('.classroom-bg').addClass('animated fadeOutDown')
                    setTimeout(()=>{
                        $('.classroom-bg').removeClass('fadeOutDown fadeInUp').addClass('fadeInDown')
                    },500)
                      
                }
            }
            else if(event.changedTouches[0].clientY-touchStartY>-5&&state>0){
                newState=state-1
                if (stateRef.current===3){
                    // $('.black-veil').addClass('animated fadeOut')
                    changeClassroom=false;
                }
                $('.classroom-bg').addClass('animated fadeOutUp')
                        setTimeout(()=>{
                            $('.classroom-bg').removeClass('fadeOutUp fadeInDown').addClass('fadeInUp')
                        },500)
            }

                $('.intro-text').css('display','none')
                
                
                setTimeout(()=>{
                    
                    setState(newState);
                },500)
                setTimeout(()=>{
                    
                    $('.intro-text').css('display','block')
                },1000)             
                         
                }

    }
    
    

    var selectStage=(state)=>{
        setState(state)
    }

    useEffect(() => {
        
        if(display){
            // console.log("true")
        }

        return () => {
            
        };
    }, [display]);

    if (!display){
        return (<></>)
    }

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone4_Education.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone4_Education.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    return (
        <div className="education-component">
            {isMobileOnly? (
                <div onTouchEnd={(event)=>{onTouchEndMobile(event)}} onTouchStart={(event)=>{onTouchStart(event)}} onWheel={(event)=>onWheel(event)} className="animated fadeIn full-screen position-absolute education-intro-master-mobile">
                    <div  className="full-screen-black-bg__half position-absolute " />
                    
                    <img className="classroom-bg" alt="classroom" src={bg[state]}></img>
                    <img className="student-bg" alt="student" src={edu_student}></img>
                    

                    <div style={{opacity:state===3? "1" :"0"}} className={`black-veil position-absolute ${state===3 ?"animated fadeIn" : "animated fadeOut"}`}>     
                    </div>

                    <div style={{display: state===3 ? "flex" : "none"}}  className="container education-intro-container h-100 animated fadeIn ">

                        <div className="row w-100">
                            <div className="offset-1 col-10 d-flex flex-column justify-content-center align-items-start wrapper">
                                <div className="w-100"><div className="header border-bottom-insert w-100"><span>Learning can happen anywhere</span></div></div>
                                <div className="border-left-insert overflow-hidden mt-3 pre-line"><span className="" >{`Learning is never confined within the four walls of a classroom. 
                                Why not visit the museums, conduct experiments in your own kitchen or simply take a walk in the park?

                                How about learning on the highest mountain on earth? 
                                (How cool is that?)
                                `}</span></div>

                                <button onClick={()=>{onFindOut()}} className="theme-hover theme-btn-auto mt-3">LET'S FIND OUT</button>

                            </div>
                        </div>
                        
                    </div>

                    <div className="indicator-container">
                        <div onClick={()=>selectStage(0)} className={`indicator ${state===0 ? "active" : ""}`}></div>
                        <div onClick={()=>selectStage(1)}  className={`indicator ${state===1 ? "active" : ""}`}></div>
                        <div onClick={()=>selectStage(2)} className={`indicator ${state===2 ? "active" : ""}`}></div>
                        <div onClick={()=>selectStage(3)}  className={`indicator ${state===3 ? "active" : ""}`}></div>
                    </div>

                    <div className={`header-container  ${state<3 &&displayHeader?"animated fadeIn":"animated fadeOut"}`}>
                        <h1 className="header border-bottom-insert"><span>{headerText[state]}</span></h1>
                        <ControlHint classes="mt-2" text={`Scroll down to see what happens next`}></ControlHint>
                    </div>

                    {topRightBtn}
                    <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
                    <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/education`} des={"Share the exhibit with your friends!"}></ShareModal>   

                    <ScrollPrompt color="white" left="50%" display={state!==3}></ScrollPrompt>
                </div>   
            ):(
                <div onTouchEnd={(event)=>{onTouchEnd(event)}} onTouchStart={(event)=>{onTouchStart(event)}} onWheel={(event)=>onWheel(event)} className="animated fadeIn full-screen position-absolute education-intro-master">
                    <div  className="full-screen-black-bg__half position-absolute " />
                    
                    <img className="classroom-bg" alt="classroom" src={bg[state]}></img>
                    <img className="student-bg" alt="student" src={edu_student}></img>
                    

                    <div style={{opacity:state===3? "1" :"0"}} className={`black-veil position-absolute ${state===3 ?"animated fadeIn" : "animated fadeOut"}`}>     
                    </div>

                    <div style={{display: state===3 ? "flex" : "none"}}  className="container education-intro-container h-100 animated fadeIn ">

                        <div className="row w-100">
                            <div className="offset-3 col-6 d-flex flex-column justify-content-center align-items-start wrapper">
                                <div className="w-100"><div className="header border-bottom-insert w-100"><span>Learning can happen anywhere</span></div></div>
                                <div className="border-left-insert overflow-hidden mt-3 pre-line"><span className="" >{`Learning is never confined within the four walls of a classroom. 
                                Why not visit the museums, conduct experiments in your own kitchen or simply take a walk in the park?

                                How about learning on the highest mountain on earth? 
                                (How cool is that?)
                                `}</span></div>

                                <button onClick={()=>{onFindOut()}} className="theme-hover theme-btn-auto mt-3">LET'S FIND OUT</button>

                            </div>
                        </div>
                        
                    </div>

                    <div className="indicator-container">
                        <div onClick={()=>selectStage(0)} className={`indicator ${state===0 ? "active" : ""}`}></div>
                        <div onClick={()=>selectStage(1)}  className={`indicator ${state===1 ? "active" : ""}`}></div>
                        <div onClick={()=>selectStage(2)} className={`indicator ${state===2 ? "active" : ""}`}></div>
                        <div onClick={()=>selectStage(3)}  className={`indicator ${state===3 ? "active" : ""}`}></div>
                    </div>

                    <div className={`header-container  ${state<3 &&displayHeader?"animated fadeIn":"animated fadeOut"}`}>
                        <h1 className="header border-bottom-insert"><span>{headerText[state]}</span></h1>
                        <ControlHint classes="mt-2" text={`Scroll down to see what happens next`}></ControlHint>
                    </div>

                    

                    {topRightBtn}
                    <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
                    <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/education`} des={"Share the exhibit with your friends!"}></ShareModal>   

                    <ScrollPrompt color="white" left="50%" display={state!==3}></ScrollPrompt>
                </div>
            )} 
        </div>
    )
}
