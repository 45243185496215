import React,{useState,useEffect,useRef} from "react";
import {main_data} from "../store"
import {btn_white_cross,community_singapore_map,community_love_bubble_red,community_heart_end,btn_black_cross} from "../../utils/images"
import Slider,{createSliderWithTooltip} from 'rc-slider';
import {sleep} from '../../utils/utility'
import LoveBubbles from './overlay_content/love_bubbles'
import $ from 'jquery';
import ControlHint from "./overlay_content/control_hint"
import {API_URL_CARING_SCALE} from "../../config"
import { isMobileOnly, isMobile } from "react-device-detect";

import UsefulIcons from "./useful_icons"
import ShareModal from "./share_modal"
import {ROOT_URL} from "../../config"
import BackMainButton from "./overlay_content/back_main_button"


const bubblePositions={
    0:{
        0:[15,20],
        1:[25,30],
        2:[60,30],
        3:[50,46]
    },
    1:{
        0:[45,40],
        1:[65,35],
        2:[20,42],
        3:[40,21]
    },
    2:{
        0:[15,27],
        1:[32,47],
        2:[73,33],
        3:[30,24]
    },
    3:{
        0:[18,27],
        1:[54,25],
        2:[42,41],
        3:[35,22]
    },
    4:{
        0:[10,37],
        1:[38,49],
        2:[61,35],
        3:[40,21]
    },
    5:{
        0:[15,33],
        1:[28,29],
        2:[60,30],
        3:[55,44]
    },
    6:{
        0:[25,43],
        1:[28,29],
        2:[50,30],
        3:[55,34]
    },
    7:{
        0:[10,42],
        1:[48,39],
        2:[63,33],
        3:[36,33]
    },
    8:{
        0:[40,7],
        1:[48,24],
        2:[45,55],
        3:[24,17]
    },
    9:{
        0:[10,29],
        1:[25,18],
        2:[30,40],
        3:[55,35]
    },
}

const correctValue=69;

const endingText=[`Thank you for making
Singapore a caring place`,
`Take a tour of our exhibition.
We hope it’ll give you some ideas on
taking your first step in giving`]
const endingBtn=[`CLOSE`,`OK, GOT IT`]

var bubblesComponents=[];



for (var i=0;i<Object.keys(bubblePositions).length;i++){
    
    var index=(i+1)*10;
    bubblesComponents.push(<LoveBubbles key={`bubble-${index}`} positions={bubblePositions[i]}  groupIndex={index}/>)
}



const SliderWithTooltip = createSliderWithTooltip(Slider);

export default function CommunityServiceHelp(props){
    
    const {display,setDisplay,openCallback,closeCallback,isStandalone}=props;

    const [sliderValue,setSliderValue] = useState(0);

    const [displayButton, setDisplayButton] = useState(true);

    const [state,setState]=useState(0)

    const [sliderDisabled, setSliderDisabled] = useState(false);
    // const sliderValueRef=useRef({})
    // sliderValueRef.current=sliderValue;
    const [ending,setEnding]=useState(0);

    const [displayShareModal, setDisplayShareModal] = useState(false);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>):(<BackMainButton display={true}/>);

    var sliderInterval=0;

    useEffect(() => {
        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
        return () => {
            
        };
    }, [display]);

    var sliderOnChange=(value)=>{
        setSliderValue(value)

        for (var i=1;i<=10;i++){
            var index=i*10;
            var group=$(`.bubble-group__${index} .bubble`)
            var displayed=$(`.bubble-group__${index} .bubble.bounceIn`)
            
            if (index>=value){
                displayed.removeClass('bounceIn').addClass('bounceOut')
            }
            else if(index<value){
                group.removeClass('bounceOut').addClass('bounceIn')
                group.css('opacity','1')
            }
        }
        
    }

    async function onConfirm(){

        var data={
            caring_scale:sliderValue,
        }
        

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        fetch(API_URL_CARING_SCALE, requestOptions)
        .then(response => response.json())


        setState(1)
        setSliderDisabled(true)
        var slidingFrames=5000;
        var dValue=correctValue-sliderValue;
        if(dValue===0){
            dValue=1
        }
        var interval=Math.floor(Math.abs(slidingFrames/dValue))

        $('.confirm-btn').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',()=>{
            
            setDisplayButton(false);
        })

        
        
        $('.confirm-btn').addClass('animated fadeOut');



        // setTimeout(()=>{
        //     setDisplayButton(false)
        // },1500)

        var newValue=sliderValue;

       

        for (var i=0;i<Math.abs(dValue);i++){

            if (dValue>=0){
                newValue=newValue+1
                
                setSliderValue(newValue)           
            }

            else{
                newValue=newValue-1
                setSliderValue(newValue)         
            }

            sliderOnChange(newValue);
            await sleep(interval);
        }
        
        $('.stage-0-wrapper').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',()=>{
            $('.stage-1-wrapper').addClass('animated fadeIn')
            $('.slider-wrapper').removeClass('stage-0-wrapper animated fadeOut').addClass('animated fadeIn stage-1-wrapper')
            setState(2);
        })
        setTimeout(()=>{
            $('.stage-0-wrapper').addClass('animated fadeOut')
        },2000)
        
    }

    var onClose=()=>{
        setSliderValue(100);
        setDisplay(false);
        setState(0);
        setDisplayButton(true);
        setSliderDisabled(false);
        setSliderValue(0);
    }

    useEffect(() => {

        
        
        return () => {
            
        };
    }, []);

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone_3.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone3_ComServ.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    if (display===false ){
        return(<></>)
    }


    return (<div className="full-screen animated fadeIn">
        <div className="full-screen-black-bg__half position-absolute z-index-0 ">

        
        </div>
        <div className="z-index-2 container community-service-help-container h-100">

            
            <div className="main-wrapper row h-100 ">
                <div className="col-6 left-wrapper">
                    
                    <div className="row stage-0-wrapper " style={{display : state===0 ? "" : 'none'}}>
                        <div className='col-12'>
                            <h1 className="header border-bottom-insert w-100"><span>On a scale of 0 to 100,<br/> how caring is Singapore?</span></h1>
                        </div>
                    </div>

                    <div className="row stage-1-wrapper animated fadeIn" style={{display : state===1 ? "" : 'none'}}>
                        <div className='col-12'>
                            <h1 className="header border-bottom-insert w-100"><span>On average, our visitors think that Singapore is 69% on our caring scale.</span></h1>
                        </div>
                    </div>

                    <div className="row animated fadeIn" style={{display : state===0  ? "" : 'none'}}>
                        <div className="col-12">
                            {/* <div className="paragraph overflow-hidden border-left-insert">
                                <span style={{animationDelay:"0.5s"}} className=" d-flex align-items-center ">
                                    <i className='fas fa-info-circle' style={{fontSize:'24px'}}></i> &nbsp; {`Drag the icon to tell us what you think!`}
                                </span>
                            </div> */}
                            <ControlHint text="Drag the icon to tell us what you think"> </ControlHint>
                        </div>
                    </div>

                    <div className="row animated fadeIn" style={{display :  state===1 ? "" : 'none'}}>
                        <div className="col-12">
                            {/* <div className="paragraph overflow-hidden border-left-insert">
                                <span style={{animationDelay:"0.5s"}} className=" d-flex align-items-center ">
                                    <i className='fas fa-info-circle' style={{fontSize:'24px'}}></i> &nbsp; {`Data shown here were collated from the responses of our users.`}
                                </span>
                            </div> */}
                            <ControlHint text="Results are based on the responses of the visitors to our site"> </ControlHint>
                        </div>
                    </div>

                    {/* <div className="row stage-2-wrapper " style={{display : state===2 ? "" : 'none'}}>
                        <div className='col-12'>
                            <h1 className="header"><span className="border-bottom-insert">According to a survey done in 2019, about <span style={{color:"#F1B148"}}>62%</span> of Singaporeans gives back to the community constantly.</span></h1>
                        </div>
                    </div>
                    <div className="row stage-2-wrapper " style={{display : state===2 ? "" : 'none'}}>
                        <div className="col-12">
                        <p className="paragraph">*Adapted from an quarterly survey done by the Ministry of Culture, Community and Youth (MCCY) </p>
                        </div>
                    </div> */}
                        {isMobileOnly?(
                            <div style={{display:state===1 || state ===0 ? "" : "none", padding:"10px"}} className="row mt-5 mb-5 slider-wrapper stage-0-wrapper ">
                                <div className='col-11 '>
                                
                                    <SliderWithTooltip tipProps={{
                                        placement:"bottom",
                                        visible:true
                                    }} 
                                    marks={{0:"0%",100:"100%"}} handleStyle={{}} railStyle={{backgroundColor:"white",height:'12px'}} trackStyle={{backgroundColor:"#F1B148",height:'12px'}} 
                                    value={sliderValue}
                                    onChange={(value)=>sliderOnChange(value)}
                                    disabled={sliderDisabled}
                                    
                                    />
                                </div>
                            </div>
                        ):(
                            <div style={{display:state===1 || state ===0 ? "" : "none"}} className="row mt-5 mb-5 slider-wrapper stage-0-wrapper ">
                                <div className='col-11 '>
                                
                                    <SliderWithTooltip tipProps={{
                                        placement:"bottom",
                                        visible:true
                                    }} 
                                    marks={{0:"0%",100:"100%"}} handleStyle={{}} railStyle={{backgroundColor:"white",height:'12px'}} trackStyle={{backgroundColor:"#F1B148",height:'12px'}} 
                                    value={sliderValue}
                                    onChange={(value)=>sliderOnChange(value)}
                                    disabled={sliderDisabled}
                                    
                                    />
                                </div>
                            </div>
                        )}
                    <div className="row mt-5 stage-0-wrapper " style={{display : state===0 || state===1 ? "" : 'none'}}>
                        <div className="col-auto">
                            <button onClick={()=>{
                                onConfirm()
                            }} style={{opacity:displayButton ? "1" : "0"}} className="ml-0 confirm-btn theme-btn theme-hover ">Continue</button>
                        </div>
                    </div>

                    
                    <div className="row stage-2-wrapper  animated fadeIn" style={{display : state===2 ? "" : 'none'}}>
                        <div className="col-12">
                        <div className="header border-bottom-insert text-center w-100"><span>Have you started your own giving journey yet? </span></div>
                        </div>
                    </div>

                    {isMobileOnly?(
                        <div className="row stage-2-wrapper mt-4 animated fadeIn " style={{display : state===2 ? "" : 'none'}}>
                            <div className="col-md-6 col-12">
                                <button  style={{padding:"8px"}} onClick={()=>{setEnding(1);setState(3)}} className="theme-btn-auto theme-hover w-100 h-100">NO. CAN YOU SHOW ME HOW TO START?</button>
                            </div>

                            <div className="col-md-6 col-12">
                                <button  style={{padding:"8px"}} onClick={()=>{setEnding(0);setState(3)}} className="theme-btn-auto theme-hover w-100 h-100">YES, I’VE ALREADY STARTED</button>
                            </div>
                        </div>
                    ):(
                        <div className="row stage-2-wrapper mt-4 animated fadeIn " style={{display : state===2 ? "" : 'none'}}>
                            <div className="offset-md-1 col-md-5 col-12">
                                <button  onClick={()=>{setEnding(1);setState(3)}} className="theme-btn-auto theme-hover w-100 h-100">NO. CAN YOU SHOW ME HOW TO START?</button>
                            </div>

                            <div className="col-md-5 col-12">
                                <button  onClick={()=>{setEnding(0);setState(3)}} className="theme-btn-auto theme-hover w-100 h-100">YES, I’VE ALREADY STARTED</button>
                            </div>
                        </div>
                    )}

                    <div className="row animated fadeIn" style={{display:state===3?"" :"none"}}>
                        <div className="col-12 d-flex justify-content-center">
                            <div className="header border-bottom-insert pre-line">
                                <span style={{fontSize: "1.8rem"}} className='text-center d-block'>{endingText[ending]}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center animated fadeIn mt-3" style={{display:state===3?"" :"none"}}>
                        <div className="col-auto">
                            <button onClick={()=>{onClose()}} className="theme-btn-auto theme-hover">{endingBtn[ending]}</button>
                        </div>
                    </div>

                </div>
                <div className="col-6 row justify-content-center align-content-center">
                    
                        <div className="col-11 offset-1">
                            <div className="w-100 mt-3">
                                <img className="map" src={community_singapore_map} alt="map"></img>
                                
                                <div className={`bubble-wrapper ${state>1 ? "animated fadeOut" : ""}`}>
                                {bubblesComponents}
                                </div>

                                <img alt="big bubble" src={community_heart_end} className="animated fadeIn big-bubble" style={{display:state>1 ? "" : "none"}}></img>
                                
                            </div>
                        </div>
                    
                </div>
            </div>
            
            {topRightBtn}
        </div>
        <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
        <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/community-service`} des={"Share the exhibit with your friends!"}></ShareModal>
    </div>
        )
}
