import React,{useState,useEffect,useRef} from "react";
import {setStateWithDelay} from '../utils/utility'
import FourCards from "./components/four_cards";
import StoryCards from "./components/story_cards";
import CommunityServiceIntro from "./components/community_service_intro"
import CommunityServiceHelp from "./components/community_service_help"
import Wicare from "./components/wicare"
import ArtTherapy from './components/art_therapy'
import Dementia from './components/dementia'
import SuperHero from './components/super_hero'
import EducationIntro from './components/education_intro'
import MTEverest from './components/mt_everest'
import TCM from './components/TCM'
import Caregiver from './components/caregiver'
import Superpower from './components/superpower'
import IntroOption from "./components/intro_option";
import EducationValues from "./components/education_values"
import Library from "./components/library"

import Sidebar from "./components/sidebar"
import MobileSideBar from "./components/mobile components/mobile_sidebar"
import MenuIcon from "./components/mobile components/menu_icon"
import {isMobileOnly, isMobile, isIOS} from 'react-device-detect'

import Ending from "./components/ending"
import Tutorial from "./components/tutorial"

import Feedback from "./components/feedback"
//import Author from "./components/author"
import Author from "./components/author"
import BookLaunch from "./components/book_launch"
import AckSchools from "./components/ack_schools"
import AckOrgs from "./components/ack_orgs"
import OliveBranch from "./components/olive_branch"
import Causes from "./components/causes"
import Map from "./components/map"
import Flythrough from "./components/flythrough"
import ContactUs from "./components/contact_us"
import PopUp from "./components/overlay_content/pop_up"

import {bgm} from "../utils/sounds"
import {mute_icon, unmute_icon} from "../utils/images"

import {MATTERPORT_SDK_KEY} from "../config"

//currently hosted on imgbb
const hotspot_icon_urls=[
  "https://i.ibb.co/T0VxNyq/h1-intro.webp",
  "https://i.ibb.co/ggNdjjk/h2-who.webp",
  "https://i.ibb.co/Qj7MnJf/h3-what.webp",
  "https://i.ibb.co/hyxcvDD/h4-legaled.webp",
  "https://i.ibb.co/RSRjgM9/h5-superhero.webp",
  "https://i.ibb.co/fqxNyH0/h6-lawlib.webp",
  "https://i.ibb.co/hs9PBPy/h7-comserv.webp",
  "https://i.ibb.co/Ss1Rsyw/h8-give.webp",
  "https://i.ibb.co/YNxzJ6j/h9-arttherapy.webp",
  "https://i.ibb.co/PwJB378/h10-howtohelp.webp",
  "https://i.ibb.co/9sPdgN9/h11-edu.webp",
  "https://i.ibb.co/CzNwxHP/h12-mtev.webp",
  "https://i.ibb.co/X79jfQM/h13-nie.webp",
  "https://i.ibb.co/pzpxGf1/h14-caregiver.webp",
  "https://i.ibb.co/xmMtmkS/h15-tcm.webp",
  "https://i.ibb.co/zh3NvjH/h16-dementia.webp"

]

//var showCaseIframe;
var matterTags;
var sdk;
var counterInterval;

// check if document hidden
var hidden, visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}
// music loop
var muteLerp;
var playLerp;


var dictionarySweepMattertag={
  "SZFfAHsz8Jk":"a061f67c36d54907b7e6190a433dc8f1",
  "EUDkLgBzTcE":"df35630d84b14508a77c25941a9fdf6c",
  "5DNc56ffQYO":"3f9f3f04ac72460e86a8c27ea6624e36",
  "Op4wbUvj5tO":"2aeb9b432fc44c62b099e4123ab4bfba",
  "cBBdVrK5Xxz":"bda7b0ba0b3c45f3b0c58699fc49d054",
  "uIXSFfeSOtq":"668a4b973e094b22839112bfb344b379",
  "OGXuUhj2efU":"e1383e96dea14534a528517334fb3354",
  "TbQ8l8Y9nFk":"6c4eea2f0240425185a5df57cd00feca",
  "c4ZQGaWlcAH":"c1cbd6d6b3b74e72aadd501d97d57dc2",
  "suYGrnIkVGB":"de5b3cc4856e4811b0c70ba9291bc694",
  "81mkvNin8Wz":"28bfc928c4e14fa2b18579f5379a1642",
  "axS9GKxksFj":"7bbaad66bf874753a6831e1ede2afd4f",
  "W52xdNeWZuG":"71852df225df4a6f9aaeacd15a03e28e",
  "DtheO2EocrQ":"c5538cd7ab404778b688a00a94899553",
  "wETbaCTwLuZ":"8c9da96bb33148e7a1150e46db6f2753",
  "fPFASlHNpGQ":"7be7eb6c8bd24183baac8ba9b725f403"
}

// console.log(dictionarySweepMattertag["5DNc56ffQYO"])

export default function Tour(props){

  const {setTourCounters}=props;

  //music const
  const [muted,setMuted]=useState(false);
  const mutedRef=useRef({})
  mutedRef.current=muted;
  const [bgmIsPlaying,setBgmIsPlaying]=useState(false);
  const bgmIsPlayingRef=useRef({})
  bgmIsPlayingRef.current=bgmIsPlaying;
  const [showWelcome,setShowWelcome]=useState(true);
  const showWelcomeRef=useRef({});
  showWelcomeRef.current=showWelcome;

  const matterTagClickCallback = (sid) => {
    var invalid=false;

    if (sid==="fPFASlHNpGQ"){
      pauseMusic();
      setStateWithDelay(setDisplayDementia,true);
    }

    else if (sid==="c4ZQGaWlcAH"){
      //pauseMusic();
      setStateWithDelay(setDisplayWicare,true);
    }

    else if(sid==="TbQ8l8Y9nFk"){
      //pauseMusic();
      setStateWithDelay(setDisplayArtTherapy,true)
    }

    else if(sid==="Op4wbUvj5tO"){
      //pauseMusic();
      setStateWithDelay(setDisplaySuperpower,true)
    }
    else if(sid==="cBBdVrK5Xxz"){
      pauseMusic();
      setStateWithDelay(setDisplaySuperHero,true)
    }

    else if(sid==="axS9GKxksFj"){
      pauseMusic();
      setStateWithDelay(setDisplayMTEverest,true)
    }

    else if(sid==="DtheO2EocrQ"){
      pauseMusic();
      setStateWithDelay(setDisplayCaregiver,true)
    }

    else if(sid==="wETbaCTwLuZ"){
      //pauseMusic();
      // sdk.Sweep.moveTo("8c9da96bb33148e7a1150e46db6f2753");
      setStateWithDelay(setDisplayTCM,true)
    }

    else if(sid==="EUDkLgBzTcE"){
      //pauseMusic();
      setStateWithDelay(setDisplayFourCards,true)
    }

    else if(sid==="5DNc56ffQYO"){
      //pauseMusic();
      setStateWithDelay(setDisplayStoryCards,true)
    }

    else if(sid==="OGXuUhj2efU"){
      //pauseMusic();
      setStateWithDelay(setDisplayCommunityServiceIntro,true)
    }

    else if(sid==="suYGrnIkVGB"){
      //pauseMusic();
      setStateWithDelay(setDisplayCommunityServiceHelp,true)
    }

    else if(sid==="81mkvNin8Wz"){
      //pauseMusic();
      setStateWithDelay(setDisplayEducationIntro,true)
    }

    else if (sid==="SZFfAHsz8Jk"){
      //pauseMusic();
      setStateWithDelay(setDisplayIntroOption,true)
      // sdk.Sweep.moveTo("a061f67c36d54907b7e6190a433dc8f1");
    }

    else if (sid==="W52xdNeWZuG"){
      //pauseMusic();
      setStateWithDelay(setDisplayEducationValues,true)
    }

    else if (sid==="uIXSFfeSOtq"){
      //pauseMusic();
      setStateWithDelay(setDisplayLibrary,true)
    }

    else{
      console.log("Not an avaliable tag")
      invalid=true;

    }

    if(!invalid){

      sdk.Sweep.moveTo(dictionarySweepMattertag[sid]);
    }

  };

const sweepEnterCallback=(oldSweep,newSweep)=>{
  // console.log("Left Sweep: "+oldSweep);
  // console.log("Entered Sweep: "+newSweep);

  // if (newSweep==="3e18b0a967ff4557ad8d1c7e2c81d54a"|| newSweep==="a061f67c36d54907b7e6190a433dc8f1"){
  //   setDisplayIntroOption(true);
  // }

};

const sweepExitCallback=(oldSweep,newSweep)=>{
  // console.log("Leaving Sweep: "+oldSweep);
  // console.log("Entering Sweep: "+newSweep);


  // setDisplayIntroOption(false);



};

const modeChangeStartCallback=(oldMode,newMode)=>{
  // console.log("Old Mode: "+oldMode);
  // console.log("new Mode: "+newMode);

  // setDisplayIntroOption(false);

};

const registerIcons=()=>{
  for (var i=0; i<hotspot_icon_urls.length ; i++){
    sdk.Mattertag.registerIcon(`h${i+1}`,`${hotspot_icon_urls[i]}`)
  }
}

const replaceIcons=()=>{
  sdk.Mattertag.editIcon("SZFfAHsz8Jk","h1");
  sdk.Mattertag.editIcon("EUDkLgBzTcE","h2");
  sdk.Mattertag.editIcon("5DNc56ffQYO","h3");
  sdk.Mattertag.editIcon("Op4wbUvj5tO","h4");
  sdk.Mattertag.editIcon("cBBdVrK5Xxz","h5");
  sdk.Mattertag.editIcon("uIXSFfeSOtq","h6");
  sdk.Mattertag.editIcon("OGXuUhj2efU","h7");
  sdk.Mattertag.editIcon("c4ZQGaWlcAH","h8");
  sdk.Mattertag.editIcon("TbQ8l8Y9nFk","h9");
  sdk.Mattertag.editIcon("suYGrnIkVGB","h10");
  sdk.Mattertag.editIcon("81mkvNin8Wz","h11");
  sdk.Mattertag.editIcon("axS9GKxksFj","h12");
  sdk.Mattertag.editIcon("W52xdNeWZuG","h13");
  sdk.Mattertag.editIcon("DtheO2EocrQ","h14");
  sdk.Mattertag.editIcon("wETbaCTwLuZ","h15");
  sdk.Mattertag.editIcon("fPFASlHNpGQ","h16");
}

//mobile menu
const [openMenu, setOpen] = useState(false);

// music
function toggleMusic(){
  if(muted){
    document.querySelector('.bgm').play();
  }
  else{
    document.querySelector('.bgm').pause()
  }

  setMuted(!muted);
  
}

function playMusic(){
  document.querySelector('.bgm').play();
  setMuted(false);
}

function stopMusic(){
  document.querySelector('.bgm').pause();
  setMuted(true);

}

function lerpMusic(targetVolume){
  var bgm=document.querySelector('.bgm');
  
  if (bgm.volume-targetVolume>0){
    bgm.volume=bgm.volume-0.05>=targetVolume?bgm.volume-0.05:targetVolume;
    // bgm.volume=bgm.volume-0.05>=targetVolume?bgm.volume-0.05:targetVolume;

    if(bgm.volume<=targetVolume){
      clearInterval(muteLerp);
    }
  }
  else if(bgm.volume-targetVolume<0){
    bgm.volume=bgm.volume+0.05<=targetVolume?bgm.volume+0.05:targetVolume;
    if(bgm.volume>=targetVolume){
      clearInterval(playLerp);
    }
  }
}

function musicOnVisibilityChange(){
  if ('hidden' in document) {
    if(document[hidden]){
      document.querySelector('.bgm').pause();
    }
    else{
      if(!mutedRef.current){
        document.querySelector('.bgm').play();
      }
    }
  }
  
}

function pauseMusic(){
  setBgmIsPlaying(!mutedRef.current);

  if(isIOS&&isMobile){
    
    stopMusic();
  }
  else{
    clearInterval(muteLerp);
    clearInterval(playLerp);
    muteLerp=setInterval(()=>{lerpMusic(0)},100)
  }
}

function showcaseLoader(iframe) {
  
    try {
      window.MP_SDK.connect(
        iframe, // Obtained earlier
        MATTERPORT_SDK_KEY, // Your API key//
        '3.6' // SDK version you are using
        // Use the latest version you can for your app
        )
        .then(async (mpSDK)=>{
            sdk=mpSDK;
            sdk.App.state.subscribe(function (appState) {
              if(appState.phase === sdk.App.Phase.PLAYING) {

                registerIcons();
                replaceIcons();

                setMatterportLoaded(true);

                matterTags=getMatterTagData()
                startCounterInterval();

                sdk.Mattertag.getData()
                .then((data)=>{
                  matterTags=data;
                  init();
                })
                .catch((error)=>{
                  console.log(error)
                })


             };


        })
      })
        .catch((e)=>{
          console.error(e);
          console.error("Connection failed, try again")
          console.error("Reconnecting...");
          showcaseLoader(iframe);
        });
    }
    catch (e) {
      console.error(e);
      console.error("Reconnecting...");
      showcaseLoader(iframe);
    }


};

function getMatterTagData(){
    return sdk.Mattertag.getData().then((data)=>{
      matterTags=data;
    })
  };

function displayMatterTags(stage){
if (stage===0){
    for (var i=0;i<matterTags.length;i++){

      sdk.Mattertag.editOpacity(matterTags[i].sid,1);

    }
    sdk.Mattertag.editOpacity("SZFfAHsz8Jk",1);

  }
}

function init(){
    sdk.on(sdk.Mattertag.Event.CLICK,matterTagClickCallback)
    sdk.on(sdk.Sweep.Event.ENTER,sweepEnterCallback)
    sdk.on(sdk.Sweep.Event.EXIT,sweepExitCallback)
    sdk.on(sdk.Mode.Event.CHANGE_START,modeChangeStartCallback)

    if(matterTags.length>0){
      //console.log(matterTags)
      for (var i = 0;i<matterTags.length;i++){

        sdk.Mattertag.preventAction(matterTags[i].sid,{
          navigating:true
        })
      }
    }

    sdk.Mattertag.preventAction("SZFfAHsz8Jk",{
      navigating:true
    })

    sdk.Mattertag.preventAction("wETbaCTwLuZ",{
      navigating:true
    })



}

function Test(){
    // console.log(matterTags)
    // matterTags.map((tag)=>{
    //   console.log(tag.sid)
    // })
    // setDisplayCaregiver(true)

    // var URL = "/api/handle.php";
    // var data = { id: id, four_cards: fourCardsCounter,community_service_intro:communityServiceIntroCounter,community_service_help: communityServiceHelpCounter,art_therapy:artTherapyCounter,dementia:dementiaCounter,super_hero:superHeroCounter,education_intro:educationIntroCounter,MT_everest:MTEverestCounter,TCM:TCMCounter,caregiver:caregiverCounter,superpower:superPowerCounter };
    // data=JSON.stringify(data)
    // // console.log(data)

    // // var blob=new Blob([data], {type : 'application/json; charset=UTF-8'})
    // navigator.sendBeacon(URL, data);
  }

    const [displayFourCards, setDisplayFourCards] = useState(false);
    const displayFourCardsRef=useRef({})
    displayFourCardsRef.current=displayFourCards



    const [displayIntroOption,setDisplayIntroOption]=useState(false)
    const displayIntroOptionRef=useRef({})
    displayIntroOptionRef.current=displayIntroOption

    const [displayStoryCards,setDisplayStoryCards]=useState(false);
    const displayStoryCardsRef=useRef({})
    displayStoryCardsRef.current=displayStoryCards

    const [displayCommunityServiceIntro,setDisplayCommunityServiceIntro]=useState(false);
    const displayCommunityServiceIntroRef=useRef({})
    displayCommunityServiceIntroRef.current=displayCommunityServiceIntro

    const [displayCommunityServiceHelp,setDisplayCommunityServiceHelp]=useState(false);
    const displayCommunityServiceHelpRef=useRef({})
    displayCommunityServiceHelpRef.current=displayCommunityServiceHelp

    const [displayArtTherapy,setDisplayArtTherapy]=useState(false);
    const displayArtTherapyRef=useRef({})
    displayArtTherapyRef.current=displayArtTherapy

    const [displayDementia,setDisplayDementia]=useState(false);
    const displayDementiaRef=useRef({})
    displayDementiaRef.current=displayDementia

    const [displaySuperHero,setDisplaySuperHero]=useState(false)
    const displaySuperHeroRef=useRef({})
    displaySuperHeroRef.current=displaySuperHero

    const [displayEducationIntro,setDisplayEducationIntro]=useState(false)
    const displayEducationIntroRef=useRef({})
    displayEducationIntroRef.current=displayEducationIntro

    const [displayMTEverest,setDisplayMTEverest]=useState(false)
    const displayMTEverestRef=useRef({})
    displayMTEverestRef.current=displayMTEverest

    const [displayTCM,setDisplayTCM]=useState(false)
    const displayTCMRef=useRef({})
    displayTCMRef.current=displayTCM

    const [displayCaregiver,setDisplayCaregiver]=useState(false)
    const displayCaregiverRef=useRef({})
    displayCaregiverRef.current=displayCaregiver

    const [displaySuperpower,setDisplaySuperpower]=useState(false)
    const displaySuperpowerRef=useRef({})
    displaySuperpowerRef.current=displaySuperpower

    const [displayEducationValues,setDisplayEducationValues]=useState(false)
    const displayEducationValuesRef=useRef({})
    displayEducationValuesRef.current=displayEducationValues

    const [displayLibrary,setDisplayLibrary]=useState(false)
    const displayLibraryRef=useRef({})
    displayLibraryRef.current=displayLibrary

    const [displayWicare,setDisplayWicare]=useState(false)
    const displayWicareRef=useRef({})
    displayWicareRef.current=displayWicare

    const [displayFeedback,setDisplayFeedback]=useState(false)
    const [displayAuthor,setDisplayAuthor]=useState(false)
    const [displayBookLaunch, setDisplayBookLaunch] = useState(false);
    const [displayAckSchools,setDisplayAckSchools]=useState(false)
    const [displayAckOrgs,setDisplayAckOrgs]=useState(false)
    const [displayOliveBranch,setDisplayOliveBranch]=useState(false)
    const [displayCauses, setDisplayCauses] = useState(false);
    const [displayMap, setDisplayMap]=useState(false)
    const [displayFlythrough, setDisplayFlythrough] = useState(false);
    const [displayContactUs,setDisplayContactUs]=useState(false)

    const [displayEnding,setDisplayEnding]=useState(false)

    const [showSidebar,setShowSidebar]=useState(true)
    const [showMobileSidebar, setShowMobileSidebar]=useState(true)

    const [visitedHS,setVisitedHS]=useState([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])
    const [displayingOL,setDisplayingOL]=useState(false);
    const [displayHotspot,setDisplayHotspot]=useState(false);

    const [displayTutorial,setDisplayTutorial]=useState(false)

    const [viewedTutorial,setViewedTutorial]=useState(false)

    const [matterportLoaded,setMatterportLoaded]=useState(false)

    const [showCaseIframe, setShowCaseIframe] = useState(undefined);

    //competition giveaway
    // const [displayRedeem,setDisplayRedeem]=useState(false)
    const [displayPopUp, setDisplayPopUp]=useState(true)

    //music
    const [pauseBGM, setPauseBGM]= useState(false);

    function overlayOpenCallback(index){
      var newVisitedHS=[...visitedHS]
      newVisitedHS[index]=true;
      setVisitedHS(newVisitedHS)
      setShowMobileSidebar(false);
      setDisplayHotspot(true);
    }

    function overlayCloseCallback(index){
      //setDisplayingOL(false);
      setShowMobileSidebar(true);
      setDisplayHotspot(false);
      setPauseBGM(false);

      if(bgmIsPlayingRef.current){
        
        if(isIOS&&isMobile){
          
          toggleMusic();
        }
        else{
          clearInterval(muteLerp);
          clearInterval(playLerp);
          playLerp=setInterval(()=>{lerpMusic(1)},150)
        }
        
      }
    }

    function startCounterInterval(){
      var counters={
        masterCounter:0,
        introOptionCounter:0,
        fourCardsCounter:0,
        storyCardsCounter:0,
        superPowerCounter:0,
        superHeroCounter:0,
        libraryCounter:0,
        communityServiceIntroCounter:0,
        artTherapyCounter:0,
        wicareCounter:0,
        communityServiceHelpCounter:0,
        educationIntroCounter:0,
        MTEverestCounter:0,
        educationValuesCounter:0,
        caregiverCounter:0,
        TCMCounter:0,
        dementiaCounter:0,};
      counterInterval=setInterval(()=>{

        switch(true){
          case displayIntroOptionRef.current:
            counters.introOptionCounter+=1;
            break;

          case displayFourCardsRef.current:
            counters.fourCardsCounter+=1;

            break;
          case displayStoryCardsRef.current:
            counters.storyCardsCounter+=1;
            break;

          case displayLibraryRef.current:
            counters.libraryCounter+=1;
            break;

          case displayEducationValuesRef.current:
            counters.educationValuesCounter+=1;
            break;
          case displayCommunityServiceIntroRef.current:
            counters.communityServiceIntroCounter+=1;
            break;
          case displayCommunityServiceHelpRef.current:
            counters.communityServiceHelpCounter+=1;
            break;
          case displayWicareRef.current:
            counters.wicareCounter+=1;
            break;
          case displayArtTherapyRef.current:
            counters.artTherapyCounter+=1;
            break;
          case displayDementiaRef.current:
            counters.dementiaCounter+=1;
            break;
          case displaySuperHeroRef.current:
            counters.superHeroCounter+=1;
            break;

          case displayEducationIntroRef.current:
            counters.educationIntroCounter+=1;
            break;

          case displayMTEverestRef.current:
            counters.MTEverestCounter+=1;
            break;

          case displayTCMRef.current:
            counters.TCMCounter+=1;
            break;

          case displayCaregiverRef.current:
            counters.caregiverCounter+=1;
            break;

          case displaySuperpowerRef.current:
            counters.superPowerCounter+=1;
            break;
        }
        counters.masterCounter+=1;
        setTourCounters(counters)

      },1000)

    }

    async function loadShowcase(){
        if(document.getElementById('showcase-iframe')){
          setShowCaseIframe(document.getElementById('showcase-iframe'))
        }
        else{
          console.log("iframe not found, retrying...");
            setTimeout(()=>{
              loadShowcase();
          },200)
        }
    }

    useEffect(() => {
      if(showCaseIframe){
        console.log("Loading showcase");
        showcaseLoader(showCaseIframe);
      }
      else{
        console.log("Component mounted, setting state...");
        loadShowcase();
      }


    }, [showCaseIframe]);

    useEffect(() => {

      if(!displayHotspot){
        var allVisited=true;
        for(var i=0;i<visitedHS.length;i++){
          if(visitedHS[i]===false){
            allVisited=false;
            break;
          }
        }

        if(allVisited){
          setDisplayEnding(true);
        }
      }

      return () => {

      };
    }, [visitedHS,displayHotspot]);

    //check if tab is in front, pause music if it is not
    useEffect(() => {
      document.addEventListener(visibilityChange,musicOnVisibilityChange)
      return () => {
        document.removeEventListener(visibilityChange,musicOnVisibilityChange)
      };

    }, []);

    //pause music when selected overlay is active
    useEffect(()=>{
      if(displayingOL === true){
        setBgmIsPlaying(!mutedRef.current);
        pauseMusic();
      } 
      else { 
        setBgmIsPlaying(mutedRef.current);
        if(isIOS&&isMobile){
          playMusic();
        }
        else{
          clearInterval(muteLerp);
          clearInterval(playLerp);
          playLerp=setInterval(()=>{lerpMusic(1)},150)
        }
      }
    },[displayingOL])

    //mute/unmute music function
    useEffect(()=>{
      if(pauseBGM === true){
        setBgmIsPlaying(!mutedRef.current);
        pauseMusic();
      }
    },[pauseBGM])

    useEffect(()=>{
      if(matterportLoaded === true){
        playMusic();
      }
    }, [matterportLoaded])

    return(
    <>
    {/* <div className="debug-button">
      <button  style={{display:'none'}} className="btn btn-primary" onClick={()=>Test()}>Click to test</button>
    </div> */}

    <div className="main-layout-wrapper">
      {/* check if it is tablet or mobile */}
      {isMobile ? (
        <div className = "mobile-menu-components">
          <MenuIcon open={openMenu} setOpen={setOpen} show={showMobileSidebar}/>
          <MobileSideBar
            open ={openMenu}
            setOpen={setOpen}
            setDisplayingOverlay={setDisplayingOL}
            displayFeedback={displayFeedback}
            setDisplayFeedback={setDisplayFeedback}
            displayAuthor={displayAuthor}
            setDisplayAuthor={setDisplayAuthor}
            displayBookLaunch={displayBookLaunch}
            setDisplayBookLaunch={setDisplayBookLaunch}
            displayOliveBranch={displayOliveBranch}
            setDisplayOliveBranch={setDisplayOliveBranch}
            displayCauses={displayCauses}
            setDisplayCauses={setDisplayCauses}
            displayAckSchools={displayAckSchools}
            setDisplayAckSchools={setDisplayAckSchools}
            displayAckOrgs={displayAckOrgs}
            setDisplayAckOrgs={setDisplayAckOrgs}
            displayFlythrough={displayFlythrough}
            setDisplayFlythrough={setDisplayFlythrough}
            displayContactUs={displayContactUs}
            setDisplayContactUs={setDisplayContactUs}
          />
        </div>
      ) : (
        <Sidebar
          show={showSidebar}
          setDisplayingOverlay={setDisplayingOL}
          displayFeedback={displayFeedback}
          setDisplayFeedback={setDisplayFeedback}
          displayAuthor={displayAuthor}
          setDisplayAuthor={setDisplayAuthor}
          displayBookLaunch={displayBookLaunch}
          setDisplayBookLaunch={setDisplayBookLaunch}
          displayOliveBranch={displayOliveBranch}
          setDisplayOliveBranch={setDisplayOliveBranch}
          displayCauses={displayCauses}
          setDisplayCauses={setDisplayCauses}
          displayAckSchools={displayAckSchools}
          setDisplayAckSchools={setDisplayAckSchools}
          displayAckOrgs={displayAckOrgs}
          setDisplayAckOrgs={setDisplayAckOrgs}
          displayFlythrough={displayFlythrough}
          setDisplayFlythrough={setDisplayFlythrough}
          displayContactUs={displayContactUs}
          setDisplayContactUs={setDisplayContactUs}
          />
      )} {/** end of isMobileOnly*/}

      <div className="iframe-wrapper">
        <iframe
            title="MPShowcase"
            src="https://my.matterport.com/show/?m=bBgdvb3Jnux&play=1&qs=1&mls=2"
            frameBorder="0" allowFullScreen={false}
            id="showcase-iframe">
        </iframe>

        {isMobileOnly? (
          <div onClick={()=>{setDisplayTutorial(true)}} className={`tutorial-btn-wrapper-mobile ${matterportLoaded?"show":""}`}>
            <i className={`lni lni-question-circle ${viewedTutorial? "":"glow"}`}></i>
          </div>
        ):(
          <div onClick={()=>{setDisplayTutorial(true)}} className={`tutorial-btn-wrapper ${matterportLoaded?"show":""}`}>
            <i className={`lni lni-question-circle ${viewedTutorial? "":"glow"}`}></i>
          </div>
        )}

        <span onClick={()=>{toggleMusic()}} className="music-wrapper" style={{display: matterportLoaded? "":"none"}}>
          <img alt="music" className="w-100" src={muted?mute_icon:unmute_icon}></img>
          <audio loop={true} className="bgm" controls={false} src={bgm}></audio>
        </span>


        <Feedback display={displayFeedback} setDisplay={setDisplayFeedback} setDisplayingOverlay={setDisplayingOL}></Feedback>

        <Author display={displayAuthor} setDisplay={setDisplayAuthor} setDisplayingOverlay={setDisplayingOL}></Author>

        <Flythrough display={displayFlythrough} setDisplay={setDisplayFlythrough} setDisplayingOverlay={setDisplayingOL}></Flythrough>

        <BookLaunch display={displayBookLaunch} setDisplay={setDisplayBookLaunch} setDisplayingOverlay={setDisplayingOL}></BookLaunch>

        <AckSchools display={displayAckSchools} setDisplay={setDisplayAckSchools} setDisplayingOverlay={setDisplayingOL}></AckSchools>

        <AckOrgs display={displayAckOrgs} setDisplay={setDisplayAckOrgs} setDisplayingOverlay={setDisplayingOL}></AckOrgs>

        <OliveBranch display={displayOliveBranch} setDisplay={setDisplayOliveBranch} setDisplayingOverlay={setDisplayingOL}></OliveBranch>

        <Causes display={displayCauses} setDisplay={setDisplayCauses} setDisplayingOverlay={setDisplayingOL}></Causes>

        <Map sdk={sdk} display={displayMap} setDisplay={setDisplayMap} visitedHS={visitedHS} setDisplayingOverlay={setDisplayingOL}></Map>

        <ContactUs display={displayContactUs} setDisplay={setDisplayContactUs} setDisplayingOverlay={setDisplayingOL}></ContactUs>

      </div>

    </div>

    <IntroOption openCallback={()=>{overlayOpenCallback(0)}} closeCallback={()=>overlayCloseCallback(0)} sdk={sdk} setDisplayFourCards={setDisplayFourCards} setDisplayStoryCards={setDisplayStoryCards} display={displayIntroOption} setDisplay={setDisplayIntroOption}></IntroOption>

    <FourCards openCallback={()=>{overlayOpenCallback(1)}} closeCallback={()=>overlayCloseCallback(1)}  sdk={sdk}  setDisplayStoryCards={setDisplayStoryCards} display={displayFourCards} setDisplay={setDisplayFourCards}></FourCards>

    <StoryCards openCallback={()=>{overlayOpenCallback(2)}} closeCallback={()=>overlayCloseCallback(2)}  sdk={sdk} setDisplayFourCards={setDisplayFourCards} display={displayStoryCards} setDisplay={setDisplayStoryCards}></StoryCards>

    <Superpower openCallback={()=>{overlayOpenCallback(3)}} closeCallback={()=>overlayCloseCallback(3)}  sdk={sdk} display={displaySuperpower} setDisplay={setDisplaySuperpower} setDisplaySuperHero={setDisplaySuperHero} pauseBGM={setPauseBGM}></Superpower>

    <SuperHero openCallback={()=>{overlayOpenCallback(4)}} closeCallback={()=>overlayCloseCallback(4)} display={displaySuperHero} setDisplay={setDisplaySuperHero}></SuperHero>

    <Library openCallback={()=>{overlayOpenCallback(5)}} closeCallback={()=>overlayCloseCallback(5)}  display={displayLibrary} setDisplay={setDisplayLibrary}></Library>

    <CommunityServiceIntro openCallback={()=>{overlayOpenCallback(6)}} closeCallback={()=>overlayCloseCallback(6)} sdk={sdk} setDisplayWicare={setDisplayWicare}  display={displayCommunityServiceIntro} setDisplay={setDisplayCommunityServiceIntro}></CommunityServiceIntro>

    <Wicare openCallback={()=>{overlayOpenCallback(7)}} closeCallback={()=>overlayCloseCallback(7)}  display={displayWicare} setDisplay={setDisplayWicare}></Wicare>

    <ArtTherapy openCallback={()=>{overlayOpenCallback(8)}} closeCallback={()=>overlayCloseCallback(8)}  display={displayArtTherapy} setDisplay={setDisplayArtTherapy}></ArtTherapy>

    <CommunityServiceHelp openCallback={()=>{overlayOpenCallback(9)}} closeCallback={()=>overlayCloseCallback(9)}  display={displayCommunityServiceHelp} setDisplay={setDisplayCommunityServiceHelp}></CommunityServiceHelp>

    <EducationIntro openCallback={()=>{overlayOpenCallback(10)}} closeCallback={()=>overlayCloseCallback(10)} sdk={sdk} setDisplayMTEverest={setDisplayMTEverest}  display={displayEducationIntro} setDisplay={setDisplayEducationIntro} pauseBGM={setPauseBGM}></EducationIntro>

    <MTEverest openCallback={()=>{overlayOpenCallback(11)}} closeCallback={()=>overlayCloseCallback(11)}  display={displayMTEverest} setDisplay={setDisplayMTEverest}></MTEverest>

    <EducationValues openCallback={()=>{overlayOpenCallback(12)}} closeCallback={()=>overlayCloseCallback(12)}  display={displayEducationValues} setDisplay={setDisplayEducationValues}></EducationValues>

    <Caregiver openCallback={()=>{overlayOpenCallback(13)}} closeCallback={()=>overlayCloseCallback(13)}  display={displayCaregiver} setDisplay={setDisplayCaregiver}></Caregiver>

    <TCM  openCallback={()=>{overlayOpenCallback(14)}} closeCallback={()=>overlayCloseCallback(14)} display={displayTCM} setDisplay={setDisplayTCM}></TCM>

    <Dementia openCallback={()=>{overlayOpenCallback(15)}} closeCallback={()=>overlayCloseCallback(15)}  display={displayDementia} setDisplay={setDisplayDementia}></Dementia>

    <Ending display={displayEnding} setDisplay={setDisplayEnding}></Ending>

    <Tutorial display={displayTutorial} setDisplay={setDisplayTutorial} setViewed={setViewedTutorial}></Tutorial>

    <PopUp display={displayPopUp} setDisplay={setDisplayPopUp}/>
    </>
    )
}
