import React from 'react';
import {low_power_icon} from "../utils/images"

export default function SafariDesktop(props){
    const {
        showMessage,
        setShowMessage
    }=props

    var onClick=()=>{
        setShowMessage(false)
    }

    if(showMessage){
        return(
            <div className="low-power-message-bg h-100 container-fluid">
                <div className="row justify-content-center">
                    {/* <img className="landscape-message-logo" alt="logo" src={CKTL_logo_subheader}></img> */}
                    <img className="col-auto d-flex flex-row img" src={low_power_icon}/>
                    <p className="message col-auto d-flex flex-row">
                        Some images of the virtual tour might not load properly on Safari,<br/> for the best experience please use Google Chrome.
                    </p>

                    <button className="theme-btn col-2 close-btn" onClick={()=>onClick()}>Ok</button>
                </div>
            </div>
        )
    } else {
        return null;
    }
};