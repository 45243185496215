 //landing page

 export const CKTLintro_animation=require("../static/video/landing_page/CKTLintro_animation.mp4")

export const ME_idle_anim=require("../static/video/mt_everest/ME_idle_anim.gif")
export const ME_climbing_anim=require("../static/video/mt_everest/ME_climbing_anim.mp4")
//// mobile version of mt everest vid
export const ME_climbing_anim_mobile=require("../static/video/mt_everest/ME_mobile_anim.mp4")

export const values_anim=require("../static/video/education_values/values_animation.mp4")

//TCM

export const ear_anim=require("../static/video/TCM/ear_animation.gif")


//Author
export const interview=require("../static/video/author/interview.mp4")


//library
export const library_fly_through=require("../static/video/library/library.mp4")

//flythrough
export const flythrough=require("../static/video/flythrough/flythrough.mp4")