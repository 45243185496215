import React,{useState,useEffect,useRef} from "react";

import {dementia_card_front} from "../../../utils/images"

export default function MatchingCard(props){
    const {index,value,image,display,clickable,onSelect,active} = props;

   

    
    useEffect(() => {  

        
        
        return () => {
            
        };
    }, []);


    return (<>
    <div id={`card-${index}`} onClick={()=>{onSelect(index,value)}} className={`col-auto flip-card opacity-1  unclickable inactive`}>
        <div className="flip-card-inner">
            <div className="flip-card-front">
            
                <img src={dementia_card_front}  alt="photo"></img>
                
            </div>

            <div className="flip-card-back paragraph-format">
                <img alt="image" src={image}></img>
                    {/* <p style={{position:'absolute',top:'0',left:"0",fontSize:"32px"}}>{value}</p> */}
            </div>
        </div>
    </div>
    </>)
}