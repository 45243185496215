import React,{useState,useEffect,useRef} from "react";
import {btn_white_cross,char_kway_teow_1,panel_questionmark,tan_ireneTan} from "../../utils/images"
import {setStateWithDelay,navigateFunctionSet} from "../../utils/utility"
import {isMobileOnly, isMobile} from 'react-device-detect';

import UsefulIcons from "./useful_icons"

export default function IntroOption(props){
    const {display,setDisplay,sdk,setDisplayFourCards,setDisplayStoryCards,openCallback,closeCallback}=props;

    const [whoActive, setWhoActive] = useState(false);
    const [whatActive, setWhatActive] = useState(false);

    useEffect(() => {
        if(display){
            openCallback();
        }
        else{
            closeCallback();
        }
    }, [display]);

    var onClose=()=>{
        setDisplay(false);
        
    }

    var onSelect=(index)=>{
        if (index===0){
            navigateFunctionSet(sdk,"EUDkLgBzTcE")
            setStateWithDelay(setDisplayFourCards,true,1500)
        }
        else if (index===1){
            navigateFunctionSet(sdk,"5DNc56ffQYO")
            
            setStateWithDelay(setDisplayStoryCards,true,1500)
        }
        onClose();
    }

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone1_Intro.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone1_Intro.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    if (display===false ){
        return(<></>)
    }

    return (
            <div className="full-screen animated fadeIn">
                <div className="full-screen-black-bg__half position-absolute"></div>
                {/* check if it's a mobile devices */}
                {isMobileOnly ? (
                    <div className="container h-100 intro-option-container">
                        <div className="row h-100  align-items-center">
                        
                            <div onClick={()=>onSelect(0)} className={`col-4  offset-1 row justify-content-center who-wrapper-mobile`}>
                                <section className="picture-wrapper">
                                    <img className="main-img main-img__who" alt="main" src={tan_ireneTan}></img>
                                    <div className="gradient-layer-wrapper">
                                        <span className="text-wrapper">Who’s behind the <br/><i>Char Kway Teow Legacy?</i></span>
                                    </div>
                                </section>
                                <section className="question-wrapper">
                                    <span>Who</span>
                                </section>
                            </div>

                            <div onClick={()=>onSelect(1)} className={`col-4  offset-2 row justify-content-center what-wrapper-mobile`}>
                                <section className="picture-wrapper">
                                    <img className="main-img main-img__what" alt="main" src={char_kway_teow_1}></img>
                                    <div className="gradient-layer-wrapper">
                                        <span className="text-wrapper">What's the <br/><i>Char Kway Teow Legacy</i> all about?</span>
                                    </div>
                                </section>
                                <section className="question-wrapper">
                                    <span>What</span>
                                </section>
                            </div>
                        </div>
                    </div>
                ):(
                <div className="container h-100 intro-option-container">
                    <div className="row h-100  align-items-center">
                        <div onClick={()=>onSelect(0)} onMouseLeave={()=>{setWhoActive(false)}} onMouseEnter={()=>{setWhoActive(true)}} className={`${whoActive ? "active" : ""} col-4 offset-1 row justify-content-center who-wrapper`}>

                        <section className="picture-wrapper">
                            <img className="main-img main-img__who" alt="main" src={tan_ireneTan}></img>
                            <div className="questionmark-wrapper">
                                <img alt="questionmark" src={panel_questionmark}></img>
                            </div>

                            <div className="gradient-layer-wrapper">
                                <span className="text-wrapper">Who’s behind the <i>Char Kway Teow Legacy?</i></span>
                            </div>
                        </section>
                        <section className="question-wrapper">
                            <span>Who</span>
                        </section>
                        </div>

                        <div onClick={()=>onSelect(1)} onMouseLeave={()=>{setWhatActive(false)}} onMouseEnter={()=>{setWhatActive(true)}} className={`${whatActive ? "active" : ""} col-4  offset-2 row justify-content-center what-wrapper`}>

                        <section className="picture-wrapper">
                            <img className="main-img main-img__what" alt="main" src={char_kway_teow_1}></img>
                            <div className="questionmark-wrapper">
                                <img alt="questionmark" src={panel_questionmark}></img>
                            </div>

                            <div className="gradient-layer-wrapper">
                                <span className="text-wrapper">What's the <i>Char Kway Teow Legacy</i> all about?</span>
                            </div>
                        </section>
                        <section className="question-wrapper">
                            <span>What</span>
                        </section>
                        </div>
                    </div>
                </div>
                )}
            <div className="btn-top-right"  >
                <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
            </div>

            <UsefulIcons displayQues={true} displayShare={false} onClickRead={()=>handleReadMore()}></UsefulIcons>
        </div>)

}