import React,{useEffect} from "react";

export default function TCMButton(props){
    const {setActive,active,text,index}=props;

    var handleClick=()=>{
        setActive();
    }

    useEffect(() => {
        
        return () => {
            
        };
    }, [active]);

    return (<div onClick={()=>handleClick()} className={"col-4 option clickable mt-2 mt-xl-4"}>
        {/* <div className="indicator-wrapper">
            <div className={`indicator ${active===index ? "active" : ""}`}></div>
        </div> */}

    <div className={`option-btn ${active===index ? "active" : "inactive"}`}><span>{text}</span></div>
</div>)
}