import React,{useState,useEffect,useRef} from "react";
import {main_data} from "../store"

import {isMobileOnly,isMobile} from 'react-device-detect';

import {detectWheelDirectionReact} from "../../utils/utility"
import {btn_black_cross,btn_white_cross,misc_glow,ME_pic_1,ME_ending_pic, mount_everest_tap} from "../../utils/images"

import {ME_idle_anim, ME_climbing_anim, ME_climbing_anim_mobile} from "../../utils/videos"
import {ME_wind} from "../../utils/sounds"

import {Arwes,Project,Words,Frame,Image,Blockquote,Row,Col,Button} from "arwes"

import ScrollPrompt from "./overlay_content/scroll_prompt"
import ControlHint from "./overlay_content/control_hint"

import $ from 'jquery';

import ShareModal from "./share_modal"
import UsefulIcons from "./useful_icons"
import {ROOT_URL} from "../../config"
import BackMainButton from "./overlay_content/back_main_button"

var data=main_data.mt_everest

var scrollCounter=0;
var coolDownDuration=800;
var lastScroll=0;

var vid;

var videoStepMobile=[0.6,3.6,9.8,12,15.5]
var videoStep=[0,3.3,9.3,11.5,15]

var headerSize="h2"
var wordSize="font-18"

if (window.screen.width<1200){
    headerSize="h5"
    wordSize="font-11"
}

export default function MTEverest(props){
    const {display,setDisplay,openCallback,closeCallback,isStandalone}=props;

    const [state,setState]=useState(0)

    const [step, setStep] = useState(0);
    const [isPlaying,setIsPlaying]=useState(false)
    const stepRef=useRef({})

    const [showInfo,setShowInfo]=useState(0)

    const [displayShareModal, setDisplayShareModal] = useState(false);

    const [touchStartY,setTouchStartY]=useState(0);

    stepRef.current=step;

    const [autoPlayed, setAutoPlayed] = useState(false);
    const [showVideo,setShowVideo]=useState(false);
    const [displayShareButtons,setDisplayShareButtons]=useState(true);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>):(<BackMainButton display={true}/>);

    useEffect(() => {
        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
    }, [display]);

    var onClose=()=>{
        setDisplay(false);
        setState(0);
        setStep(0);
        setShowInfo(0)
        setIsPlaying(false);
        setShowVideo(false)


    }

    var restart=()=>{
        changeWindVolume(1);
        $("#audio")[0].pause();
        setState(0);
        setStep(0);
        setShowInfo(0)
        setIsPlaying(false);
        vid[0].currentTime=0;

    }

    var onWheel=(event)=>{

        if (new Date()-lastScroll>coolDownDuration && vid[0].paused)
        {
            scrollCounter+=1;
            var direction=detectWheelDirectionReact(event)

            if(scrollCounter>=3){

                var newStep=step;
                if (direction===0 && step<4){
                    newStep+=1;

                    vid[0].playbackRate=1;
                    setStep(newStep)
                    vid[0].play();
                    lastScroll=new Date()
                    setShowInfo(0)
                    setIsPlaying(true);

                }
                else if (direction===1 && stepRef.current>0){
                    // newStep-=1;
                    // vid[0].playbackRate=-1;
                    // setStep(newStep)
                    // vid[0].play();
                    // lastScroll=new Date()
                }
                // else if(direction===0 && stepRef.current===3){

                //     newStep+=1;
                //     setTimeout(()=>{
                //         setStep(newStep)
                //     })
                //     setStep(newStep);
                // }

            }

        }
    }

    var onTouchStart=(event)=>{
        if(event.touches[0].clientY){
            setTouchStartY(event.touches[0].clientY)
        }

    }

    var onTouchEnd=(event)=>{
        if(event.changedTouches[0].clientY){
            if(event.changedTouches[0].clientY-touchStartY>50 &&  vid[0].paused){
                var newStep=step;
                    if (step<4){
                        newStep+=1;
                        vid[0].playbackRate=1;
                        setStep(newStep)
                        vid[0].play();
                        lastScroll=new Date()
                        setShowInfo(0)
                        setIsPlaying(true);

                }
            }
        }

    }

    var onClickMobile=()=>{
        if(vid[0].paused){
            var newStep=step;
                if (step<4){
                    newStep+=1;
                    vid[0].playbackRate=1;
                    setStep(newStep)
                    vid[0].play();
                    lastScroll=new Date()
                    setShowInfo(0)
                    setIsPlaying(true);
                }
        }
    }

    var vidProgressCheck=(target)=>{
        if(!autoPlayed){
            target.pause();
            target.currentTime=0;
            setAutoPlayed(true)
            setState(0);
            setStep(0);
            setShowInfo(0)
        }
        else{
            if(!target.paused){

                if (target.playbackRate>0){
                    if (target.currentTime>=videoStep[step]){
                        setIsPlaying(false);
                        target.pause()
                        setShowInfo(step)

                    }
                }
                else{
                    if (target.currentTime<=videoStep[step]){

                        target.pause()
                        setIsPlaying(false);
                        setShowInfo(step)
                    }
                }


            }
        }

    }

    var vidProgressCheckMobile=(target)=>{
        if(!autoPlayed){
            target.pause();
            target.currentTime=0;
            setAutoPlayed(true)
            setState(0);
            setStep(0);
            setShowInfo(0)
        }
        else{
            if(!target.paused){

                if (target.playbackRate>0){
                    if (target.currentTime>=videoStepMobile[step]){
                        setIsPlaying(false);
                        target.pause()
                        setShowInfo(step)

                    }
                }
                else{
                    if (target.currentTime<=videoStepMobile[step]){

                        target.pause()
                        setIsPlaying(false);
                        setShowInfo(step)
                    }
                }


            }
        }

    }

    var changeWindVolume=(volume)=>{
        var audio=$("#audio")[0];
        if(audio){
            audio.volume=volume;
        }

    }

    function backFromVideo(){

        setShowVideo(false);
        changeWindVolume(1);


    }

    var start=()=>{
        setState(1)
        var audio=$("#audio")[0];
        audio.currentTime=0;
        audio.play();
    }

    useEffect(() => {

        if(display){
            vid=$('.climbing-vid');
        }


    }, [display]);

    useEffect(()=>{
        if (step > 0){
            setDisplayShareButtons(false);
        }

        if(step > 3){
            setDisplayShareButtons(true);
        }
    }, [step])

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone4_Education.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone4_Education.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    if (!display){
        return (<></>)
    }

    return (
        <div>
            {isMobileOnly ? (
                <div className="full-screen animated fadeIn ME-container-mobile" onClick={state>0?()=>{onClickMobile()}:null}>
                    <video onTimeUpdate={(event)=>{vidProgressCheckMobile(event.target)}} webkit-playsinline="true" playsInline={true} autoPlay muted={true}  className="position-absolute climbing-vid">
                        <source src={ME_climbing_anim_mobile}></source>
                        {/* ME_climbing_anim_mobile */}
                    </video>

                    <div className="scifi-wrapper">
                        <Arwes background='transparent' show={state===1 ? true : false} animate show puffsProps={{quantity:0}} pattern={misc_glow}>
                            <div className="challenge-wrapper">
                                <Project show={state===1} animate headerSize={headerSize} header='THE CHALLENGE'>
                                {anim => (
                                    <>
                                    <p style={{marginBottom:"10px"}}><Words animate show={anim.entered}>
                                    In order to climb to the summit of Mount Everest, it takes between 55 and 60 days to complete, including travel time to and from base camp.
                                    </Words></p>

                                    <ControlHint delay="0s" display={anim.entered} text={<Words animate show={anim.entered}>Tap the screen to climb up Mount Everest</Words>}  ></ControlHint>
                                    </>
                                )}
                                </Project>
                            </div>

                            <div className="altitude-wrapper">
                                <Frame animate show={showInfo===0} level={0} corners={3}>
                                    <div style={{display: showInfo!==0 ? "none" : "",margin:"20px",marginLeft:'0'}}>
                                        {/* <Blockquote  layer="secondary" animate >{`Altitude: ${data[showInfo].alt}`}</Blockquote> */}
                                        <Blockquote  layer="secondary" animate >{`Altitude: ${data[step].alt} above sea level`}</Blockquote>
                                    </div>
                                </Frame>
                            </div>

                            <div className="info-wrapper info-wrapper__0">
                                <Frame  animate show={showInfo===1} level={1} corners={3}>
                                    {anim => (
                                        <>
                                        <Row className="frame-wrapper">
                                            <Col m={7} className="col-wrapper" style={{height:"240px"}}>
                                                <Image className="img-wrapper" show={showInfo===1}  animate resources={data[1].img}/>
                                            </Col>
                                            <Col m={5} className="pr-0">
                                                <Row className="row-wrapper"><Words style={{borderBottom:"1px solid #F1B148", width:"312px", right:"7%", padding:"10px 0 5px 0"}} show={showInfo===1}  animate show={anim.entered}>{data[1].img_cap}</Words></Row>
                                                <Row className="row-wrapper">
                                                    <p>
                                                        <Words show={showInfo===1}  animate show={anim.entered}>
                                                            {data[1].img_des}
                                                        </Words>
                                                    </p>
                                                </Row>
                                                <Row className="row-wrapper" ><Words className="text" style={{borderTop:"1px solid #F1B148", width:"312px", right:"7%", padding:"5px 0 5px 0"}} show={showInfo===1} animate show={anim.entered}>{`Back`}</Words></Row>
                                            </Col>
                                        </Row>
                                        </>

                                    )}
                                </Frame>
                            </div>

                            <div className="info-wrapper info-wrapper__1">
                                <Frame  animate show={showInfo===2} level={0} corners={3}>
                                    {anim => (
                                        <>
                                        <Row className="frame-wrapper">
                                            <Col m={7} className="col-wrapper" style={{height:"240px"}}>
                                                <Image className="img-wrapper" show={showInfo===2}  animate resources={data[2].img}/>
                                            </Col>
                                            <Col m={5}>
                                                <Row className="row-wrapper"><Words style={{borderBottom:"1px solid #F1B148", width:"312px", right:"7%", padding:"10px 0 10px 0"}} show={showInfo===2}  animate show={anim.entered}>{data[2].img_cap}</Words></Row>
                                                <Row className="row-wrapper">
                                                    <p>
                                                        <Words show={showInfo===2}  animate show={anim.entered}>
                                                            {data[2].img_des}
                                                        </Words>
                                                    </p>
                                                </Row>
                                                <Row className="row-wrapper" ><Words className="text" show={showInfo===1} animate show={anim.entered}>{`Back`}</Words></Row>
                                            </Col>
                                        </Row>
                                        </>
                                    )}
                                </Frame>
                            </div>

                            <div className="info-wrapper info-wrapper__2">
                                <Frame  animate show={showInfo===3} level={0} corners={3}>
                                    {anim => (
                                        <>
                                        <Row className="frame-wrapper">
                                            <Col m={7} className="col-wrapper" style={{height:"240px"}}>
                                                <Image className="img-wrapper" show={showInfo===3}  animate resources={data[3].img}/>
                                            </Col>
                                            <Col m={5}>
                                                <Row className="row-wrapper"><Words style={{borderBottom:"1px solid #F1B148", width:"312px", right:"7%", padding:"10px 0 10px 0"}} show={showInfo===3}  animate show={anim.entered}>{data[3].img_cap}</Words></Row>
                                                <Row>
                                                    <p>
                                                        <Words show={showInfo===3}  animate show={anim.entered}>
                                                            {data[3].img_des}
                                                        </Words>
                                                    </p>
                                                </Row>
                                                <Row className="row-wrapper" ><Words className="text" show={showInfo===1} animate show={anim.entered}>{`Back`}</Words></Row>
                                            </Col>
                                        </Row>
                                        </>

                                    )}
                                </Frame>
                            </div>
                        </Arwes>
                    </div>

                    <div className="full-screen-black-bg__half position-absolute z-index-0 " style={{display: state===0 ? "" : "none" }}></div>

                    <div  className="z-index-2 container ME-intro-container h-100 " style={{display: state===0 ? "" : "none" }}>
                            <div className="row h-75" style={{marginTop: "9%"}}>
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="header overflow-hidden border-bottom-insert"><span >Mount Everest Expedition</span></div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <p className="paragraph">Good things happen when you dare to dream. But remarkable things happen when you have the courage to pursue your dream. 
                                            <br/><br/>In May 2017, Nur Yusrina Ya’akob, a teacher, specialising in Physical Education at the National Instution for Education (NIE) surmounted the odds to reach the summit of the highest peak on earth.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-auto">
                                            <button onClick={()=>  start()} className="theme-btn theme-hover">Let's Go</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 offset-lg-2 offset-md-1 offset-sm-0">
                                    <div className="row align-items-end justify-content-end h-100">
                                        <div className="col-12">

                                            <img alt="idle" className="w-100" src={ME_idle_anim} ></img>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="full-screen-black-bg__transparent animated fadeIn position-absolute" style={{opacity:'0.2',display:step===4&&showInfo===4 ? "" : "none"}}></div>
                        <div  className="z-index-2 container ME-ending-container animated fadeIn" style={{display: step===4 &&showInfo===4 ? "" : "none" }}>
                            <div style={{position:"relative",height:"100%",paddingTop:"1%",top:"50%",transform:"translateY(-50%)"}} className="row">
                                <div style={{display:step===4,pointerEvents:!showVideo?"":"none",height:"98%"}} className="col-12 justify-content-center align-items-center">
                                    <Frame className="h-100 children-inherit-height"  animate show={!showVideo} level={0} corners={3}>
                                        {anim => (<>
                                            <div style={{opacity:anim.entered&&!showVideo? "1" :"0",height:"100%",overflowY:"auto",position:"relative"}} className="container not-affected">
                                                <div className="row">
                                                    <div className="col-4 d-flex flex-column justify-content-center align-items-center" style={{padding:"0"}}>
                                                        <img src={ME_ending_pic} alt="ending"></img>
                                                    </div>

                                                    <div className= "left-wrapper offset-1 col-7 d-flex flex-column align-items-center">
                                                        <div className="header-row w-100">
                                                            <span className="pre-line"><Words animate show={anim.entered}>{`You have finally reached the summit! Hooray!`}</Words></span>
                                                        </div>
                                                        <div className="pre-line paragraph-row mt-2">
                                                            <span ><Words animate show={anim.entered}>{`It gives HH Tan immense pride and pleasure to be able to help fellow Singaporeans pursue their dreams. He believes that it is the greatest gift anyone could give.

                                                            In May 2017, Nur Yusrina Ya’akob, a teacher specialising in Physical Education at the National Institute of Education (NIE), along with Dr Arjunan Saravana Pillai and Jeremy Tong, surmounted the odds to reach the summit of the highest peak on earth.

                                                            Be brave. Pursue your dreams!

                                                            `}</Words>
                                                            </span>
                                                        </div>

                                                        <div className="btn-row w-100 mt-3">
                                                            <div onClick={()=>{setShowVideo(true);changeWindVolume(0)}} className="theme-btn theme-hover">Watch Video</div>
                                                            <div onClick={()=>{restart()}} className="ml-2 theme-btn theme-hover h-100"><span>Replay</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)}
                                    </Frame>
                                </div>
                                <div style={{position:"absolute",left:"0",top:"0",zIndex:"10",pointerEvents:showVideo?"":"none"}} className="w-100 h-100 d-flex justify-content-center align-items-center">
                                        <Frame  animate show={showVideo} level={0} corners={3}>
                                            {anim => (<div style={{opacity:anim.entered&&showVideo?"1" :"0"}} className="ending-video-wrapper ">
                                                <div  className="row ">
                                                    <div className="col-12">
                                                        <iframe  id="YTBPlayer"  src={showVideo?"https://www.youtube.com/embed/w5bNvKO5dgk":""} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 justify-content-center">
                                                    <div className="col-auto">
                                                        <button onClick={()=>{backFromVideo()}} className="theme-btn-auto theme-hover">Back</button>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </Frame>
                                    </div>
                            </div>

                        </div>

                    {topRightBtn}
                    <div className="animated fadeIn" style={{display: displayShareButtons? "":"none"}}>
                        <UsefulIcons displayQues={false} onClickShare={()=>setDisplayShareModal(true)} onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
                    </div>
                    <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`https://givingdangerously/virtual-tour/giving-legacy`} des={"Spread the word about TCM with your friends and family!"}></ShareModal>

                    <audio id="audio" controls={false} loop >
                        <source src={ME_wind}></source>
                    </audio>
                </div>
            ):(
                <div onTouchStart={state>0?(event)=>{onTouchStart(event)}:null} onTouchEnd={state>0?(event)=>{onTouchEnd(event)}:null} onWheel={state>0?(event)=>{onWheel(event)}:null} className="full-screen animated fadeIn ME-container">
                    <video onTimeUpdate={(event)=>{vidProgressCheck(event.target)}} autoPlay muted={true}  className="position-absolute climbing-vid">
                        <source src={ME_climbing_anim}></source>
                    </video>

                    <div className="scifi-wrapper">
                    <Arwes background='transparent' show={state===1 ? true : false} animate show puffsProps={{quantity:0}} pattern={misc_glow}>
                        <div className="challenge-wrapper">
                            <Project show={state===1} animate headerSize={headerSize} header='THE CHALLENGE'>
                            {anim => (
                                <>
                                <p style={{fontSize:"1rem",marginBottom:"10px"}}><Words animate show={anim.entered}>
                                It takes at least 2 months to climb Mount Everest – and that’s only if the weather conditions are favourable. And this excludes the 12 to 15 months of training before the climb.
                                </Words></p>

                                <ControlHint delay="0s" display={anim.entered} text={<Words animate show={anim.entered}>Scroll down to begin your journey</Words>}  ></ControlHint>
                                </>
                            )}
                            </Project>
                        </div>

                        <div className="altitude-wrapper">
                            <Frame animate show={showInfo!==0} level={0} corners={3}>

                                <div style={{display: showInfo!==0 ? "" : "none",margin:"20px",marginLeft:'0'}}>
                                    <Blockquote  layer="secondary" animate >{`Altitude: ${data[showInfo].alt}`}</Blockquote>
                                </div>
                            </Frame>
                        </div>

                        <div className="info-wrapper info-wrapper__0">
                            <Frame  animate show={showInfo===1} level={0} corners={3}>
                                {anim => (
                                    <>
                                    <div className="img-wrapper">
                                        <Image show={showInfo===1}  animate resources={data[1].img}>
                                            {data[1].img_cap}
                                        </Image>
                                    </div>
                                    <p  style={{fontSize:"1rem"}}><Words   show={showInfo===1}  animate show={anim.entered}>
                                        {data[1].img_des}
                                    </Words></p>
                                    </>

                                )}
                            </Frame>
                        </div>

                        <div className="info-wrapper info-wrapper__1">
                            <Frame  animate show={showInfo===2} level={0} corners={3}>
                                {anim => (
                                    <>
                                    <div className="img-wrapper">
                                        <Image show={showInfo===2} animate resources={data[2].img}>
                                            {data[2].img_cap}
                                        </Image>
                                    </div>
                                    <p style={{fontSize:"1rem"}}> <Words  show={showInfo===2} animate show={anim.entered}>
                                        {data[2].img_des}
                                    </Words></p>
                                    </>

                                )}
                            </Frame>
                        </div>

                        <div className="info-wrapper info-wrapper__2">
                            <Frame  animate show={showInfo===3} level={0} corners={3}>
                                {anim => (
                                    <>
                                    <div className="img-wrapper">
                                        <Image show={showInfo===3} animate resources={data[3].img}>
                                            {data[3].img_cap}
                                        </Image>
                                    </div>
                                    <p style={{fontSize:"1rem"}}><Words  show={showInfo===3} animate show={anim.entered}>
                                        {data[3].img_des}
                                    </Words></p>
                                    </>

                                )}
                            </Frame>
                        </div>

                    </Arwes>
                    </div>

                    <div className="full-screen-black-bg__half position-absolute z-index-0 " style={{display: state===0 ? "" : "none" }}></div>

                    <div  className="z-index-2 container ME-intro-container h-100 " style={{display: state===0 ? "" : "none" }}>
                            <div className="row h-25"></div>
                            <div className="row h-75">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="header overflow-hidden border-bottom-insert"><span >Mount Everest Expedition</span></div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <p className="paragraph">{`Good things happen when you dare to dream. But remarkable things happen when you have the courage to pursue your dream.

                                            In May 2017, Nur Yusrina Ya’akob, a teacher, specialising in Physical Education at the National Instution for Education (NIE) surmounted the odds to reach the summit of the highest peak on earth.`}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-auto">
                                            <button onClick={()=>  start()} className="theme-btn theme-hover">Let's Go</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 offset-lg-2 offset-md-1 offset-sm-0">
                                    <div className="row align-items-end justify-content-end h-100">
                                        <div className="col-12">

                                            <img alt="idle" className="w-100" src={ME_idle_anim} ></img>

                                        </div>
                                    </div>

                                </div>
                            </div>





                        </div>

                        <div className="full-screen-black-bg__transparent animated fadeIn position-absolute" style={{opacity:'0.2',display:step===4&&showInfo===4 ? "" : "none"}}></div>

                        <div  className="z-index-2 container ME-ending-container h-100 animated fadeIn" style={{display: step===4 &&showInfo===4 ? "" : "none" }}>
                            <div className="row">
                                <div style={{display:step===4,pointerEvents:!showVideo?"":"none"}} className="col-12 justify-content-center align-items-center">
                                    <Frame  animate show={!showVideo} level={0} corners={3}>
                                        {anim => (<>
                                            <div style={{opacity:anim.entered&&!showVideo? "1" :"0"}} className="container">
                                                <div className="row">
                                                    <div className="col-4 d-flex flex-column justify-content-center align-items-center" style={{padding:"0"}}>
                                                        <img src={ME_ending_pic} alt="ending"></img>
                                                    </div>

                                                    <div className= "left-wrapper offset-1 col-6 d-flex flex-column justify-content-center align-items-center">
                                                        <div className="header-row w-100">
                                                            <span className="pre-line"><Words animate show={anim.entered}>{`You have finally reached the summit!
                                                            Hooray!
                                                            `}</Words></span>
                                                        </div>
                                                        <div className="pre-line paragraph-row mt-2">
                                                            <span ><Words animate show={anim.entered}>{`It gives HH Tan immense pride and pleasure to be able to help fellow Singaporeans pursue their dreams. He believes that it is the greatest gift anyone could give.

                                                            In May 2017, Nur Yusrina Ya’akob, a teacher specialising in Physical Education at the National Institute of Education (NIE), along with Dr Arjunan Saravana Pillai and Jeremy Tong, surmounted the odds to reach the summit of the highest peak on earth.

                                                            Be brave. Pursue your dreams!

                                                            `}</Words>
                                                            </span>
                                                        </div>

                                                        <div className="btn-row w-100 mt-3">
                                                            <div onClick={()=>{setShowVideo(true);changeWindVolume(0)}} className="theme-btn theme-hover">Watch Video</div>
                                                            <div onClick={()=>{restart()}} className="ml-2 theme-btn theme-hover h-100"><span>Replay</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)}
                                    </Frame>
                                </div>
                                <div style={{position:"absolute",left:"0",top:"0",zIndex:"10",pointerEvents:showVideo?"":"none"}} className="w-100 h-100 d-flex justify-content-center align-items-center">
                                        <Frame  animate show={showVideo} level={0} corners={3}>
                                            {anim => (<div style={{opacity:anim.entered&&showVideo?"1" :"0"}} className="ending-video-wrapper ">
                                                <div  className="row ">
                                                    <div className="col-12">
                                                        <iframe  id="YTBPlayer"  src={showVideo?"https://www.youtube.com/embed/w5bNvKO5dgk":""} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 justify-content-center">
                                                    <div className="col-auto">
                                                        <button onClick={()=>{backFromVideo()}} className="theme-btn-auto theme-hover">Back</button>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </Frame>
                                    </div>
                            </div>

                        </div>
                    {topRightBtn}

                    <audio id="audio" controls={false} loop >
                        <source src={ME_wind}></source>
                    </audio>

                    <ScrollPrompt color="white" right="50px" top="45vh" display={step!==5 && state===1 && !isPlaying}></ScrollPrompt>
                    <UsefulIcons displayQues={false} displayShare={!displayShareModal} onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
                    <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/mount-everest`} des={"Share the exhibit with your friends!"}></ShareModal>
                </div>
            )}
        </div>
    )
}
