import React, { useRef } from "react";
import {btn_white_cross, mr_tan, sticker_pack, whatsapp_icon, telegram_icon, sticker_qr, video_qr} from "../../utils/images"
import ControlHint from "./overlay_content/control_hint"
// import PrizesCard from "./prizes_category";
import {main_data} from "../store"
import { isMobile, isMobileOnly } from "react-device-detect";

export default function Author(props){
    const {display,setDisplay,setDisplayingOverlay}=props;
    const myRef = useRef();

    var onClose=()=>{
        setDisplay(false);
        setDisplayingOverlay(false);
    }

    var scrollTop=()=>{
        myRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest'});
    }

    const tapMeHandler=()=>{
        //window.location.href='http://tcktl.sg/virtual-launch-2021';
        window.open('http://tcktl.sg/introduction-video', '_blank');
    }

    const tapMeStickerHandler=()=>{
        //window.location.href='https://www.givedangerously.today/tcktl-sticker-packs';
        window.open('https://www.givedangerously.today/tcktl-sticker-packs', '_blank');
    }

    if (display===false){
        return(<></>)
    }

    return (
    <div className="author-container animated fadeIn">
        <div className="container">
            <div ref={myRef} className="row w-100 justify-content-center">
                <div className="col-lg-6 col-md-8 col-10">
                    <div className="header border-bottom-insert text-center">
                        <span>Introduction</span>
                    </div>
                </div>
            </div>
            <div className="row w-100 justify-content-center mt-2">
                <div className="col-lg-6 col-md-8 col-10">
                    <div className="paragraph pre-line text-center">
                    Introduction of <i>The Char Kway Teow Legacy</i> by Mr Tan Hsuan Heng
                    </div>
                </div>
            </div>

            <div className="pd50 mr-tan-message col-lg-10 col-md-10 col-12">
                <div className="row">
                    <div className="col-7">
                        <h1 className="header">Hear from Mr HH Tan</h1>
                        {isMobileOnly? (
                            <ControlHint classes="refreshable" text={`Tap the QR code to hear from Mr HH Tan`} isAbsolute={false}></ControlHint>
                        ):(
                            <ControlHint classes="refreshable" text={`Scan the QR code to hear from Mr HH Tan`} isAbsolute={false}></ControlHint>
                        )}
                        <br/>
                        <div className="paragraph pre-line">
                            Ring ring !... Our Founder, Mr HH Tan, would like to welcome you to the virtual tour and share a personal story about the beginnings of our giving legacy. Hop on the video call now to say hi!
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="image-container">
                            <div className="mr-tan-img"><img src={mr_tan} alt="Mr Tan Hsuan Heng"/></div>
                            {isMobileOnly? (
                                <div className="sidebar-qrvideo-container" onClick={()=>{tapMeHandler()}}>
                                    <p>Tap to listen!</p>
                                    <img className="qr-code" src={video_qr}/>
                                </div>
                            ):(
                                <div className="sidebar-qrvideo-container">
                                    <p>Hover & <br/>Scan to listen!</p>
                                    <img className="qr-code" src={video_qr}/>
                                </div>
                            )}
                            <div className="paragraph pre-line">
                                <b>Mr Tan Hsuan Heng</b><br/>
                                Founder and Managing Director<br/>
                                Give Dangerously
                            </div>
                            <div className="photo-credit">
                                (Photo courtesy of Community Chest/ONEfor10)
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="introduction-video-container col-lg-12 col-md-12 col-12">
                <h1 className="header pd50">Introductory video</h1>
                <ControlHint classes="refreshable pd50" text={`Introductory video of The Char Kway Teow Legacy`} isAbsolute={false}></ControlHint>
                <br/>
                <div className="col-12 d-flex justify-content-center ytb-iframe-wrapper">
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/3ytff7eX1hw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>


            {/* <div className="join-giveaway col-lg-12 col-md-12 col-12">
                <h1 className="header pd50">Join the Giveaway!</h1>
                <ControlHint classes="refreshable pd50" text={`Click to see the prize details for each category!`} isAbsolute={false}></ControlHint>
                <br/>
                <div className="cards-container container-fluid">
                    <div className="row w-100 flex-nowrap cards-wrapper mt-3">
                        <PrizesCard data={data[0]} cardType={2}/>
                        <PrizesCard data={data[1]} cardType={1}/>
                        <PrizesCard data={data[2]} cardType={0}/>
                        <PrizesCard data={data[3]} cardType={1}/>
                    </div>
                </div>

                <div className="giveaway-details container-fluid">
                    <div className="row details-header">
                        <h1 className="header">How to Join the Giveaway (ENDS 6th September 2021, 2359 hours)</h1>
                    </div>
                    <div className="row details-body">
                        <table>
                            <tr>
                                <td className="table-data"><p className="header">1.</p></td>
                                <td className="table-data">
                                    <p className="header">👋 Visit our virtual tour</p>
                                    <p className="text">The tour features <span>17 unique interactives</span> where you can discover Give Dangerously's history, beneficiaries and how you can be a part of our giving community.</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="table-data"><p className="header">2.</p></td>
                                <td className="table-data">
                                    <p className="header">💬 Tell us which part of the tour is favourite and why.</p>
                                    <p className="text">Simply click the “Enter Giveaway” button below, tell us which part of the tour is your favourite and why, and stand a chance to win a prize from <span>ALL CATEGORIES!</span></p>
                                    <ul className="small-text">
                                        <li>Prizes will be awarded to best answers.</li>
                                        <li>Winners will be announced by 10 September 2021.</li>
                                        <li>An email will be sent to the winners by 10 September 2021.</li>
                                        <li><a href="https://www.givedangerously.today/tcktl-giveaway-terms-and-conditions/">Terms and Conditions</a> apply.</li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                        <div className="col-lg-12 col-md-12 col-12 giveaway-btn-container">
                            <button className="giveaway-btn btn m-auto">Our giveaway has ended! <br/>Thank you for participating!</button>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="sticker-pack col-lg-12 col-md-12 col-12">
                <div className="row">
                    <div className="header border-sticker">
                        <span>EXCLUSIVE!</span>
                    </div>
                </div>
                <div className="sticker-details row justify-content-center">
                    <div className="col-5">
                        <img className="sticker-gif" src={sticker_pack}/>
                    </div>
                    <div className="col-7">
                        <h1 class="header">TCKTL Sticker Pack</h1>
                        {isMobileOnly? (
                            <ControlHint classes="refreshable" text={`Tap the QR Code to get your free stickers!`} isAbsolute={false}></ControlHint>
                        ):(
                            <ControlHint classes="refreshable" text={`Scan the QR Code to get your free stickers!`} isAbsolute={false}></ControlHint>
                        )}
                        
                        <div className="row sticker-download">
                                {/* <img className="sticker-qr" src={sticker_qr} /> */}
                            {isMobile? (
                                <div className="col-4">
                                    <div className="sticker-qr-container-tap" onClick={()=>{tapMeStickerHandler()}}>
                                        <p>Tap me!</p>
                                        <img className="qr-code" src={sticker_qr}/>
                                    </div>
                                </div>
                            ):(
                                <div className="col-3">
                                    <div className="sticker-qr-container">
                                        <p>Hover & <br/>Scan me!</p>
                                        <img className="qr-code" src={sticker_qr}/>
                                    </div>
                                </div>
                            )}
                            <div className="col-6">
                                <div className="row">
                                    <div className="paragraph">Available now on:</div>
                                </div>
                                <div className="row">
                                    <img className="icon-app" src={telegram_icon} /> 
                                    <div className="paragraph app-name">Telegram</div>
                                </div>
                                <div className="row">
                                    <img className="icon-app" src={whatsapp_icon} /> 
                                    <div className="paragraph app-name">WhatsApp</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row btn-row">
                    <button onClick={()=>{scrollTop()}} className="back-top-button theme-btn-auto theme-hover">Back To Top</button>
                </div>
            </div>
        </div>
        <div className="btn-top-right"  >
            <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
        </div>
    </div>)
}