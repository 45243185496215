import React from "react";
import {btn_white_cross,} from "../../utils/images"
import {flythrough} from "../../utils/videos"

import {isMobileOnly} from 'react-device-detect';


export default function Flythrough(props){
    const {display,setDisplay,setDisplayingOverlay}=props;

    var onClose=()=>{
        setDisplay(false);
        setDisplayingOverlay(false);
    }

    if (display===false){
        return(<></>)
    }

    return (
        <div>
            {isMobileOnly? (
                <div className="flythrough-container-mobile animated fadeIn-C">

                    <div className="container">
                        <div className="row justify-content-center">
                        
                            <div className="col-10 col-lg-8 col-md-9 flythrough-wrapper">
                                <div className="header w-100 border-bottom-insert text-center">
                                    <span>Exhibition flythrough</span>
                                </div>
                            </div>
                        </div> 
                        
                
                        <div className="row justify-content-center mt-2">
                            <div className="col-12">
                                <div className='paragraph text-center'>Flythrough of <i>The Char Kway Teow Legacy</i> exhibition</div>
                            </div>
                        </div>
                
                        <div className="row justify-content-center mt-3">
                            <div className="col-12 d-flex justify-content-center">
                                <video className="flythrough-vid-mobile" controls={true}>
                                    <source src={flythrough}></source>
                                </video>
                            </div>
                        </div>
                    </div>
            
                    <div className="btn-top-right"  >
                        <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
                    </div>
                </div>
            ):(
                <div className="flythrough-container animated fadeIn">

                    <div className="container">
                    <div className="row justify-content-center">
                    
                        <div className="col-10 col-lg-8 col-md-9">
                            <div className="header w-100 border-bottom-insert text-center">
                                <span>Exhibition flythrough</span>
                            </div>
                        </div>
                    </div> 
                    

                    <div className="row justify-content-center mt-2">
                        <div className="col-12">
                            <div className='paragraph text-center'>Flythrough of <i>The Char Kway Teow Legacy</i> exhibition</div>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-3">
                        <div className="col-12 d-flex justify-content-center">
                            <video controls={true}>
                                <source src={flythrough}></source>
                            </video>
                        </div>
                    </div>
                    </div>

                    <div className="btn-top-right"  >
                        <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
                    </div>
                </div>
            )}
        </div>
    )
}