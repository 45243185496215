import React from "react";
import {isMobileOnly} from 'react-device-detect';
import {btn_white_cross,map,map_mobile,
    h1_intro,
    h2_who,
    h3_what,
    h4_legaled,
    h5_superhero,
    h6_lawlib,
    h7_comserv,
    h8_give,
    h9_arttherapy,
    h10_howtohelp,
    h11_edu,
    h12_mtev,
    h13_nie,
    h14_caregiver,
    h15_tcm,
    h16_dementia,

    h1_intro_bw,
    h2_who_bw,
    h3_what_bw,
    h4_legaled_bw,
    h5_superhero_bw,
    h6_lawlib_bw,
    h7_comserv_bw,
    h8_give_bw,
    h9_arttherapy_bw,
    h10_howtohelp_bw,
    h11_edu_bw,
    h12_mtev_bw,
    h13_nie_bw,
    h14_caregiver_bw,
    h15_tcm_bw,
    h16_dementia_bw} from "../../utils/images"
import Caregiver from "./caregiver";
import ControlHint from "./overlay_content/control_hint"

import {navigateFunctionSet} from "../../utils/utility"


const colored_icons=[h1_intro,h2_who,h3_what,h4_legaled,h5_superhero,h6_lawlib,h7_comserv,h8_give,h9_arttherapy,h10_howtohelp,h11_edu,h12_mtev,h13_nie,h14_caregiver,h15_tcm,h16_dementia];
const grey_icons=[h1_intro_bw,
    h2_who_bw,
    h3_what_bw,
    h4_legaled_bw,
    h5_superhero_bw,
    h6_lawlib_bw,
    h7_comserv_bw,
    h8_give_bw,
    h9_arttherapy_bw,
    h10_howtohelp_bw,
    h11_edu_bw,
    h12_mtev_bw,
    h13_nie_bw,
    h14_caregiver_bw,
    h15_tcm_bw,
    h16_dementia_bw];


export default function Map(props){
    const {sdk,display,setDisplay,visitedHS}=props;

    const mapText=(<span>There are a total of 5 different <span style={{fontStyle:"italic",color:"#F1B148"}}>zones</span> in the exhibition!</span>)

    function onHotspotClick(sid){
        onClose();
        navigateFunctionSet(sdk,sid)
    }

    var onClose=()=>{
        setDisplay(false);
    }

   

    return (
        <div className="map-component">
        {/* check if it is mobile only */}
        {isMobileOnly ? (
        <div className="map-container-mobile">
                <div className={`toggle-target container mr-0 ${!display ? "hidden" : ""}`}>
                    <div className="row">
                        <div className="col-1 d-flex align-items-start p-0">
                            <div onClick={()=>{setDisplay(!display)}} className={`${display?"active" : ""} toggle clickable  d-flex flex-column align-items-center justify-content-center w-100`}>
                                <div className="text-center pre-line">{`View Map`}</div>
                                <i className="mt-2 far fa-map fa-2x"></i>
                            </div>
                            
                        </div>
                        <div className={`m-0 p-0 col-11`}>
                            <div className="body container pt-3 pb-3">
                                <ControlHint className="control-hint-map-mobile" text={mapText}></ControlHint>

                                <div className="map-wrapper mt-4">
                                    <img className="w-100" alt="map" src={map_mobile}></img>

                                    <div onClick={()=>onHotspotClick("SZFfAHsz8Jk")} className="hotspot-wrapper hotspot-wrapper__main intro">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[0]?grey_icons[0]:colored_icons[0]}></img>

                                        <div className="text-wrapper">
                                            <span>ZONE 1</span>
                                            <span style={{color:"#F1B148"}}>INTRODUCTION</span>
                                        </div>
                                    </div>

                                    <div onClick={()=>onHotspotClick("EUDkLgBzTcE")} className="hotspot-wrapper who">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[1]?grey_icons[1]:colored_icons[1]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("5DNc56ffQYO")} className="hotspot-wrapper what">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[2]?grey_icons[2]:colored_icons[2]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("Op4wbUvj5tO")} className="hotspot-wrapper hotspot-wrapper__main legal-ed">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[3]?grey_icons[3]:colored_icons[3]}></img>

                                        <div className="text-wrapper">
                                            <span>ZONE 2</span>
                                            <span style={{color:"#92CBF7"}}>Legal Education</span>
                                        </div>
                                    </div>

                                    <div onClick={()=>onHotspotClick("cBBdVrK5Xxz")} className="hotspot-wrapper  superhero">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[4]?grey_icons[4]:colored_icons[4]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("uIXSFfeSOtq")} className="hotspot-wrapper  library">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[5]?grey_icons[5]:colored_icons[5]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("OGXuUhj2efU")} className="hotspot-wrapper hotspot-wrapper__main cmsv">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[6]?grey_icons[6]:colored_icons[6]}></img>

                                        <div className="text-wrapper">
                                            <span>ZONE 3</span>
                                            <span style={{color:"#FFBD90"}}>Community Service</span>
                                        </div>
                                    </div>

                                    <div onClick={()=>onHotspotClick("c4ZQGaWlcAH")} className="hotspot-wrapper  help">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[7]?grey_icons[7]:colored_icons[7]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("TbQ8l8Y9nFk")} className="hotspot-wrapper  art">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[8]?grey_icons[8]:colored_icons[8]}></img>
                                    </div>

                                    

                                    <div onClick={()=>onHotspotClick("suYGrnIkVGB")} className="hotspot-wrapper  wicare">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[9]?grey_icons[9]:colored_icons[9]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("81mkvNin8Wz")} className="hotspot-wrapper hotspot-wrapper__main ed">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[10]?grey_icons[10]:colored_icons[10]}></img>

                                        <div className="text-wrapper">
                                            <span>ZONE 4</span>
                                            <span style={{color:"#6BC8BE"}}>Education</span>
                                        </div>
                                    </div>

                                    <div onClick={()=>onHotspotClick("axS9GKxksFj")} className="hotspot-wrapper  me">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[11]?grey_icons[11]:colored_icons[11]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("W52xdNeWZuG")} className="hotspot-wrapper  ed-value">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[12]?grey_icons[12]:colored_icons[12]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("DtheO2EocrQ")} className="hotspot-wrapper hotspot-wrapper__main caregiver">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[13]?grey_icons[13]:colored_icons[13]}></img>

                                        <div className="text-wrapper">
                                            <span>ZONE 5</span>
                                            <span style={{color:"#DFE571"}}>Healthcare</span>
                                        </div>
                                    </div>

                                    <div onClick={()=>onHotspotClick("wETbaCTwLuZ")} className="hotspot-wrapper  TCM">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[14]?grey_icons[14]:colored_icons[14]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("fPFASlHNpGQ")} className="hotspot-wrapper  dementia">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[15]?grey_icons[15]:colored_icons[15]}></img>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div> 
            ) : (
            <div className="map-container">
                <div className={`toggle-target container mr-0 ${!display ? "hidden" : ""}`}>
                    <div className="row">
                        <div className="col-1 d-flex align-items-start p-0">
                            <div onClick={()=>{setDisplay(!display)}} className={`${display?"active" : ""} toggle clickable  d-flex flex-column align-items-center justify-content-center w-100`}>
                                <div className="text-center pre-line">{`View Map`}</div>
                                <i className="mt-2 far fa-map fa-2x"></i>
                            </div>
                            
                        </div>
                        <div className={`m-0 p-0 col-11 `}>
                            <div className="body container pt-5 pb-5">
                                <ControlHint text={mapText}></ControlHint>

                                <div className="map-wrapper mt-4">
                                    <img className="w-100" alt="map" src={map}></img>

                                    <div onClick={()=>onHotspotClick("SZFfAHsz8Jk")} className="hotspot-wrapper hotspot-wrapper__main intro">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[0]?grey_icons[0]:colored_icons[0]}></img>

                                        <div className="text-wrapper">
                                            <span>ZONE 1</span>
                                            <span style={{color:"#F1B148"}}>INTRODUCTION</span>
                                        </div>
                                    </div>

                                    <div onClick={()=>onHotspotClick("EUDkLgBzTcE")} className="hotspot-wrapper who">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[1]?grey_icons[1]:colored_icons[1]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("5DNc56ffQYO")} className="hotspot-wrapper what">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[2]?grey_icons[2]:colored_icons[2]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("Op4wbUvj5tO")} className="hotspot-wrapper hotspot-wrapper__main legal-ed">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[3]?grey_icons[3]:colored_icons[3]}></img>

                                        <div className="text-wrapper">
                                            <span>ZONE 2</span>
                                            <span style={{color:"#92CBF7"}}>Legal Education</span>
                                        </div>
                                    </div>

                                    <div onClick={()=>onHotspotClick("cBBdVrK5Xxz")} className="hotspot-wrapper  superhero">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[4]?grey_icons[4]:colored_icons[4]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("uIXSFfeSOtq")} className="hotspot-wrapper  library">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[5]?grey_icons[5]:colored_icons[5]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("OGXuUhj2efU")} className="hotspot-wrapper hotspot-wrapper__main cmsv">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[6]?grey_icons[6]:colored_icons[6]}></img>

                                        <div className="text-wrapper">
                                            <span>ZONE 3</span>
                                            <span style={{color:"#FFBD90"}}>Community Service</span>
                                        </div>
                                    </div>

                                    <div onClick={()=>onHotspotClick("c4ZQGaWlcAH")} className="hotspot-wrapper  help">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[7]?grey_icons[7]:colored_icons[7]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("TbQ8l8Y9nFk")} className="hotspot-wrapper  art">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[8]?grey_icons[8]:colored_icons[8]}></img>
                                    </div>

                                    

                                    <div onClick={()=>onHotspotClick("suYGrnIkVGB")} className="hotspot-wrapper  wicare">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[9]?grey_icons[9]:colored_icons[9]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("81mkvNin8Wz")} className="hotspot-wrapper hotspot-wrapper__main ed">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[10]?grey_icons[10]:colored_icons[10]}></img>

                                        <div className="text-wrapper">
                                            <span>ZONE 4</span>
                                            <span style={{color:"#6BC8BE"}}>Education</span>
                                        </div>
                                    </div>

                                    <div onClick={()=>onHotspotClick("axS9GKxksFj")} className="hotspot-wrapper  me">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[11]?grey_icons[11]:colored_icons[11]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("W52xdNeWZuG")} className="hotspot-wrapper  ed-value">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[12]?grey_icons[12]:colored_icons[12]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("DtheO2EocrQ")} className="hotspot-wrapper hotspot-wrapper__main caregiver">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[13]?grey_icons[13]:colored_icons[13]}></img>

                                        <div className="text-wrapper">
                                            <span>ZONE 5</span>
                                            <span style={{color:"#DFE571"}}>Healthcare</span>
                                        </div>
                                    </div>

                                    <div onClick={()=>onHotspotClick("wETbaCTwLuZ")} className="hotspot-wrapper  TCM">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[14]?grey_icons[14]:colored_icons[14]}></img>
                                    </div>

                                    <div onClick={()=>onHotspotClick("fPFASlHNpGQ")} className="hotspot-wrapper  dementia">
                                        <img className="hotspot" alt="hotspot" src={visitedHS[15]?grey_icons[15]:colored_icons[15]}></img>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
            )}  {/** end of isMobileOnly*/}
        </div>
    )
}