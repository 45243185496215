import React from "react";
import {btn_white_cross,causes_pie_chart} from "../../utils/images"

import ControlHint from "./overlay_content/control_hint"

import {isMobileOnly} from 'react-device-detect';


export default function OliveBranch(props){
    const {display,setDisplay,setDisplayingOverlay}=props;


    var onClose=()=>{
        setDisplay(false);
        setDisplayingOverlay(false);
    }

    if (display===false){
        return(<></>)
    }

    
    return (
        <div>
            {isMobileOnly? (
                <div className="causes-container animated fadeIn-A">

        <div className="container ">
            <div className="row">
                <div className="col-5 d-flex flex-column justify-content-center">
                    <div className="row">
                        <div className="col-12">
                            <div className="header border-bottom-insert">
                                <span>Causes supported by the legacy</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="paragraph pre-line">
                                {`Some of the causes supported by the Estates of CJ Koh, Ong Tiong Tat and Irene Tan Liang Kheng includes Legal Education, Education, Community Services and Healthcare.`}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <ControlHint text="How has the support benefited the causes? Find out in the exhibition"></ControlHint>
                        </div>
                    </div>
                    
                </div>
                <div className="col-6 ml-3 d-flex flex-column justify-content-center">
                    <img alt="olive branch" src={causes_pie_chart} className="w-100"></img>
                </div>
            </div>
        </div>
        <div className="btn-top-right"  >
            <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
        </div>
    </div>
            ):(<div className="causes-container animated fadeIn">

            <div className="container ">
                <div className="row">
                    <div className="col-5 d-flex flex-column justify-content-center">
                        <div className="row">
                            <div className="col-12">
                                <div className="header border-bottom-insert">
                                    <span>Causes supported by the legacy</span>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="paragraph pre-line">
                                    {`Some of the causes supported by the Estates of CJ Koh, Ong Tiong Tat and Irene Tan Liang Kheng includes Legal Education, Education, Community Services and Healthcare.`}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <ControlHint text="How has the support benefited the causes? Find out in the exhibition"></ControlHint>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-6 ml-3 d-flex flex-column justify-content-center">
                        <img alt="olive branch" src={causes_pie_chart} className="w-100"></img>
                    </div>
                </div>
            </div>
            <div className="btn-top-right"  >
                <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
            </div>
        </div>


            )
            
            
            
            
            
            }
        </div>
    
    
    
    )

}