import React,{useState,useEffect} from "react";
import {isMobileOnly, isMacOs, isSafari} from 'react-device-detect';
import {Link} from "react-router-dom";
import {CKTLintro_animation,interview} from "../utils/videos"

import {CKTL_logo_subheader} from "../utils/images"

import LowPowerMessage from "../app/low_power_message"
import SafariDesktop from "../app/safari_desktop_message"

import $ from "jquery"

export default function Home(){

    const [videoOpacity,setVideoOpacity]=useState(0);
    const [buttonOpacity,setButtonOpacity]=useState(0);


    const [displayVideo,setDisplayVideo]=useState(false);
    const [showVideoMessage, setShowVideoMessage]=useState(false);
    const [showSafariMessage, setShowSafariMessage]=useState(false);
    const [playVideo, setPlayVideo]=useState(false);


    var showVideo=()=>{
      setDisplayVideo(true)

      setTimeout(()=>{
        setVideoOpacity(1)
      },1000)

      setTimeout(()=>{
        setButtonOpacity(1)
      },3000)
    }

    useEffect(() => {
        $('.vid')[0].playbackRate=0.7;
        const videoElement = document.getElementById('home_vid');
        videoElement.addEventListener('suspend', ()=>{
          setTimeout(setShowVideoMessage(true), 1000)
        })
        videoElement.addEventListener('play', ()=>{
          setShowVideoMessage(false);
        })

        if(isSafari && isMacOs){
          setShowSafariMessage(true);
        }

        return () => {
            // setDisplayVideo(false);
            // setVideoOpacity(0);
            // $('.vid')[0].currentTime=0;
        };
    }, []);

    return (<>
    <div className="landing-bg position-absolute z-index-0"></div>
      <div className="landing-wrapper">
        <video  webkit-playsinline="true" playsInline={true} autoPlay muted onEnded={()=>showVideo()} className="vid" id="home_vid">
          <source src={CKTLintro_animation} ></source>
        </video>
      </div>

      {/* check if it is a mobile device */}
      <LowPowerMessage showMessage={showVideoMessage} setShowMessage={setShowVideoMessage}/>
      {isMobileOnly ? (
        <div className="container intro-video-container-mobile" style={{opacity:videoOpacity,display: displayVideo ? "flex" : "none"}}>  
          <div className="row justify-content-center">
            <div className="col-auto">
            <a target="_blank" rel="noopener noreferrer" href="https://www.givedangerously.today"><img className="logo" alt="logo" src={CKTL_logo_subheader}></img></a>

            </div>
          </div>

          <div className="row justify-content-center w-100  mt-2">
            <div className="col-12 ytb-iframe-wrapper">
              {/* <video controls>
                <source src={interview}></source>
              </video> */}
              <iframe width="100%" height="100%"  src="https://www.youtube.com/embed/3ytff7eX1hw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>

            <div className="row justify-content-center mt-3">
              <div className="col">
                <div style={{opacity:buttonOpacity}} className="landing-button">
                <Link to="/tour">
                  <button className="btn-orange btn m-auto">Begin Exhibition</button>
                </Link>
              </div>
              </div>
            </div>
          </div>
        </div>
        ) : (
          <div className="container intro-video-container " style={{opacity:videoOpacity,display: displayVideo ? "flex" : "none"}}>
            <SafariDesktop showMessage={showSafariMessage} setShowMessage={setShowSafariMessage}/>
            <div className="row justify-content-center">
              <div className="col-auto">
                <a target="_blank" rel="noopener noreferrer"  href="https://www.givedangerously.today"><img className="logo" alt="logo" src={CKTL_logo_subheader}></img></a>
              </div>
            </div>

            <div className="row justify-content-center w-100  mt-2">
              <div className="col-9 ytb-iframe-wrapper">
                {/* <video controls>
                  <source src={interview}></source>
                </video> */}
                <iframe width="100%" height="100%"  src="https://www.youtube.com/embed/3ytff7eX1hw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

            <div className="row justify-content-center mt-3">
              <div className="col">
                <div style={{opacity:buttonOpacity}} className="landing-button">
                <Link to="/tour">
                  <button className="btn-orange btn m-auto">Begin Exhibition</button>
                </Link>
              </div>
              </div>
            </div>
          </div>
        )}
      </>)
}
