import React,{useState,useEffect,useRef} from "react";
import {main_data} from "../store"
import {btn_white_cross,btn_black_cross} from "../../utils/images"
import {Circle} from '../../utils/utility'
import Slider,{createSliderWithTooltip} from 'rc-slider';
import ScrollPrompt from "./overlay_content/scroll_prompt"
import ControlHint from "./overlay_content/control_hint"
import $ from 'jquery';
import { isMobileOnly, isMobile } from "react-device-detect";

import UsefulIcons from "./useful_icons"
import ShareModal from "./share_modal"
import {ROOT_URL} from "../../config"
import BackMainButton from "./overlay_content/back_main_button"

var canvas;
var canvasWrapper;
var c;
var circleArray=[];

var mouse={
    x:undefined,
    y:undefined
};

var scrollCounter=0;
var coolDownDuration=800;
var lastScroll=0;

var animRequest=undefined;

const data=main_data.community_service_intro;

const position={
    0:{
        yellow:[40,65,25],
        blue:[70,35,25],
        
    },

    1:{
        yellow:[72,72,10],
        blue:[40,40,39]
    },
    2:{
        yellow:[25,25,15],
        blue:[55,55,37]
    },
    3:{
        yellow:[15,80,10],
        blue:[50,50,40]
    }

}

const text={
    blue:{
        0:"",
        1:"80%",
        2:"80%",
        3:""
    },
    yellow:{
        0:"",
        1:"20%",
        2:"20%",
        3:""
    },
}


var setNewPositions=(index)=>{
    
    circleArray[0].setNewPosition(position[index].yellow[0],position[index].yellow[1],position[index].yellow[2],text.yellow[index]);
    circleArray[1].setNewPosition(position[index].blue[0],position[index].blue[1],position[index].blue[2],text.blue[index]);
    
}

const SliderWithTooltip = createSliderWithTooltip(Slider);

const markStyle={color:"white"}

export default function Wicare(props){
    const {display,setDisplay,openCallback,closeCallback,isStandalone}=props;
    const [displayShareModal, setDisplayShareModal] = useState(false);

    const [state,setState]=useState(1)
    const stateRef=useRef({})
    const [sliderValue,setSliderValue]=useState(50)
    const [sliderDisabled,setSliderDisabled]=useState(false)

    const [showWIS,setShowWIS]=useState(false);
    const showWISRef=useRef({})
    showWISRef.current=showWIS
    const [showWIB,setShowWIB]=useState(false);
    const showWIBRef=useRef({})
    showWIBRef.current=showWIB
    const [isClickable, setIsClickable]=useState(false);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>):(<BackMainButton display={true}/>);
    
    
    stateRef.current=state

    useEffect(() => {
        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
    }, [display]);

    var onClose=()=>{
        setState(0);
        setSliderValue(50);
        setShowWIS(false);
        setShowWIB(false);
        setSliderDisabled(false);
        setDisplay(false);
        canvas=undefined;
        cancelAnimationFrame(animRequest)
        animRequest=undefined;
        circleArray=[];
       

    }

    var handleCanvasMouseMove=(event)=>{
        mouse.x=event.x;
        mouse.y=event.y;
    }

    var initializeCanvasSize=()=>{
        canvas[0].width=canvasWrapper[0].getBoundingClientRect().width;
        canvas[0].height=canvas[0].width;
    }

    

    function animate(){
        
        animRequest=requestAnimationFrame(animate);
        c.clearRect(0,0,canvas[0].width,canvas[0].height);
        for (var i =0; i < circleArray.length;i++){
            
            circleArray[i].update(mouse,stateRef.current===2);
        }
    
    }

    var sliderOnChange=(value)=>{
        setSliderValue(value)
        circleArray[0].setNewSizeByRatio(value,0)
        circleArray[1].setNewSizeByRatio(100-value,1)
        
        
    }

    function handleContinue(){
        setState(2)
        circleArray[0].setNewSizeByRatio(20,0)
        circleArray[1].setNewSizeByRatio(80,1)
    }

    function handleContinueMobile(){
        setState(2)
        circleArray[0].setNewSizeByRatio(20,0)
        circleArray[1].setNewSizeByRatio(80,1)
        setIsClickable(true)
    }


    

    var initializeCircles=()=>{
        if (circleArray.length==0)
        {
            var yelloMainCircle=new Circle(40,65,25,'rgba(255, 189, 82, 0.9)',canvas[0],c)
            yelloMainCircle.setHoverCallback(()=>toggleState(setShowWIS,showWISRef.current))
            circleArray.push(yelloMainCircle)
            var blueMainCircle=new Circle(70,35,25,'rgba(68, 90, 160, 0.9)',canvas[0],c)
            blueMainCircle.setHoverCallback(()=>toggleState(setShowWIB,showWIBRef.current))
            circleArray.push(blueMainCircle)
        }
    }

    function toggleState(setState,state){
        setState(!state)
    }
    
    useEffect(() => {
        if (display && canvas===undefined){
        canvas=$('#canvas');
        canvasWrapper=$('.canvas-wrapper');
        c=canvas[0].getContext('2d');
        

        initializeCanvasSize();
        
        window.addEventListener('mousemove',(event)=>handleCanvasMouseMove(event))
        
        // if(isMobileOnly){
        //     initializeCirclesMobile();
        // } else {
        //     initializeCircles();
        // }
        initializeCircles();
        animate();
    }
       
        return () => {
            window.removeEventListener('resize',initializeCanvasSize);
            window.removeEventListener('mousemove',handleCanvasMouseMove);
        };
    }, [display]);

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone_3.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone3_ComServ.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    if (display===false ){
        return(<></>)
    }

    return (
        <div className="full-screen animated fadeIn">
            <div className="full-screen-black-bg__half position-absolute z-index-0 ">

            
            </div>
            <div className="z-index-2 container wicare-container h-100">
                
            {isMobileOnly ? (
                <div className="row h-100 mobile-wicare">
                    <div  className="col-7 left d-flex flex-column justify-content-center">
                        
                        <div style={{display:state===0 ? "" : "none"}} className="container p-0 state__0 animated fadeIn">
                            <div className="row mt-4">
                                <div className="col-12 ">
                                    <h1 className="header overflow-hidden"><span className="border-bottom-insert">Community Services</span></h1>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <p className="paragraph">Legal education was something that CJ held close to his heart and he hopes to improve access legal knowledge and advance legal research.<br /><br />
                                    
                                    Did you know that <span style={{color:"#FFD171"}}>smaller charities</span> do not always get the same amount of  as <span style={{color:"#7293FF"}}>bigger charities?</span>
                                    </p>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-auto">
                                    <div onClick={()=>{setState(1)}} className="theme-btn theme-hover">
                                        Let's Go
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display:state===1 ? "" : "none",overflow:"visible"}} className="container p-0 state__1 animated fadeIn">
                            <div className="row">
                                <div className="col-12 ">
                                    <div className="header overflow-hidden">
                                    <span className="border-bottom-insert pre-line" style={{fontSize: "1.8rem"}}>If you had $10 to donate,<br/> how would you divide the amount between <span style={{color:"#F1B148"}}>small</span> and <span style={{color:"#7596FF"}}>large</span> charities?</span>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12 ">

                                    {/* <div className="paragraph overflow-hidden border-left-insert">
                                        <span className=" d-flex align-items-center">
                                            <i className='fas fa-info-circle' style={{fontSize:'24px'}}></i> &nbsp; {`Drag the slider to input your answer.`}
                                        </span>
                                    </div> */}
                                    <ControlHint text="Drag the slider to input your answer"></ControlHint>                               
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <SliderWithTooltip tipProps={{
                                        placement:"bottom",
                                        visible:true,
                                        overlay:`$${sliderValue/10}/$${10-sliderValue/10}`
                                        
                                    }} 
                                    marks={{0:{style:markStyle,label:"Small"},100:{style:markStyle,label:"Big"}}} railStyle={{backgroundColor:"#445AA0",height:'6px'}} trackStyle={{backgroundColor:"#F1B148",height:'6px'}}
                                    dotStyle={{top:"-1px"}} 
                                    value={sliderValue}
                                    onChange={(value)=>sliderOnChange(value)}
                                    disabled={sliderDisabled}
                                    dots={true}
                                    activeDotStyle={{borderColor:"#e9e9e9"}}
                                    step={10}
                                    
                                    />
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-auto">
                                    {isMobileOnly? (
                                        <div onClick={()=>handleContinueMobile()} className="theme-hover theme-btn">Continue</div>
                                    ):(
                                        <div onClick={()=>handleContinue()} className="theme-hover theme-btn">Continue</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{display:state===2 ? "" : "none"}} className="container p-0 state__2 animated fadeIn">
                            <div className="row">
                                <div className="col-12 paragraph" style={{fontSize:"1.1rem"}}>
                                    In a survey of respondents between ages 18 and 25,
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">

                                    <div className="header overflow-hidden">
                                        <span className="border-bottom-insert">
                                        82% said they would donate a higher amount to the <span style={{color:"#7596FF"}}>larger</span> charities.
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                                
                            <div className="row">
                                <div className='col-12'>
                                    <ControlHint classes="mt-2" text={"Tap on the circles find out more about these charities!"}></ControlHint>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12 paragraph">
                                It’s easy for donors to overlook smaller charities, especially if the charities support causes that not many people can relate to. Find out more about the work of smaller charities such as Wicare Support Group. 
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-auto">
                                    <button onClick={()=>{onClose()}} className="theme-btn theme-hover">Done</button>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>

                    <div className="col-5 d-flex flex-column justify-content-center">
                        
                        <div className="row canvas-wrapper ">
                            <canvas className="col-auto" id="canvas">
                            </canvas>


                            <div className={`sm-charity-box container animated fadeIn ${showWIS ? "animated fadeIn" : "animated fadeOut"}`}>
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="header w-100 border-bottom-half-white">What is a <span style={{color:"#F1B148"}}>small</span> charity? </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="paragraph">A small charity refers to one with gross annual receipts of less than $500,000.</span>
                                    </div>
                                </div>
                            </div>

                            <div className={`bg-charity-box container animated fadeIn ${showWIB ? "animated fadeIn" : "animated fadeOut"}`}>
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="header w-100 border-bottom-half-white">What is a <span style={{color:"#7596FF"}}>large</span> charity? </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="paragraph">A large charity or Institution of a Public Character (IPC) refers to one with gross annual receipts of not less than $10 million in each of the last 2 financial years.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    
                        
                    </div>
            ):(
                <div className="row h-100">
                    <div  className="col-5 left d-flex flex-column justify-content-center">
                        
                        <div style={{display:state===0 ? "" : "none"}} className="container p-0 state__0 animated fadeIn">
                            <div className="row mt-4">
                                <div className="col-12 ">
                                    <h1 className="header overflow-hidden"><span className="border-bottom-insert">Community Services</span></h1>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <p className="paragraph">Legal education was something that CJ held close to his heart and he hopes to improve access legal knowledge and advance legal research.<br /><br />
                                    
                                    Did you know that <span style={{color:"#FFD171"}}>smaller charities</span> do not always get the same amount of  as <span style={{color:"#7293FF"}}>bigger charities?</span>
                                    </p>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-auto">
                                    <div onClick={()=>{setState(1)}} className="theme-btn theme-hover">
                                        Let's Go
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display:state===1 ? "" : "none",overflow:"visible"}} className="container p-0 state__1 animated fadeIn">
                            <div className="row">
                                <div className="col-12 ">
                                    <div className="header overflow-hidden">
                                    <span className="border-bottom-insert pre-line">If you had $10 to donate,<br/> how would you divide the amount between <span style={{color:"#F1B148"}}>small</span> and <span style={{color:"#7596FF"}}>large</span> charities?</span>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12 ">

                                    {/* <div className="paragraph overflow-hidden border-left-insert">
                                        <span className=" d-flex align-items-center">
                                            <i className='fas fa-info-circle' style={{fontSize:'24px'}}></i> &nbsp; {`Drag the slider to input your answer.`}
                                        </span>
                                    </div> */}
                                    <ControlHint text="Drag the slider to input your answer"></ControlHint>                               
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <SliderWithTooltip tipProps={{
                                        placement:"bottom",
                                        visible:true,
                                        overlay:`$${sliderValue/10}/$${10-sliderValue/10}`
                                        
                                    }} 
                                    marks={{0:{style:markStyle,label:"Small"},100:{style:markStyle,label:"Big"}}} railStyle={{backgroundColor:"#445AA0",height:'6px'}} trackStyle={{backgroundColor:"#F1B148",height:'6px'}}
                                    dotStyle={{top:"-1px"}} 
                                    value={sliderValue}
                                    onChange={(value)=>sliderOnChange(value)}
                                    disabled={sliderDisabled}
                                    dots={true}
                                    activeDotStyle={{borderColor:"#e9e9e9"}}
                                    step={10}
                                    
                                    />
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-auto">
                                    <div onClick={()=>handleContinue()} className="theme-hover theme-btn">Continue</div>
                                </div>
                            </div>
                        </div>

                        <div style={{display:state===2 ? "" : "none"}} className="container p-0 state__2 animated fadeIn">
                            <div className="row">
                                <div className="col-12 paragraph">
                                    In a survey of respondents between ages 18 and 25,
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">

                                    <div className="header overflow-hidden">
                                        <span className="border-bottom-insert">
                                        82% said they would donate a higher amount to the larger charities.
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                                
                            <div className="row">
                                <div className='col-12'>
                                    <ControlHint classes="mt-2" text={"Hover over the circles find out more about these charities!"}></ControlHint>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12 paragraph">
                                It’s easy for donors to overlook smaller charities, especially if the charities support causes that not many people can relate to. Find out more about the work of smaller charities such as Wicare Support Group. 
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-auto">
                                    <button onClick={()=>{onClose()}} className="theme-btn theme-hover">Done</button>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>

                    <div className="col-6 d-flex flex-column offset-1 justify-content-center">
                        
                        <div className="row canvas-wrapper ">
                            <canvas className="col-auto" id="canvas">
                            </canvas>


                            <div className={`sm-charity-box container animated fadeIn ${showWIS ? "animated fadeIn" : "animated fadeOut"}`}>
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="header w-100 border-bottom-half-white">What is a <span style={{color:"#F1B148"}}>small</span> charity? </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="paragraph">A small charity refers to one with gross annual receipts of less than $500,000.</span>
                                    </div>
                                </div>
                            </div>

                            <div className={`bg-charity-box container animated fadeIn ${showWIB ? "animated fadeIn" : "animated fadeOut"}`}>
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="header w-100 border-bottom-half-white">What is a <span style={{color:"#7596FF"}}>large</span> charity? </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="paragraph">A large charity or Institution of a Public Character (IPC) refers to one with gross annual receipts of not less than $10 million in each of the last 2 financial years.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    
                        
                    </div>
            )}

            {topRightBtn}
                
            </div>
            
            <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
            <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/charities-in-singapore`} des={"Share the exhibit with your friends!"}></ShareModal>
        </div>
        )

}