import React,{useState,useEffect} from "react";
import {CKTL_logo_subheader} from "../../utils/images"
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

export default function SideBar(props){
    const {
        displayingOL,
        setDisplayingOverlay,
        show,
        setShow,
        displayFeedback,
        setDisplayFeedback,
        displayAuthor,
        setDisplayAuthor,
        displayBookLaunch,
        setDisplayBookLaunch,
        displayOliveBranch,
        setDisplayOliveBranch,
        displayCauses,
        setDisplayCauses,
        displayAckSchools,
        setDisplayAckSchools,
        displayAckOrgs,
        setDisplayAckOrgs,
        displayFlythrough,
        setDisplayFlythrough,
        displayContactUs,
        setDisplayContactUs
    }=props
    const [showSlideDown, setShowSlideDown] = useState(false);
    
    const [glowGiveaway, setGlowGiveaway] = useState(true);

    function hideAll(){
        setDisplayFeedback(false)
        setDisplayAuthor(false)
        setDisplayBookLaunch(false)
        setDisplayOliveBranch(false)
        setDisplayCauses(false)
        setDisplayAckSchools(false)
        setDisplayAckOrgs(false)
        setDisplayFlythrough(false)
        setDisplayContactUs(false);
    }

    function onItemClick(setTarget){
        hideAll();
        setTarget(true);
        setDisplayingOverlay(true);
        // pauseMusic();
    }

    // function redeemBtn(target){
    //     if(glowGiveaway)
    //         setGlowGiveaway(false);
    //     onItemClick(target);
    // }

    useEffect(() => {
        setTimeout(()=>{
            if(glowGiveaway)
                setGlowGiveaway(false);
        }, 200000)
    },[glowGiveaway])

    return(
    <div className={`sidebar-container container ${show? "active" :"inactive"}`}>
        <div className="row">
            <div className="col-12 main-title-container">
                <a target="_blank" rel="noopener noreferrer" href="https://www.givedangerously.today"><img className="logo" alt="logo" src={CKTL_logo_subheader}></img></a>
            </div> 
        </div>

        <div className="row">
            <div className="col-12 items-container">
                <div onClick={()=>{onItemClick(setDisplayAuthor)}} className={`item ${displayAuthor?"active":""}`}>Introduction</div>
                <div onClick={()=>{onItemClick(setDisplayFlythrough)}} className={`item ${displayFlythrough?"active":""}`}>Exhibition Flythrough</div>

                <div onClick={()=>{onItemClick(setDisplayBookLaunch)}} className={`item ${displayBookLaunch?"active":""}`}>Book Launch and Exhibition Opening Ceremony</div>
                <div onClick={()=>{onItemClick(setDisplayOliveBranch)}} className={`item ${displayOliveBranch?"active":""}`}>Significance of the Olive Branch</div>
                <div onClick={()=>{onItemClick(setDisplayCauses)}} className={`item ${displayCauses?"active":""}`}>Causes Supported by the Legacy</div>
                {/* <div style={{animation: glowGiveaway? "glow 1.5s ease-in-out infinite alternate" : ""}} onClick={()=>{redeemBtn(setDisplayRedeem)}} className={`item-redeem ${displayRedeem?"active":""}`}>
                    Enter the Giveaway
                </div>  */}
                <div  onClick={()=>setShowSlideDown(!showSlideDown)} className="item akn"><span>Acknowledgements</span> <span className="d-flex flex-column justify-content-center">{showSlideDown ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down "></i>}</span></div>  
            </div>
        </div>

        <div className="row">
            <SlideDown>
                {showSlideDown ? (<div className="col-12 slidedown-wrapper">
                    <div onClick={()=>{onItemClick(setDisplayAckSchools)}} className={`item schools ${displayAckSchools?"active":""}`}>Schools</div>
                    <div onClick={()=>{onItemClick(setDisplayAckOrgs)}} className={`item orgs ${displayAckOrgs?"active":""}`}>Organizations</div>
                </div>) : null}
            </SlideDown>
        </div>

        <div className="row flex-grow-1">
            <div className="col-12 info-container">

                <div className="functions">
                    <div onClick={()=>{onItemClick(setDisplayFeedback)}} className={`d-flex flex-column justify-content-center align-items-center col-6 sub-item item__51 ${displayFeedback?"active":""}`}>
                        <i className="far fa-smile fa-2x"></i>
                        <span>Feedback</span>
                    </div>
                    
                    <div onClick={()=>{onItemClick(setDisplayContactUs)}} className={` d-flex flex-column justify-content-center align-items-center col-6 sub-item item__52 ${displayContactUs?"active":""}`}>
                        <i className="fas fa-phone-alt fa-2x"></i>
                        <span>Contact Us</span>
                    </div>
                    
                </div>

                <div className="copyright">
                    &#169; Give Dangerously 2021
                </div>

                <div className="hol">
                    Powered by <a className="destyled-link" href="https://www.hol.sg/" target="_blank" rel="noopener noreferrer"><span>HOL Experiences</span></a>
                </div>

            </div>
        </div>
    </div>);
};
