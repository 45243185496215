import React,{useState,useEffect} from "react";

import {btn_black_cross} from "../../utils/images"
import {API_URL_ARTWORK} from "../../config"

import LinkRow from "./overlay_content/link_row"


export default function ShareModal(props){
   const {link,des,display,setDisplay,isArtwork,image,filename}=props;
   const [copy, setCopy] = useState(false);

    var onCopy=()=>{

        var copyText=document.getElementById("link-field")

        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");

        setCopy(true);
    }
    
    //uploads artwork to server
    function uploadImage(file){
        var data = {
            fileName: filename,
            file: file
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Accept': 'application/json' },
            body: JSON.stringify(data)
        };

        fetch(API_URL_ARTWORK, requestOptions)
        .then(response => {
            console.log("Success: "+response.text())
        });
    }

   //sharing for social media
    function handleFacebook(){
       if(isArtwork){
            return "http://www.facebook.com/share.php?u=https://tcktl.sg/artwork/"+filename
       }
       else
            return "http://www.facebook.com/share.php?u="+encodeURIComponent(link)
    }

    var handleTwitter=()=>{
        if(isArtwork)
            return "http://twitter.com/intent/tweet?text=Check out my artwork! &url="+encodeURIComponent("https://tcktl.sg/artwork/"+filename)+"&hashtags=#tcktl,#givedangerously"
        else
            return "http://twitter.com/intent/tweet?text=Check out this exhibition!&url="+encodeURIComponent(link)+"&hashtags=#tcktl,#givedangerously"
    }

    var handleLinkedIn=()=>{
        if(isArtwork)
            return "https://www.linkedin.com/sharing/share-offsite/?url=" +encodeURIComponent("https://tcktl.sg/artwork/"+filename)
        else
            return "https://www.linkedin.com/sharing/share-offsite/?url="+encodeURIComponent(link)
    }

    var handlePinterest=()=>{
        if(isArtwork)
            return "http://pinterest.com/pin/create/button/?url=https://tcktl.sg/&media=https://tcktl.sg/artwork/"+filename
        else
            return "http://pinterest.com/pin/create/button/?url="+encodeURIComponent(link)
    }

    var handleMail=()=>{
        if(isArtwork)
            return "mailto:?subject=Check out this exhibition!&body=Hello! %0D%0A%0D%0A Check out my artwork that I created: https://tcktl.sg/artwork/"+filename+" %0D%0A%0D%0A Created through: "+link;
        else
        return "mailto:?subject=Check out this exhibition!&body=Hello! %0D%0A%0D%0A Please check out this virtual exhibition: "+link+"%0D%0A ";
    }

    var onClose=()=>{
       setDisplay(false);
       setCopy(false);
    }

    useEffect(()=>{
        if(image !== undefined && image !== "" ){
            uploadImage(image);
        }
    }, [image])

   if(!display){
       return (<></>)
   }

   if(isArtwork)
   {
        return (
            <div className="container share-modal-container animated fadeIn">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <div className="header">SHARE YOUR ARTWORK!</div>
                    </div>
                </div>
        
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-center">
                            {/* <div className="des">{des}</div> */}
                            <div className="des">Thank you for expressing yourself on our virtual canvas.</div>
                    </div>
                </div>

                <div className="row mt-3 justify-content-center">
                    <div className="col-11 d-flex justify-content-center artwork-container">
                        <img className="share-artwork" src={image}/>
                        <a download={filename} href={image} target="_blank">
                            <i className="fas fa-file-download fa-2x clickable download-icon" aria-hidden="true"/>
                        </a>
                    </div>
                </div>
        
                <div className="row mt-3">
                    <div className="col-6 d-flex justify-content-start">
                            <div className="des-artwork">Share your art piece on your socials!</div>
                    </div>
                    <div className="col-6 d-flex justify-content-start">
                            <div className="des-artwork">Or copy this link to share the interactive!</div>
                    </div>
                    <div className="mt-2 col-6 d-flex justify-content-center">
                        <div className="col-2 social-icon-div d-flex flex-column justify-content-center align-items-center">
                            <a href={handleTwitter()} target="_blank">
                                <i className="fab fa-twitter fa-2x clickable link-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="col-2 social-offset social-icon-div d-flex flex-column justify-content-center align-items-center">
                            <a href={handleFacebook()} target="_blank">
                                <i className="fab fa-facebook-square fa-2x clickable link-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="col-2 social-offset social-icon-div d-flex flex-column justify-content-center align-items-center">
                            <a href={handleLinkedIn()} target="_blank">
                                <i className="fab fa-linkedin fa-2x clickable link-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="col-2 social-offset social-icon-div d-flex flex-column justify-content-center align-items-center">
                            <a href={handlePinterest()} target="_blank">
                                <i className="fab fa-pinterest-p fa-2x clickable link-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="col-2 social-offset social-icon-div d-flex flex-column justify-content-center align-items-center">
                            <a href={handleMail()} target="_blank">
                                <i className="fas fa-envelope fa-2x clickable link-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>

                    <LinkRow rowClass="mt-2 col-6" copyLink={()=>onCopy()} link={link}></LinkRow>
                </div>
        
                <div className="row mt-2">
                    <div style={{opacity: copy ? "1" :'0'}} className="col-12 d-flex justify-content-center">
                            <span className="message">Link Copied!</span>
                    </div>
                </div>
        
                    
                <div className="btn-top-right"  >
                    <a onClick={()=>onClose()}><img src={btn_black_cross} alt="white cross"></img></a>
                </div>    
            </div>)
    }
    else 
    {
        return (
            <div className="container share-modal-container animated fadeIn">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                    <div className="header">SHARING IS CARING!</div>
                    </div>
                </div>
        
                <div className="row mt-3">
                    <div className="offset-1 col-11 d-flex justify-content-start">
                        {/* <div className="des">{des}</div> */}
                        <div className="des">Spread the word about <i>The Char Kway Teow Legacy</i> to your friends and family.</div>
                    </div>
                </div>
        
                <div className="row mt-3">
                    <div className="mt-2 col-12 d-flex justify-content-center">
                        <div className="col-1 social-icon-div d-flex flex-column justify-content-center align-items-center">
                            <a href={handleTwitter()} target="_blank">
                                <i className="fab fa-twitter fa-2x clickable link-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="col-1 social-offset social-icon-div d-flex flex-column justify-content-center align-items-center">
                            <a href={handleFacebook()} target="_blank">
                                <i className="fab fa-facebook-square fa-2x clickable link-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="col-1 social-offset social-icon-div d-flex flex-column justify-content-center align-items-center">
                            <a href={handleLinkedIn()} target="_blank">
                                <i className="fab fa-linkedin fa-2x clickable link-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="col-1 social-offset social-icon-div d-flex flex-column justify-content-center align-items-center">
                            <a href={handlePinterest()} target="_blank">
                                <i className="fab fa-pinterest-p fa-2x clickable link-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="col-1 social-offset social-icon-div d-flex flex-column justify-content-center align-items-center">
                            <a href={handleMail()} target="_blank">
                                <i className="fas fa-envelope fa-2x clickable link-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
        
                <div className="row mt-3">
                    <div className="offset-1 col-11 d-flex justify-content-start">
                        <div className="des">Or copy this link to share</div>
                    </div>
                </div>
        
                <LinkRow rowClass="mt-3" copyLink={()=>onCopy()} link={link}></LinkRow>
        
                <div className="row mt-3 clickable">
                    <div className="col-12 d-flex justify-content-center">
                        <button onClick={()=>{onCopy()}} className="copy-btn">Copy Link</button >
                    </div>
                </div>
        
                <div className="row">
                    <div style={{opacity: copy ? "1" :'0'}} className="col-12 d-flex justify-content-center">
                        <span className="message">Link Copied!</span>
                    </div>
                </div>
        
                    
                <div className="btn-top-right clickable"  >
                    <a onClick={()=>onClose()}><img src={btn_black_cross} alt="white cross"></img></a>
                </div>    
            </div>
        )
    }
}