import React,{useEffect, useState} from "react";
import {main_data} from "../store"
import {btn_white_cross, btn_black_cross} from "../../utils/images"
import BackMainButton from "./overlay_content/back_main_button"

import UsefulIcons from "./useful_icons"
import ShareModal from "./share_modal"
import {ROOT_URL} from "../../config"

import {setStateWithDelay,navigateFunctionSet} from '../../utils/utility';
import CharacterCard from "./overlay_content/character_card";
import ControlHint from "./overlay_content/control_hint"
import { isMobileOnly,isMobile } from "react-device-detect";

const data=main_data.four_cards;

export default function FourCards(props){

    const {display,setDisplay,sdk,setDisplayStoryCards,openCallback,closeCallback,isStandalone}=props;
    const [displayShareModal, setDisplayShareModal] = useState(false);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>):(<BackMainButton display={true}/>);

    var onClose=()=>{
        setDisplay(false);
        
    }

    var navigateToStoryCards=()=>{
        onClose()
        
       
        navigateFunctionSet(sdk,"5DNc56ffQYO")
        setStateWithDelay(setDisplayStoryCards,true,1500)
    }

    useEffect(() => {

        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
        
        return () => {
            
        };
    }, [display]);

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone1_Intro.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone1_Intro.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }
   
    if (display===false){
        return(<></>)
    }

    return (
    <div className="full-screen animated fadeIn overflow-hidden">
        <div className="full-screen-black-bg__half position-absolute z-index-1 "/>
        {isMobileOnly?(
            <div className="z-index-2 container-fluid four-cards-container-mobile">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 d-flex flex-column">
                        <div className="row">
                            <div className="col-auto text-center header-main border-bottom-theme">
                                {`Each ingredient represents a giver`}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 d-flex justify-content-center">
                            <ControlHint text={`Flip the cards to find out who represents each ingredient`} isAbsolute={false}></ControlHint>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="row w-100 flex-nowrap cards-wrapper mt-3">
                    <CharacterCard data={data[0]} cardType={0}/>
                    <CharacterCard data={data[1]} cardType={1}/>
                    <CharacterCard data={data[2]} cardType={0}/>
                    <CharacterCard data={data[3]} cardType={1}/>
                </div>

                <div className="row d-flex justify-content-start mt-3">
                    <div className="col-auto"> <button onClick={()=>{navigateToStoryCards()}} style={{width:"auto"}} className="theme-btn-auto theme-hover">What’s the Char Kway Teow Legacy all about?</button></div>
                </div>

            </div>
        ):(
            <div className="z-index-2 container-fluid four-cards-container">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 d-flex flex-column">
                        <div className="row">
                            <div className="col-auto text-center header border-bottom-theme">
                                {`Each ingredient represents a giver`}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 d-flex justify-content-center">
                            <ControlHint text={`Flip the cards to find out who represents each ingredient`} isAbsolute={false}></ControlHint>
                            </div>
                        </div>
                    </div>
                    
                </div>

                {/* <div className="row">
                    <div className="col header">
                        <h1>Who are the people behind the giving legacy?</h1>
                    </div>
                </div> */}
                
                <div className="row w-100 flex-nowrap cards-wrapper mt-3">
                    <CharacterCard data={data[0]} cardType={0}/>
                    <CharacterCard data={data[1]} cardType={1}/>
                    <CharacterCard data={data[2]} cardType={0}/>
                    <CharacterCard data={data[3]} cardType={1}/>
                </div>



                <div className="row d-flex justify-content-start mt-3">
                    <div className="col-auto"> <button onClick={()=>{navigateToStoryCards()}} style={{width:"auto"}} className="theme-btn-auto theme-hover">What’s the Char Kway Teow Legacy all about?</button></div>
                </div>
            </div>
        )}
        
        <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
        <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/who`} des={"Share the exhibit with your friends!"}></ShareModal>

        {/* <div className="btn-top-right">
                 <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
        </div> */}
        {topRightBtn}
        
    </div>
    )
}