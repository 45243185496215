import React,{useEffect,useState,useRef} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import './App.scss';

import Home from './app/home'
import Tour from './app/tour'
import FourCards from "./app/components/four_cards";
import StoryCards from "./app/components/story_cards";
import CommunityServiceHelp from "./app/components/community_service_help"
import Wicare from "./app/components/wicare"
import ArtTherapy from './app/components/art_therapy'
import Dementia from './app/components/dementia'
import SuperHero from './app/components/super_hero'
import EducationIntro from './app/components/education_intro'
import MTEverest from './app/components/mt_everest'
import TCM from './app/components/TCM'
import Caregiver from './app/components/caregiver'
import Superpower from './app/components/superpower'
import EducationValues from "./app/components/education_values"
import Library from "./app/components/library"
import MobileOrientation from './app/orientation'
import {isMobile} from 'react-device-detect'

import {ThemeProvider,createTheme,SoundsProvider,createSounds} from "arwes"

import {scifi_click,scifi_deploy,scifi_typing} from "./utils/sounds"

import {API_URL_SESSION_INFO,BASENAME} from "./config"

import $ from "jquery"

//remove after launch
import IntroductoryVideo from './app/components/introductory_video';

const id=Date.now();




// var ip;
// $.getJSON('https://api.ipify.org?format=json', function(data){
//   ip=data.ip
// })

var ip;
var city;
var country;

$.ajax({
  url: "https://geolocation-db.com/jsonp",
  jsonpCallback: "callback",
  dataType: "jsonp",
  success: function( location ) {
    ip=location.IPv4;
    city=location.city;
    country=location.country_name;
  }
});		



const myTheme = {
  color: {
    primary: {
      base: '#F1B148',
      dark: '#F1B148',
      light: 'white'
    },
    secondary:{
      light:'#F1B148',
      base:'white'
    },
    header: {
      base: 'white',
      dark: '#a818a7',
      light: '#f458f2'
    }
  }
};

const mySounds = {
  shared: { volume: 1, },  // Shared sound settings
  players: {  // The player settings
    click: {  // With the name the player is created
      sound: { src: [scifi_click] }  // The settings to pass to Howler
    },
    typing: {
      sound: { src: [scifi_typing] },
      settings: { oneAtATime: true }  // The custom app settings
    },
    deploy: {
      sound: { src: [scifi_deploy] },
      settings: { oneAtATime: true }
    },
  }
};
function App() {
  
  const [tourCounters, setTourCounters] = useState({
    fourCardsCounter:0,
    storyCardsCounter:0,
    superPowerCounter:0,
    superHeroCounter:0,
    libraryCounter:0,
    communityServiceIntroCounter:0,
    artTherapyCounter:0,
    communityServiceHelpCounter:0,
    educationIntroCounter:0,
    MTEverestCounter:0,
    educationValuesCounter:0,
    caregiverCounter:0,
    TCMCounter:0,
    dementiaCounter:0,

  });

  const tourCountersRef=useRef({})
  tourCountersRef.current=tourCounters;


  var Test=()=>{
    //
    // sendData(API_URL_SESSION_INFO);
    console.log("Feedback: "+process.env.REACT_APP_PROD_API_URL_FEEDBACK)
    console.log("SESSION_INFO: "+process.env.REACT_APP_PROD_API_URL_SESSION_INFO)
    console.log("CARING_SCALE: "+process.env.REACT_APP_PROD_API_URL_CARING_SCALE)
    console.log("MATTERPORT_SDK_KEY: "+process.env.REACT_APP_PROD_MATTERPORT_SDK_KEY)
    console.log("DOMAIN: "+process.env.REACT_APP_PROD_DOMAIN)
    console.log("BASENAME: "+process.env.REACT_APP_PROD_BASENAME)
    console.log(process.env)
  }


  function sendData(url){
   
      
      var data = { 
      id: id,
      ip:ip,
      city:city,
      country:country,
      tour_counters:tourCountersRef.current,
    };


      data=JSON.stringify(data)
      // console.log(data)
    
      // var blob=new Blob([data], {type : 'application/json; charset=UTF-8'})
      navigator.sendBeacon(url, data);
  }

  function beforeUnloadListener(){
    sendData(API_URL_SESSION_INFO);
  }
  
  useEffect(() => {
    window.addEventListener("beforeunload",beforeUnloadListener)    
    return () => {
      window.removeEventListener("beforeunload",beforeUnloadListener)
    };
  }, []);

  return (
    <ThemeProvider theme={createTheme(myTheme)}>
      <SoundsProvider sounds={createSounds(mySounds)}>
        <div className="App" id="app-container">
            <Router basename={BASENAME}>
              <Switch>
                <Route exact path="/">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <Home />
                </Route>

                <Route exact path="/tour">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <Tour setTourCounters={setTourCounters} />
                </Route>

                {/* Zone 1 */}
                <Route exact path="/who">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <FourCards display={true} isStandalone={true}></FourCards>
                </Route>

                <Route exact path="/what">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <StoryCards display={true} isStandalone={true}></StoryCards>
                </Route>

                {/* Zone 2 */}
                <Route exact path="/superpower">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <Superpower display={true} isStandalone={true}></Superpower>
                </Route>

                <Route exact path="/superhero">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <SuperHero display={true} isStandalone={true}></SuperHero>
                </Route>

                <Route exact path="/library">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <Library display={true} isStandalone={true}></Library>
                </Route>

                {/* Zone 3 */}
                <Route exact path="/charities-in-singapore">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <Wicare display={true} isStandalone={true}></Wicare>
                </Route>

                <Route exact path="/art-therapy">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <ArtTherapy display={true} isStandalone={true}></ArtTherapy>
                </Route>

                <Route exact path="/community-service">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <CommunityServiceHelp display={true} isStandalone={true}></CommunityServiceHelp>
                </Route>

                {/* Zone 4 */}
                <Route exact path="/education">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <EducationIntro display={true} isStandalone={true}></EducationIntro>
                </Route>

                <Route exact path="/mount-everest">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <MTEverest display={true} isStandalone={true}></MTEverest>
                </Route>

                <Route exact path="/values">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <EducationValues display={true} isStandalone={true}></EducationValues>
                </Route>

                {/* Zone 5 */}
                <Route exact path="/caregiver">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <Caregiver display={true} isStandalone={true}></Caregiver>
                </Route>

                <Route exact path="/TCM">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <TCM display={true} isStandalone={true}></TCM>
                </Route>

                <Route exact path="/memory-game">
                  {/* to check mobile orientation */}
                  {isMobile? (
                    <MobileOrientation />
                  ):(
                    null
                  )}
                  <Dementia display={true} isStandalone={true}></Dementia>
                </Route>
                
                <Route exact path="/introduction-video">
                  <IntroductoryVideo></IntroductoryVideo>
                </Route>

              </Switch>
            </Router>
        </div>
      </SoundsProvider>
    </ThemeProvider>
  );
}

export default App;
