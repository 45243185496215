import React from "react";
import {bool, func} from 'prop-types';

const MenuIcon = ({open, setOpen, show}) => {
    return (
        <div className = "mobile-icon" open={open} onClick={() => setOpen(!open)} style={{zIndex: show ? '2' : '0'}}>
            <div style={{ transform: open ? 'rotate(45deg)' : 'rotate(0)', background: open ? 'white' : '#202c51' }}/>
            <div style={{ transform: open ? 'translateX(-15px)' : 'translateX(0)', opacity: open ? '0' : '1' }}/>
            <div style={{ transform: open ? 'rotate(-45deg)' : 'rotate(0)', background: open ? 'white' : '#202c51'}}/>
        </div>
    )
}

MenuIcon.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};

export default MenuIcon;