import React,{useState,useEffect} from "react";
import {btn_white_cross,feedback_leaf_white,feedback_leaf_yellow} from "../../utils/images"
import { setStateWithDelay } from "../../utils/utility";
import {API_URL_FEEDBACK,ROOT_URL} from "../../config"

import LinkRow from "./overlay_content/link_row"

import $ from 'jquery'


export default function Feedback(props){
    const {display,setDisplay,setDisplayingOverlay}=props;

    const [rating, setRating] = useState(6);
    const [norating,setNoRating]=useState(false)
    const [showError,setShowError]=useState(false)
    const [copy,setCopy]=useState(false)

    const [feedback,setFeedback]=useState("")
    

    const [stage, setStage] = useState(0);

    var onClose=()=>{
        setDisplay(false);
        setDisplayingOverlay(false);
        setRating(6);
        setShowError(false);
        setCopy(false);
        setStage(0);
        setNoRating(false)
        setFeedback("")
    }

    var onInput=(event)=>{
        setFeedback(event.target.value);
    }

    var onSubmitRating=()=>{
        if(rating===0){
            setNoRating(true)
        }
        else{
            setStage(1)
        }
    }

    var onSubmitFeedback=(index)=>{
        var textarea=$('#feedback-area')[0]
        if(textarea.value==="" && index===1){
            setShowError(true)
        }
        else{
            var data={
                rating:rating,
                feedback:feedback
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };

            fetch(API_URL_FEEDBACK, requestOptions)
            .then(response => response.json())
            //.then(data => this.setState({ postId: data.id }));
            //post
            setStage(2)
        }
    }

    
    var onCopy=()=>{

        var copyText=document.getElementById("link-field")
        
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    
        /* Copy the text inside the text field */
        document.execCommand("copy");
    
        setCopy(true);
    
    
       }


    if (!display){
        return <></>
    }

    return (
    
    <div className="feedback-master-container animated fadeIn">
        <div className="position-absolute"></div>

        <div className="container feedback-container animated fadeIn z-index-5">
        
            <div style={{display:stage===0 ? "flex" : "none"}} className="row h-100 flex-column justify-content-center rating-container">
                <div className="col-8 offset-2 content-wrapper">
                    <div className="row w-100">
                        <div className="col-12">

                            <div className="header overflow-hidden">
                                <span className="border-bottom-insert text-center">How did you find the exhibition?</span>
                            </div>

                        </div>
                        
                    </div>

                    <div className="row w-100 mt-2">
                        <div className="col-12">

                            <div className="paragraph pre-line">
                                {`Inspired to start your own giving legacy?
                                You can start by giving us 6 olive branches!`} &#128522;
                            </div>

                        </div>
                        
                    </div>

                    <div className="row flex-nowrap leaf-row mt-4">
                        <div className="col-2">
                            <img onClick={()=>{setRating(1)}} className="w-100" alt="leaf" src={rating >=1 ? feedback_leaf_yellow : feedback_leaf_white}></img>
                        </div>

                        <div className="col-2">
                            <img onClick={()=>{setRating(2)}} className="w-100" alt="leaf" src={rating >=2 ? feedback_leaf_yellow : feedback_leaf_white}></img>
                        </div>

                        <div className="col-2">
                            <img onClick={()=>{setRating(3)}} className="w-100" alt="leaf" src={rating >=3 ? feedback_leaf_yellow : feedback_leaf_white}></img>
                        </div>

                        <div className="col-2">
                            <img onClick={()=>{setRating(4)}} className="w-100" alt="leaf" src={rating >=4 ? feedback_leaf_yellow : feedback_leaf_white}></img>
                        </div>

                        <div className="col-2">
                            <img onClick={()=>{setRating(5)}} className="w-100" alt="leaf" src={rating >=5 ? feedback_leaf_yellow : feedback_leaf_white}></img>
                        </div>

                        <div className="col-2">
                            <img onClick={()=>{setRating(6)}} className="w-100" alt="leaf" src={rating >=6 ? feedback_leaf_yellow : feedback_leaf_white}></img>
                        </div>

                        
                    </div>

                    <div className="row mt-5">
                        <div className="col-auto">
                            <button onClick={()=>onSubmitRating()} className="theme-btn theme-hover">Submit</button>
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div style={{opacity: norating ? "1" :'0'}} className="col-12 d-flex justify-content-center">
                            <span className="error">Please click on an olive to rate</span>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{display:stage===1 ? "flex" : "none"}} className="row h-100 flex-column justify-content-center comment-container animated fadeIn">
                <div className="col-8 offset-2 content-wrapper">
                    <div className="row w-100">
                        <div className="col-12">

                            <div className="header overflow-hidden">
                                <span className="border-bottom-insert text-center">We’d love to know more</span>
                            </div>

                        </div>
                        
                    </div>

                    

                    <div className="row w-100 mt-2">
                        <div className="col-12">

                            <div className="paragraph pre-line">
                            We want to know more about your experience at <i>The Char Kway Teow Legacy</i> Virtual Exhibition. Do share your experience with us below.
                            </div>

                        </div>
                        
                    </div>

                    <div className="row w-100 mt-2">
                        <div className="offset-1 col-10">
                            <textarea onChange={(event)=>{onInput(event)}} id="feedback-area"  placeholder="What did you like the most?" defaultValue={feedback} className="w-100"></textarea >
                        </div>
                    </div>

                    <div className="row">
                        <div style={{opacity: showError ? "1" :'0'}} className="col-12 d-flex justify-content-center">
                            <span className="error">Field must not be empty</span>
                        </div>
                    </div>

                    

                    <div className="row mt-3">
                        <div style={{}} className="col-auto d-flex justify-content-center">
                        <button onClick={()=>{onSubmitFeedback(0);}}  className="theme-btn theme-hover">Skip</button>
                        <button onClick={()=>{onSubmitFeedback(1);}}  className="theme-btn theme-hover ml-3">Submit</button>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{display:stage===2 ? "flex" : "none"}} className="row h-100 flex-column justify-content-center ending-container animated fadeIn">
                <div className="col-8 offset-2 content-wrapper">
                    <div className="row w-100">
                        <div className="col-12">

                            <div className="header overflow-hidden">
                                <span className="border-bottom-insert text-center">Thank you for sharing</span>
                            </div>

                        </div>
                        
                    </div>

                    

                    <div className="row w-100 mt-2">
                        <div className="col-12">

                            <div className="paragraph pre-line">
                                {`If you had a good time, please share this experience with your friends!`}
                            </div>

                        </div>
                        
                    </div>


                    <LinkRow copyLink={onCopy} link={ROOT_URL} rowClass="mt-3 w-100"></LinkRow>

                    

                    <div className="row mt-3">

                        <div className="col-12 d-flex justify-content-center">
                            <button onClick={()=>{onCopy()}} className="theme-btn theme-hover">Copy Link</button >
                        </div>
                    </div>

                    

                    <div className="row">
                        <div style={{opacity: copy ? "1" :'0'}} className="col-12 d-flex justify-content-center">
                            <span className="message">Link Copied!</span>
                        </div>
                    </div>
                </div>
            </div>

        <div className="btn-top-right"  >
                 <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
            </div>
    </div>
    </div>
    )
}