import {ellipse_01,ellipse_02,ellipse_03,ellipse_04,
    welcome_img_irenesendoff,welcome_img_mrtan,welcome_img_beach,welcome_img_mrtan_irenetan,
    begin_1997,begin_bdcard,begin_ckt,begin_ttongcjkoh,begin_weekimwee,
    founder_cjkohpaintings,founder_cjpradap,founder_irenebday,founder_ongttirenetan,founder_youngcj,
ME_pic_1,ME_pic_2,ME_pic_3,ME_pic_4,who_cjkoh,who_irenetan,who_mrtan,who_ongtt,who_chillipaste,who_cockles,who_porkfat,who_ckt, story_card_01,story_card_02,story_card_03,story_card_04,
hero_student_color_01,hero_student_color_02,hero_student_color_03,hero_student_01,hero_student_02,hero_student_03,
hero_student_animation_01,hero_student_animation_02,hero_student_animation_03, hero_student_03_mobile, hero_student_02_mobile, hero_student_01_mobile,
hero_student_animation_03_mobile,hero_student_animation_02_mobile,hero_student_animation_01_mobile,
book_launch_opening_01,book_launch_opening_02,book_launch_opening_03,book_launch_opening_04,book_launch_opening_05,
book_launch_opening_06,book_launch_opening_07,book_launch_opening_08,book_launch_opening_09,book_launch_opening_10,
book_launch_opening_11,book_launch_opening_12,book_launch_opening_13,book_launch_opening_14,
ack_schools_01,ack_schools_02,ack_schools_03,ack_schools_04,
ack_orgs_01,ack_orgs_mobile} from "../utils/images"

import React from "react"


var txt_an_introduction=`Welcome to this exhibition! I’m Tan Hsuan Heng, and I’ll be your guide today.\n
This story is about the friendship of Uncle, Auntie, and CJ and their lifelong mission of philanthropy. \n
Throughout the panels, you will read interviews done by secondary students from various schools. They have diligently written about the impact of these three people’s friendship. \n
I hope their stories will inspire you to make your mark on society - whether it be monetary donations or acts of service. \n`;

var txt_introduction_quote=`“Why give? What do you get in return? Would you get richer and more famous? That I cannot promise, but I can testify that being involved in the process of giving had made me a ‘wealthy’ man, and I have received many things that money cannot buy.”
\n`;
var txt_quote_name_tan="-- HH Tan";



export const main_data={
    welcome:{
        main_header:"Welcome",

        paragraphs:{
            0:{
                header:"An Introduction",
                content:txt_an_introduction,
                quote:txt_introduction_quote,
                quote_name:txt_quote_name_tan
            }
        },
        images:{
            left:[welcome_img_mrtan,welcome_img_irenesendoff],
            right:[welcome_img_mrtan_irenetan,welcome_img_beach]
        }
    },
    causes:{
        main_header:"Causes supported by the Estates of CJ Koh, Ong Tiong Tat and Irene Tan Liang Kheng",

        paragraphs:{
            0:{
                content:"Auntie wanted their legacy to continue after her death and I’ve been directing her gifts to causes such as education, medical research and community services."
            }
        },

        ellipse:{
            0:ellipse_01,
            1:ellipse_02,
            2:ellipse_03,
            3:ellipse_04,
        },

        sub_header:{
            0:"Healthcare",
            1:"Legal Education",
            2:"Community Services",
            3:"Education",
        },

        bulletPoints:{
            0:["Lee Kong Chian School of Medicine, Nanyang Technological University","Chinese Medicine Clinic, Nanyang Technological University","National Centre for Infectious Diseases"],
            1:["CJ Koh Law Library, National University of Singapore","School of Law, Singapore Management University","School of Law, Singapore University of Social Sciences"],
            2:["Tan Tock Seng Hospital Community Fund","Wicare Support Group","Muscular Dystrophy Association Singapore"],
            3:["CJ Koh Law Library, National University of Singapore","School of Law, Singapore Management University","School of Law, Singapore University of Social Sciences"]
        },
        
    },

    
    founders:{
        main_header:"About the founders of the Giving Legacy",

        paragraphs:{
            0:{
                header:"A Lawyer, Artist, Philantrophist",
                content:`CJ was born in Indonesia in 1901. At the tender age of six, he was sent to stay with a Welsh family in Wales and remained in Britain for his education. During his stay in Britain, he struggled to make ends meet as his father was unable to wholly support CJ financially. As a philanthropist, CJ would have related to students who have to face similar financial woes.
 
                CJ was called to the Bar at Middle Temple in 1928. And at 50, he served as an honorary magistrate of the Juvenile Court in Singapore.
                 
                CJ and his wife, Pradap Kow, had a loving relationship. They enjoyed ballroom dancing and travelling around the world together. Pradap had been an English teacher at the Convent of the Holy Infant Jesus (CHIJ) at Victoria Street. She passed away at the age of 57 in 1969.                
                `,
                quote:false,
                quote_name:false
            },

            1:{
                header:"A Love Story",
                content:`When the stroke-stricken CJ had no one to rely on, Uncle moved in to take care of CJ. 
 
                Another frequent visitor at the house was Auntie - an executive broker at the Hong Kong Shanghai Bank. Back in the days, share trading was done manually. As part of her job, Auntie had to visit her clients daily.
                 
                Auntie had been caring for her sick mother for years, and empathised with Uncle’s selfless care for CJ. Drawn by this big-hearted man, love gradually blossomed between Uncle and Auntie.
                 
                In 1999, two years after CJ’s passing, Uncle married Auntie.                
                `,
                quote:false,
                quote_name:false
            }
        },
        images:{
            left:[founder_cjpradap,founder_cjkohpaintings,founder_irenebday],
            right:[founder_youngcj,founder_ongttirenetan]
        }
    },
    tommy_koh_intro:{
        main_header:`Giving — a speech by Professor Tommy Koh`,
        paragraphs:{
            0:{
                header:"Transcript",
                content:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius aliquet urna at rhoncus. Praesent interdum sem bibendum aliquet accumsan. Nullam ac maximus justo. Aliquam erat volutpat. Integer eu pellentesque nisl, eu fermentum tortor. Suspendisse eget magna nunc. Aliquam quis felis convallis, rhoncus ligula sit amet, venenatis turpis. Curabitur tincidunt pharetra purus, non lobortis ex semper ut. Praesent vel urna mi. Quisque pharetra, odio et tristique interdum, dui purus venenatis tellus, sed porttitor sapien dui non quam. Fusce eleifend lorem id ipsum posuere, non dapibus nunc tempus. Quisque in arcu ligula.

                Integer condimentum, eros eget feugiat condimentum, eros eget feugiat Quisque pharetra, odio et tristique interdum, Integer condimentum, eros eget feugiat Quisque
                quam. Fusce eleifend lorem id ipsum posuere, non dapibus nunc tempus. Quisque in arcu ligulaisque`
            }
        }
    },

    four_cards:{
        0:{
            front_image:who_ckt,
            back_image:who_cjkoh,
            food_name:"Springy Kway Teow",
            food_des:"The Texture Element",
            
            year:"(1901-1997)",
            occupation:"Lawyer",
            
            name:`Koh
            Choon Joo`,
            intro:<>CJ Koh was a successful lawyer, an enthusiastic artist, an avid poet and a quiet philanthropist. It was his dedication to helping the less privileged that started <i>The Char Kway Teow Legacy</i>.</>,
            
        },
        1:{
            front_image:who_porkfat,
            back_image:who_ongtt,
            food_name:`Crispy
            Pork Lard`,
            food_des:"The Fragant Element",
            
            
            name:`Ong
            Tiong Tat`,
            year:"(1939-2013)",
            occupation:"Stock Market Trader",
            intro:<>Ong Tiong Tat was a brilliant investor.<br/><br/>

            He was CJ’s loyal companion. He became CJ’s caregiver when CJ fell ill. Tiong Tat would take extra care to make CJ comfortable when he helped insert CJ’s nasal tubes or style his hair.</>
        },
        2:{
            front_image:who_cockles,
            back_image:who_irenetan,
            food_name:`Fresh Juicy
            Cockles`,
            food_des:"The Savoury Element",
            
            
            name:`Irene Tan
            Liang Kheng`,
            year:"(1943-2016)",
            occupation:`Share Trading Executive`,
            
            intro:<>Irene Tan was a dedicated wife to Ong Tiong Tat. While she had a successful career, her family was her biggest comfort. She would often show her affection through the delicious food that she bought or made for them.</>
        },
        3:{
            front_image:who_chillipaste,
            back_image:who_mrtan,
            food_name:`Fragrant
            Chilli Paste`,
            food_des:"The Spice Element",
            
            year:"(1952-Present)",
            occupation:`Engineer`,
            
            name:`Tan
            Hsuan Heng`,
            intro:<>HH Tan, nephew of Irene Tan, is continuing the giving legacy of CJ, Tiong Tat and his auntie. He hopes to inspire young people to make a positive difference to the lives of others in their own ways.</>
        },

    },
    community_service_intro:{
        0:{
            header:`Community Services`,
            content:`Legal education was something that CJ held close to his heart and he hopes to improve access legal knowledge and advance legal research.
            
            Did you know that smaller charities do not always get the same amount of  as bigger charities?`
        },

        1:{
            header:`80% of the donations in Singapore goes to bigger charities.`,
            content:`Most of the donations in Singapore goes to our educational institutes and religious organisations in Singapore. Only 20% of the total donations goes to the smaller charities.`
        },

        2:{
            header:`Small charities need just as much help or maybe even more, as compared to the bigger charities.`,
            content:`Due to the lack of awareness of these smaller charities that supports specific causes in Singapore, it is easy to be overlooked by the general public, especially if they are causes that not many people can relate to.`
        },
        3:{
            header:`Small charities need just as much help or maybe even more, as compared to the bigger charities.`,
            content:""
        }
    },

    super_hero:{
        header:`We are extra-ordinary.`,
        content:`Scholarship and bursaries are aimed to award law students who are financially disadvantaged yet deserving. Three beneficiaries of the scholarship and bursary share with us their ‘superpowers’ and how they would use it as a way to give back to the society.`,
        
        detail:{
            0:{
                name:`Gabriel Lee Heng Bin`,
                scholarship:`Recipient of Irene Tan Liang Kheng Scholarship`,
                intro:<><i className="font-weight-bold">Recipient of the Irene Tan Liang Kheng Scholarship</i><br/>Gabriel is a second-year law student at SUSS.<br/>
                He is determined to become a compassionate lawyer who will help the less privileged.
                </>,
                img:hero_student_color_01,
                img_grey:hero_student_01,
                animation:hero_student_animation_01,
                img_mobile:hero_student_01_mobile,
                animation_mobile:hero_student_animation_01_mobile
            },
            1:{
                name:`Lim Yi Zheng`,
                scholarship:`Recipient of Irene Tan Liang Kheng Scholarship`,
                intro:<><i className="font-weight-bold">Recipient of the Irene Tan Liang Kheng Bursary</i><br/>Yi Zheng is a fourth-year law student at SUSS.<br/>
                She follows the motto “Take one step at a time” as she strives to become a lawyer who listens with empathy.                
                </>,
                img:hero_student_color_02,
                img_grey:hero_student_02,
                animation:hero_student_animation_02,
                img_mobile:hero_student_02_mobile,
                animation_mobile:hero_student_animation_02_mobile
            },
            
            2:{
                name:`Satviender Kaur`,
                scholarship:`Recipient of Irene Tan Liang Kheng Scholarship`,
                intro:<><i className="font-weight-bold">Recipient of the Irene Tan Liang Kheng Scholarship</i><br/>Satviender is a third-year law student at SUSS.<br/>
                She hopes to become a lawyer who brings meaningful and positive changes to the lives of others.                               
                </>,
                img:hero_student_color_03,
                img_grey:hero_student_03,
                animation:hero_student_animation_03,
                img_mobile:hero_student_03_mobile,
                animation_mobile:hero_student_animation_03_mobile
            },
            
            
            

            
            
            
        }
    },

    education_intro:{
        0:{
            header:`Education is everywhere`,
            content:`The classic mode of education is always portrayed as a teacher in a classroom filled with students. But, many more life lessons can be learnt by living life through adventures in the outdoors as well as from right within our kitchen.

            Sometimes, all we need is just a spark of curiosity, to light up the motivation in us to explore the world and learn. `
        },
        1:{
            header:`Within Classroom`,
            content:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras velit nisi, viverra vitae ornare ornare, tempor sed erat. Donec auctor ullamcorper sem, in varius sem aliquam ac. Donec leo mi, tempus nec odio quis, finibus interdum nibh. Phasellus ut tortor quis. `

        },
        2:{
            header:`Within Homes`,
            content:`The classic mode of education is always portrayed as a teacher in a classroom filled with students. But, many more life lessons can be learnt by living life through adventures from right within our kitchen.

            Sometimes, all we need is just a spark of curiosity, to light up the motivation in us to explore the world and learn. `
        },
        3:{
            header:`Beyond`,
            content:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras velit nisi, viverra vitae ornare ornare, tempor sed erat. Donec auctor ullamcorper sem, in varius sem aliquam ac. Donec leo mi, tempus nec odio quis, finibus interdum nibh. Phasellus ut tortor quis ni`
        }
    },
    mt_everest:{
        0:{alt:"17,000 ft"},
        1:{
            img:ME_pic_1,
            alt:"17,000 ft",
            img_cap:"The Start",
            img_des:"After taking 14 days to reach the base camp, located at 17,000 ft, you will need to acclimatise"
        },
        2:{
            img:ME_pic_2,
            img_cap:"Camp One",
            alt:"20,000–21,000 ft",
            img_des:"When you reach 20,000 ft, you have reached the Valley of Silence. It’s the first camp (of five) on Mount Everest where climbers adjust to the high altitude."
        },
        3:{
            img:ME_pic_3,
            img_cap:"Almost at the Death Zone",
            alt:"28,000 ft",
            img_des:"When you reach 28,000 ft, the air pressure is one third of what you are accustomed to at sea level. Acute Mountain Sickness (AMS), High Altitude Cerebral Edema (HACE) and frostbite are only some of the risks you will face."
        },
        4:{
            img:ME_pic_4,
            img_cap:"Top of the world",
            alt:"29, 029 ft. (Imagine 20 Empire State buildings stacked on top of one another!)",
            img_des:`You have finally reached the summit!
            Hooray!
            `
        }
    },

    story_cards:{
        0:{
            header:<>Destinies fulfilled by <br/><i>char kway teow</i></>,
            text:"As a young man, Ong Tiong Tat often ate char kway teow at his favourite stall at Zion Road hawker centre. One day in 1971, out of curiosity, he introduced himself to an elderly patron who also frequented the same stall. This elderly man was Koh Choon Joo, or CJ Koh.",
            image:story_card_01,
        },
        1:{
            header:`A special bond`,
            text:`Despite their 38-year age gap, CJ and Tiong Tat hit it off immediately. They shared similar interests: wrestling, Guinness stout, football and fine art. 

            CJ eventually become Tiong Tat’s mentor. Later, when CJ fell ill, Tiong Tat was CJ’s caregiver until his passing. 
            `,
            image:story_card_02,
        },
        2:{
            header:`Love blossomed`,
            text:`Irene Tan, an executive managing share trading at The Hong Kong and Shanghai Banking Corporation, had CJ and Tiong Tat as her clients. 

            She felt drawn to Tiong Tat, who she observed was a big-hearted man, singlehandedly taking care of CJ. 
            
            Love blossomed between Irene and Tiong Tat.            
            `,
            image:story_card_03,
        },
        3:{
            header:`The gift that keeps on giving`,
            text:`As the executor of his auntie’s estate, HH Tan witnessed first-hand the positive difference giving can make to the lives of others. He hopes to encourage young people to make giving their second nature.

            Help an elderly cross the road. Lend a weaker student your tutorial notes. Offer a listening ear to a friend.
            
            Big or small, your every deed goes a long way.
            `,
            image:story_card_04,
        }
    },

    book_launch:{
        0:{
            img:book_launch_opening_01,
            cap:"The book launch and exhibition opening ceremony was held at the Ong Tiong Tat & Irene Tan Liang Kheng Auditorium at the Lee Kong Chian School of Medicine, Novena Campus, on 16 October 2019."
        },
        1:{
            img:book_launch_opening_02,
            cap:<>HH Tan autographing his book, The <i>Char Kway Teow</i> Legacy, for the guests. He hopes to encourage more people to give and pay it forward through the book and the exhibition.</>
        },
        2:{
            img:book_launch_opening_03,
            cap:"Students from four schools were involved in the project. They interviewed beneficiaries and wrote about their initiatives. A student from one of the schools, Raffles Institution, is seen here guiding guests at the exhibition."
        },
        3:{
            img:book_launch_opening_04,
            cap:"A number of outstanding students at Hwa Chong Institution are recipients of the CJ Koh Scholarship. The students who contributed to the project said: “We ‘gave back’ by being involved in this project, so it was very meaningful.”"
        },
        4:{
            img:book_launch_opening_05,
            cap:"The Convent of the Holy Infant Jesus at Victoria Street, now CHIJ Secondary, was where Pradap Kow, wife of CJ Koh, had taught. Some CHIJ students were invited to contribute to the book. Here they are, guiding guests at the exhibition."
        },
        5:{
            img:book_launch_opening_06,
            cap:"Students from Geylang Methodist School (Secondary) [GMSS], a beneficiary of the Estate, wrote about the initiatives made possible by the family’s giving. Irene Tan was an alumna of GMSS’s predecessor school, Geylang Methodist Girls’ School."
        },
        6:{
            img:book_launch_opening_07,
            cap:"HH Tan sharing with his guests why he gives and how he hopes his daughter and the generations to come will continue this giving legacy."
        },
        7:{
            img:book_launch_opening_08,
            cap:"The Guest of Honour, Miss Janet Ang Guat Har, sharing her own giving journey at the opening ceremony held at the Ong Tiong Tat & Irene Tan Liang Kheng Auditorium. The 450-seater state-of-the-art auditorium was named in recognition of the family’s gift to the Lee Kong Chian School of Medicine."
        },
        8:{
            img:book_launch_opening_09,
            cap:"Professor Cheong Hee Kiat, President of Singapore University of Social Sciences (SUSS), sharing how the Irene Tan Liang Kheng Scholarship Endowment Fund for Law has benefited SUSS students."
        },
        9:{
            img:book_launch_opening_10,
            cap:"Students from Hwa Chong Institution put up a wonderful martial arts performance."
        },
        10:{
            img:book_launch_opening_11,
            cap:"(Left to right) Professor Tan Eng Chye, President of National University of Singapore; Professor Cheong Hee Kiat, President of Singapore University of Social Sciences; HH Tan; Professor Subra Suresh, President of Nanyang Technological University, and his wife, Mary Suresh, at the exhibition. "
        },
        11:{
            img:book_launch_opening_12,
            cap:"Hwa Chong Institution students giving Professor Alan Chan, then Vice President (Alumni and Advancement), Nanyang Technological University, a tour of the exhibition. "
        },
        12:{
            img:book_launch_opening_13,
            cap:"HH Tan presenting his book to Professor Leo Yee Sin, Executive Director of the National Centre for Infectious Diseases (NCID), and Dr Mark Chen I-Cheng, Head of NCID Research Office. The family’s gift to NCID helps strengthen the community’s resilience against infectious diseases."
        },
        13:{
            img:book_launch_opening_14,
            cap:<>The perfect end to the day – <i>Char Kway Teow</i> among the dishes at the buffet reception for the guests.</>
        },
    },

    //remove after
    // virtual_launch:{
    //     0:{
    //         front_image:flip_01,
    //         back_image:flipback_01,
    //         food_name:"Need-for-Speed",
    //         food_des:"For the lightning fingers",
            
    //         header:<>First 50 participants of our giveaway<br/></>,
    //         text:<><br/>To receive a pair of tickets to 
    //         Lee Kong Chian History Museum.</>,
            
    //     },
    //     1:{
    //         front_image:flip_02,
    //         back_image:flipback_02,
    //         food_name:`Chill-at-Home`,
    //         food_des:"For the the homebodies who just want to Disney+ and Chill",

    //         header:<>LUMOS Ray Projector<br/></>,
    //         text:<><br/>Comes with a stand. (Worth up to $360) Compatible with Netflix and Youtube.</>
    //     },
    //     2:{
    //         front_image:flip_03,
    //         back_image:flipback_03,
    //         food_name:`Getaway-and-Chill`,
    //         food_des:"For those who need a 3D2N break",

    //         header:<>3D2N Staycation<br/></>,
    //         text:<><br/>At Oakwood Wanderlust’s Studio Room. (Worth up to $600)</>
    //     },
    //     3:{
    //         front_image:flip_04,
    //         back_image:flipback_04,
    //         food_name:`Shop-at-Home`,
    //         food_des:"For online shopping and food delivery fans",

    //         header:<>$300 worth of Shopee Vouchers<br/></>,
    //         text:<><br/>Prize will come in the form of 
    //         6 sets of $50 denominations.</>
    //     },
    // },

    ack_schools:[
        {img:ack_schools_01,cap:<>"Students of Class 3/4, 2019, CHIJ Secondary, who contributed to <i>The Char Kway Teow Legacy</i>, and their teachers, Ms Tivona Low and Dr Eulalia Han. Photo courtesy of Class 3/4, 2019, CHIJ Secondary.</>},
        {img:ack_schools_02,cap:<>"Students of Geylang Methodist School (Secondary) who contributed to <i>The Char Kway Teow Legacy</i> and their teachers, Mrs Lee Fang Lan and Ms Abelyn Quek. Photo courtesy of Kelvin Chua.</>},
        {img:ack_schools_03,cap:<>"Students of Hwa Chong Institution who contributed to <i>The Char Kway Teow Legacy</i> and their Teacher Mentor, Dr Yeo Hwee Joo. Photo courtesy of Hwa Chong Institution.</>},
        {img:ack_schools_04,cap:<>"Students of Raffles Institution who contributed to <i>The Char Kway Teow Legacy</i> and their Teacher Mentor, Mr Wilson Loh Chin Hin. Photo courtesy of Justinian Guan.</>},
    ],

    ack_orgs:[
        {img:ack_orgs_01,img_mobile:ack_orgs_mobile,cap:<>HH Tan would like to sincerely thank these organisations, which have supported him and helped bring <i >The Char Kway Teow Legacy: How an Enduring Friendship Turned into a Forever Gift</i> book, exhibition and virtual tour, to life.</>}
    ]
}

export var main_state={
    intro_flag:false
}


