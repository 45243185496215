import React from "react";
import {tutorial_ss, tutorial_mobile} from "../../utils/images"
import {isMobile} from 'react-device-detect';
export default function Tutorial(props){
    const {display,setDisplay,setViewed}=props;

    var onClose=()=>{
        setDisplay(false);
        setViewed(true)
    }

    if (display===false){
        return(<></>)
    }

    return(

        <div className="tutorial-container animated fadeIn">
            {isMobile ? (
                <div className="mobile-tutorial">
                    <img src={tutorial_mobile} alt="ss" className="screenshot animated fadeIn"></img>
                    <button onClick={()=>{onClose()}} className="return-button theme-btn-auto theme-hover">Back to exhibition</button>
                </div>
            ) : (
                <div>
                    <img src={tutorial_ss} alt="ss" className="screenshot animated fadeIn"></img>
                    <button onClick={()=>{onClose()}} className="return theme-btn-auto theme-hover">Back to exhibition</button>
                </div>
            )}
        </div>
    )
}