import React, {useState, useRef, useEffect} from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";
import {minimise_video, decline_btn, remind_btn, message_btn, battery_icon, 
    camera_icon, mute_call, flip_icon, end_btn, mr_tan_bg, CKTL_logo_subheader, video_qr, rotate_phone} from "../../utils/images.js";
import loader from "../../static/launch/load.gif"
import captions from "../../static/launch/mrtan_video.vtt"
import DeviceOrientation, {Orientation} from 'react-screen-orientation';

// import LowPowerMessage from "../../low_power_message"

export default function IntroductoryVideo(props){

    const [minimise, setMinimise] = useState(false);
    const [hide, setHide] = useState(false);
    const [displayEndMessage,setDisplayEndMessage]=useState(false);
    const [displayDeclineMessage,setDisplayDeclineMessage]=useState(false);
    const playRef = useRef(null);
    // const [stopRing,setStopRing]=useState(false);
    // const stopRef=useRef({})
    // stopRef.current=stopRing;
    const [startCount,setStartCount]=useState(false);
    const [showLoad,setShowLoad]=useState(false);

    // const [showVideoMessage, setShowVideoMessage]=useState(false);

    const userVideoConstraints = {
        facingMode: "user"
    }

    var showVideo=()=>{
        setDisplayEndMessage(true);
        setHide(true);
    }

    var onClickAccept=()=>{
        setMinimise(true);
        //stopRingtone();
        playRef.current.play();
        setStartCount(true);
    }

    var onClickDecline=()=>{
        setDisplayDeclineMessage(true);
        //stopRingtone();
        playRef.current.pause();
        setStartCount(false);
    }

    var onClickCancelDecline=()=>{
        if(!minimise){
            setMinimise(true);
        }
        setDisplayDeclineMessage(false);
        setStartCount(true);
        playRef.current.play();
    }

    var onClickEnd=()=>{
        setDisplayEndMessage(true);
        if(displayDeclineMessage){
            setDisplayDeclineMessage(false);
        }
        if(!hide){
            setHide(true);
            setMinimise(true);
        }
        setStartCount(false);
        playRef.current.pause();
    }

    // ringtone
    // function stopRingtone(){
    //     document.querySelector('.ringbgm').pause();
    //     setStopRing(true);
    // }

    useEffect(()=>{
        setTimeout(() => {
            setShowLoad(true);
        }, 32000);

        setTimeout(() => {
            setShowLoad(false);
        }, 34000);
    }, [startCount])

    if(isMobileOnly){
        return(
            <>
            {/* <iframe title="autoplay" src={silence} allow="autoplay" id="audio" style={{display:"none"}}></iframe> */}
            <DeviceOrientation lockOrientation={'portrait'}>
                <Orientation orientation='portrait' alwaysRender={true}>
                {/* <LowPowerMessage showMessage={showVideoMessage} setShowMessage={setShowVideoMessage}/> */}
                    <div className="launch-video-container animated fadeIn">
                        <img className="battery-icon" src={battery_icon}></img>
                        <div className="celluar">10G</div>
                        <div className="time">11:11</div>
            
                        {/* video */}
                        <img className="loader" src={loader} alt="Loading..." style={{display:showLoad?"":"none"}}></img>
                        <video ref={playRef} webkit-playsinline="true" playsInline={true} onEnded={()=>showVideo()} className="mr-tan-video" id="launch-vid" style={{filter: displayDeclineMessage? "blur(8px)" : displayEndMessage? "blur(8px)" : ""}}>
                            <source src={mr_tan_bg} ></source>
                            <track className="captions" src={captions} kind="captions" srclang="en" label="English" default></track>
                        </video>
            
                        <div className="control-panel" style={{filter: displayDeclineMessage? "blur(8px)" : displayEndMessage? "blur(8px)" : ""}}>
                            <img className="camera-icon" src={camera_icon}></img>
                            <img className="mute-icon" src={mute_call}></img>
                            <img className="flip-icon" src={flip_icon}></img>
                            <img className="end-btn" src={end_btn} onClick={()=>onClickDecline()}></img>
                        </div>
            
                        <Webcam className="user-camera" style={{animation: minimise? "topright 1s" : "", animationFillMode: minimise? "forwards" : "", display: hide? "none":""}} audio={false} mirrored={true} videoConstraints={userVideoConstraints}/>
            
                        <div className="calling-bg" style={{display: minimise? "none":""}}>
                            <div className="mr-tan-name">HH Tan</div>
                            <div className="call-message">wants to videocall...</div>
                            <img className="remind-icon" src={remind_btn}></img>
                            <img className="message-icon" src={message_btn}></img>
                            <img className="decline-btn" src={decline_btn} onClick={()=>onClickDecline()}></img>
                            <img className="accept-btn" src={minimise_video} onClick={()=>onClickAccept()}></img>
                            {/* <audio autoPlay={true} loop={true} className="ringbgm" controls={false} src={ringBGM}></audio> */}
                        </div>
            
                        <div className="decline-message" style={{display: displayDeclineMessage? "": "none"}}>
                            <div className="header">Leaving so soon?</div>
                            <div className="text">Do you want to leave this virtual<br/>experience with HH Tan?</div>
            
                            <div className="stay-btn" onClick={()=>onClickCancelDecline()}>Stay</div>
                            <div className="leave-btn" onClick={()=>onClickEnd()}>Leave</div>
                        </div>
            
                        <div className="end-message" style={{display: displayEndMessage? "": "none"}}>
                            <div className="end-text">Videocall with<br/>HH Tan Ended.</div>
                            <Link to="/">
                                <button className="visit-tour">Visit Virtual Tour</button>
                            </Link>
                            <br/>
                            {/* <button className="close-window">Close Experience</button> */}
                        </div>
                    </div>
                </Orientation>
            </DeviceOrientation>
            </>
        );
    } 
    else{
        return(
            <div className="landscape-check-bg position-absolute">
                <img className="landscape-message-logo" alt="logo" src={CKTL_logo_subheader}></img>
                <p className="message">
                    <img className="launch-qr-code" src={video_qr}/><br/>
                    For the best experience, <br/>please scan the QR code to view <br/>our virtual launch video on your phone.
                </p>
                <div className="visit-button-container">
                    <Link to="/">
                            <button className="visit-tour">Visit Virtual Tour</button>
                    </Link>
                </div>
            </div>
        );
    }
}