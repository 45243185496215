export const scifi_click =require("../static/sfx/arwes/click.mp3")

export const scifi_typing =require("../static/sfx/arwes/typing.mp3")

export const scifi_deploy =require("../static/sfx/arwes/deploy.mp3")

export const clock_ticking =require("../static/sfx/dementia/ticking.mp3")

export const glass_hit =require("../static/sfx/caregiver/glass_hit.mp3")


//super-hero
export const gabriel_how=require('../static/sfx/super_hero/gabriel_how.mp3')
export const gabriel_what=require('../static/sfx/super_hero/gabriel_what.mp3')
export const gabriel_why=require('../static/sfx/super_hero/gabriel_why.mp3')
export const Satv_how=require('../static/sfx/super_hero/Satv_how.mp3')
export const Satv_what=require('../static/sfx/super_hero/Satv_what.mp3')
export const Satv_why=require('../static/sfx/super_hero/Satv_why.mp3')
export const yz_how=require('../static/sfx/super_hero/yz_how.mp3')
export const yz_what=require('../static/sfx/super_hero/yz_what.mp3')
export const yz_why=require('../static/sfx/super_hero/yz_why.mp3')


//ME
export const ME_wind=require("../static/sfx/mt_everest/ME_wind.mp3")

// BGM
export const bgm=require("../static/music/bgm.mp3")




