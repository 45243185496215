import React,{useState,useEffect,useRef} from "react";

import {main_data} from "../store"
import {isMobileOnly,isMobile} from 'react-device-detect'

import {} from "../../utils/utility"
import {btn_white_cross,TCM_ear,TCM_ear_with_des,
TCM_acidreflux,TCM_acne,TCM_common,TCM_constipation,TCM_diabetes,TCM_diarrhoea,TCM_dryeyes,TCM_eczema,TCM_fatigue,TCM_generalpain,TCM_gsc,TCM_hbp,TCM_headache,TCM_insomnia,TCM_myopia,TCM_obesity,TCM_stomachache,TCM_withdrawal,
TCM_ear_how_01,TCM_ear_how_02,TCM_ear_how_03,TCM_ear_how_04,TCM_ear_diagram,transparent} from "../../utils/images"

import {ear_anim} from "../../utils/videos"

import TCMButton from "./overlay_content/TCM_button"

import TutorialCard from "./overlay_content/TCM_tutorial_card"

import ShareModal from "./share_modal"
import UsefulIcons from "./useful_icons"
import ControlHint from "./overlay_content/control_hint"

import {ROOT_URL,BASENAME} from "../../config"

import BackMainButton from "./overlay_content/back_main_button"


const buttonNames=["General Skin Conditions","Stomachache","Diabetes","Eczema","Fatigue","Myopia","Acne","Insomnia","Dry Eyes Syndrome","Constipation","High Blood Pressure","Common Cough","Diarrhoea","Withdrawal Syndrome","General Pain","Acid Reflux","Obesity","Headache"]

const highLights=[TCM_ear_with_des,TCM_gsc,TCM_stomachache,TCM_diabetes,TCM_eczema,TCM_fatigue,TCM_myopia,TCM_acne,TCM_insomnia,TCM_dryeyes,TCM_constipation,TCM_hbp,TCM_common,TCM_diarrhoea,TCM_withdrawal,TCM_generalpain,TCM_acidreflux,TCM_obesity,TCM_headache]


var buttons=[];

export default function TCM(props){
    const {display,setDisplay,isStandalone,openCallback,closeCallback}=props;
    const [state,setState]=useState(0);
    const [active,setActive]=useState(0);

    const [displayShareModal,setDisplayShareModal]=useState(false);
    const [displayHelp,setDisplayHelp]=useState(false);
    const [animation,setAnimation]=useState(transparent)


    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayHelp || displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
</div>)
 :   (<BackMainButton/>)

    useEffect(() => {

        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
        
    }, [display]);

    var onClose=()=>{
        setDisplayShareModal(false)
        setDisplayHelp(false)
        setDisplay(false);
        setState(0);
        setAnimation(transparent)
    }

    var handleContinue=()=>{
        setState(2);
    }

    var handleButtonClick=(clickedIndex)=>{
        
        setActive(clickedIndex)
        buttons=buttonNames.map((name,index)=>{
            return(<TCMButton key={index+1} setActive={()=>handleButtonClick(index+1)} active={clickedIndex} index={index+1} text={name}></TCMButton>)
        })
    }

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone5_Healthcare.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone5_Healthcare.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    useEffect(() => {

        if(display){
            setAnimation(ear_anim)
        }
        
        if(buttons.length===0 ){
            

            buttons=buttonNames.map((name,index)=>{
                return(<TCMButton key={index} setActive={()=>handleButtonClick(index)} active={active} index={index} text={name}></TCMButton>)
            })
            

            
        }
        
        return () => {
            
        };
    }, [display,state,active]);

    if (!display){
        return (<></>)
    }

    return (
    
    <div>
        {isMobileOnly ? (
            <div className="full-screen animated fadeIn TCM-container-mobile">
                <div className="full-screen-black-bg__half position-absolute "></div>

                <div style={{display: !displayHelp && state===0 ? "" : "none"}}  className="container h-100 animated fadeIn">
                        
                        <div className="row h-100 ">
                            <div  className="col-xl-5 col-6 d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="header overflow-hidden"><span className="border-bottom-insert">DIY Ear Acupuncture</span></h1>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <p className="paragraph">{`When administrating ear acupuncture, or auricular acupuncture, acupuncture needles or ear seeds made from semen vaccariae are used to stimulate specific acupoints on the ear.

                                        Try out our digital ear acupuncture!`}
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-auto">
                                        <div onClick={()=>setState(1)}  className="theme-btn theme-hover"><span>Let's Go</span></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 offset-xl-1  h-100 d-flex justify-content-end align-items-center">
                                <img  alt="ear anim" src={animation} className="w-100"></img>
                            
                                {/* <img src={TCM_ear} className="h-75">

                                </img> */}
                                
                            </div>

                            
                        </div>
            
                        
            </div>

            <div style={{display:!displayHelp && state===1 ? "" : "none"}} className="container-fluid animated fadeIn h-100">
                <div  className="row h-100 justify-content-center ">
                
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="header mt-3 overflow-hidden"><div className="border-bottom-insert text-center w-100">Which symptoms are you interested in?</div></h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="paragraph text-center">
                                    Place the ear seeds onto the corresponding locations shown in the diagram
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div onClick={()=>{setDisplayHelp(true)}} className="italic-link clickable font-weight-bold">How to use ear seeds</div>
                            </div>
                        </div>
                    </div>

                    <div  className="col-lg-5 col-6 d-flex flex-column justify-content-center align-items-center">
                        <div className="row mt-1">

                        {/* <TCMButton setActive={setActive} active={active} index={0} text={`General Skin Condition`}></TCMButton> */}
                            {buttons}
                        </div>
                        
                    </div>

                    <div className="col-lg-4 col-5 ml-3 ml-md-5 ear-wrapper d-flex flex-column justify-content-center align-items-center">
                            
                        <div className="row">
                            <div className="position-relative offset-1 col-10 d-flex jusify-content-center align-items-center">
                                <img style={{}}  className="w-100" alt="highlights" src={highLights[active]}></img>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row mt-2 d-flex flex-row">
                            <div className="row col-7 offset-3" style={{padding:"10px", borderTop:"1px solid #F1B148"}}>
                                <ControlHint className="col-2 mobile-hint"/>
                                <p className="col-11">Disclaimer: All research and clinical material published on this site is for informational purposes only. If you have a medical concern, seek professional medical advice and treatment immediately.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{display: displayHelp ? "flex" : "none"}}  className="container h-100 flex-column justify-content-center animated fadeIn">

                <div className="row">

                    <div className=" col-12">
                        <div className="header d-flex overflow-hidden">
                            <span className="border-bottom-insert text-center">How to use them?</span>
                        </div>
                    
                    </div>

                </div>

                <div className="row mt-3">
                    <div className="col-12 d-flex help-tutorial">
                        <TutorialCard delay={"0.5s"} title={`01`} text={`Clean and dry your external ear.`} image={TCM_ear_how_01} ></TutorialCard>
                        <TutorialCard  delay={"0.65s"} title={`02`} text={`Locate the correct acupuncture points according to your ailment.`} image={TCM_ear_how_02} ></TutorialCard>
                        <TutorialCard delay={"0.8s"} title={`03`} text={`Use tweezers to apply the ear seeds onto the acupuncture point.`} image={TCM_ear_how_03} ></TutorialCard>
                        <TutorialCard delay={"0.95s"} title={`04`} text={`Massage the ear seeds gently.`} image={TCM_ear_how_04} ></TutorialCard>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-center">
                        <button  onClick={()=>{setDisplayHelp(false)}} className="theme-btn theme-hover">back</button>
                    </div>
                </div>

                


                </div>

                <UsefulIcons displayQues={false} displayShare={!displayHelp && !displayShareModal} onClickQues={()=>{setDisplayHelp(true)}} onClickShare={()=>setDisplayShareModal(true)}></UsefulIcons>

                <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/TCM`} des={"Spread the word about TCM to your family and friends."}></ShareModal>
                
                {topRightBtn }
                {/* <div className="btn-top-right"  >
                        <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
                    </div> */}
            </div>
        ):(
            <div className="full-screen animated fadeIn TCM-container">
                <div className="full-screen-black-bg__half position-absolute "></div>

                <div style={{display: !displayHelp && state===0 ? "" : "none"}}  className="container h-100 animated fadeIn">
                        
                        <div className="row h-100 ">
                            <div  className="col-xl-5 col-6 d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="header overflow-hidden"><span className="border-bottom-insert">DIY Ear Acupuncture</span></h1>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <p className="paragraph">{`When administrating ear acupuncture, or auricular acupuncture, acupuncture needles or ear seeds made from semen vaccariae are used to stimulate specific acupoints on the ear.

                                        Try out our digital ear acupuncture!`}
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-auto">
                                        <div onClick={()=>setState(1)}  className="theme-btn theme-hover"><span>Let's Go</span></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 offset-xl-1  h-100 d-flex justify-content-end align-items-center">
                                <img  alt="ear anim" src={animation} className="w-100"></img>
                            
                                {/* <img src={TCM_ear} className="h-75">

                                </img> */}
                                
                            </div>

                            
                        </div>
            
                        
            </div>

            <div style={{display:!displayHelp && state===1 ? "" : "none"}} className="container-fluid animated fadeIn h-100">
                <div  className="row h-100 justify-content-center ">

                    <div  className="col-lg-5 col-6 d-flex flex-column justify-content-center align-items-center">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="header mt-3 overflow-hidden"><div className="border-bottom-insert text-center w-100">Which symptoms are you interested in?</div></h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="paragraph text-center">
                                    Place the ear seeds onto the corresponding locations shown in the diagram
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div onClick={()=>{setDisplayHelp(true)}} className="italic-link clickable font-weight-bold">How to use ear seeds</div>
                            </div>
                        </div>


                        <div className="row mt-1">

                        {/* <TCMButton setActive={setActive} active={active} index={0} text={`General Skin Condition`}></TCMButton> */}
                            {buttons}
                        </div>
                        
                    </div>

                    <div className="col-lg-4 col-5 ml-3 ml-md-5 ear-wrapper d-flex flex-column justify-content-center align-items-center">
                            
                        <div className="row">
                            <div style={{paddingBottom:"30px",borderBottom:"1px solid #F1B148"}} className="position-relative offset-1 col-10 d-flex jusify-content-center align-items-center">
                                <img style={{}}  className="w-100" alt="highlights" src={highLights[active]}></img>
                            </div>
                        </div>


                        <div className="row mt-3">
                            <div className="col-10 offset-1">
                                <ControlHint text="Disclaimer: All research and clinical material published on this site is for informational purposes only. If you have a medical concern, seek professional medical advice and treatment immediately."></ControlHint>
                            </div>
                        </div>

        
                        
                    </div>
                </div>
            </div>


            <div style={{display: displayHelp ? "flex" : "none"}}  className="container h-100 flex-column justify-content-center animated fadeIn">

            <div className="row">

                <div className=" col-12">
                    <div className="header d-flex overflow-hidden">
                        <span className="border-bottom-insert text-center">How to use them?</span>
                    </div>
                
                </div>

            </div>

            <div className="row mt-3">
                <div className="col-12 d-flex">
                    <TutorialCard delay={"0.5s"} title={`01`} text={`Clean and dry your external ear.`} image={TCM_ear_how_01} ></TutorialCard>
                    <TutorialCard  delay={"0.65s"} title={`02`} text={`Locate the correct acupuncture points according to your ailment.`} image={TCM_ear_how_02} ></TutorialCard>
                    <TutorialCard delay={"0.8s"} title={`03`} text={`Use tweezers to apply the ear seeds onto the acupuncture point.`} image={TCM_ear_how_03} ></TutorialCard>
                    <TutorialCard delay={"0.95s"} title={`04`} text={`Massage the ear seeds gently.`} image={TCM_ear_how_04} ></TutorialCard>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-12 d-flex justify-content-center">
                    <button  onClick={()=>{setDisplayHelp(false)}} className="theme-btn theme-hover">back</button>
                </div>
            </div>

            


            </div>

            <UsefulIcons displayQues={false} displayShare={!displayHelp && !displayShareModal} onClickQues={()=>{setDisplayHelp(true)}} onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>

            <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/TCM`} des={"Spread the word about TCM to your family and friends."}></ShareModal>
            
            {topRightBtn }
            {/* <div className="btn-top-right"  >
                    <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
                </div> */}
            </div>
        )}
    </div>
    ) 
}