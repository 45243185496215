import React,{useState,useEffect,useRef} from "react";

import {main_data} from "../store"
import {btn_white_cross, hero_group_mobile, btn_black_cross} from "../../utils/images"
import {gabriel_how,gabriel_what,gabriel_why,Satv_how,Satv_what,Satv_why,yz_how,yz_what,yz_why} from "../../utils/sounds"
import {isMobileOnly,isMobile} from "react-device-detect"

import UsefulIcons from "./useful_icons"
import ShareModal from "./share_modal"
import {ROOT_URL} from "../../config"
import BackMainButton from "./overlay_content/back_main_button"

import $ from 'jquery'

import ControlHint from "./overlay_content/control_hint"
import AudioButtons from "./overlay_content/audio_buttons"

const data=main_data.super_hero;

const audioArr=[gabriel_what,gabriel_how,gabriel_why,yz_what,yz_how,yz_why,Satv_what,Satv_how,Satv_why]

const audioIndexes=[[0,1,2],[3,4,5],[6,7,8]]

const audioBtnPos=[["10%","5%","10%","58%","65%","35%"],["50%","-1%","45%","54%","0%","10%"],["70%","3%","7%","15%","10%","65%"]]
const audioBtnPosMobile=[["65%","5%","60%","58%","10%","35%"]]

export default function SuperHero(props) {

    const {display,setDisplay,openCallback,closeCallback,isStandalone}=props;
    const [displayShareModal, setDisplayShareModal] = useState(false);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>):(<BackMainButton display={true}/>);

    const [displayDetail, setDisplayDetail] = useState(false);
    const [displayDetailMobile, setDisplayDetailMobile] = useState(false);
    const [currentDetail,setCurrentDetail]= useState(0);

    const [audioIndexArr,setAudioIndexArr]=useState(audioIndexes)

    const [isHovering, setIsHovering] = useState(0);

    //mobile version variables
    const [topCardIndex,setTopCardIndex]=useState(0);
    const topCardIndexRef=useRef({});
    topCardIndexRef.current=topCardIndex;

    useEffect(() => {
        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
        return () => {
            
        };
    }, [display]);

    var onClose=()=>{
        setIsHovering(false);
        setDisplayDetail(false);
        setDisplay(false);
        //mobile variables
        setDisplayDetailMobile(false);
        setCurrentDetail(0);
        setTopCardIndex(0);
    }

    var showDetail=(index)=>{
        
        $('.student-detail-wrapper').removeClass('fadeOut').addClass('fadeIn');
        setDisplayDetail(true);
        setDisplayDetailMobile(false);
        setCurrentDetail(index);
        setTopCardIndex(index+1);
    }

    var showMobileDetail=(index)=>{
        //$('.photo-wrapper').addClass('fadeIn').addClass('animated');
        setDisplayDetailMobile(true);
        setCurrentDetail(index);
        setTopCardIndex(index+1);
    }

    var hideDetail=()=>{
        if(displayDetail){
            setDisplayDetail(false);
            var audioPlayer=$('#audio')[0];
            audioPlayer.pause();
            setTopCardIndex(0);
            setCurrentDetail(0);
        }
    }

    var hideDetailMobile=()=>{
        if(displayDetailMobile){
            var audioPlayer=$('#audio')[0];
            audioPlayer.pause();
            setDisplayDetailMobile(false);
            setDisplayDetail(true);
        }
    }

    var playSpeech=(index)=>{
        var audioPlayer=$('#audio')[0];
        audioPlayer.src=audioArr[index];
        audioPlayer.currentTime=0;
        audioPlayer.play();
    }

    useEffect(() => {

        return () => {
            
        };
    }, [display]);

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone2_Legaled.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone2_Legaled.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    if (!display){
        return (<></>)
    }

    return (
    <div>
        {isMobileOnly ? (
            <div className="superhero-mobile">
                <div  className="full-screen-black-bg__half position-absolute z-index-0">     
                </div>

                <div className="z-index-2 container super-hero-container-mobile h-100 ">
                    <div className="row h-100" style={{display: displayDetailMobile ? "none" : 'flex'}}>
                        <div className="col-6 row mobile-row">
                            
                            <div style={{display:displayDetail ? "none" : "flex"}} className="col align-self-center animated fadeIn  flex-column justify-content-center">
                                <div className="row">
                                    <div className='col-12'>
                                        <h1 className="header overflow-hidden"><span className="border-bottom-insert">{data.header}</span></h1>
                                    </div>
                                </div>

                                <ControlHint classes="refreshable" text={`Tap on arrow keys to find out who they are`} isAbsolute={false}></ControlHint>

                                <div className="row  ">
                                    <div className="col-12">
                                        <p className="paragraph">
                                        Meet (from right) Gabriel, Yi Zheng and Satviender – aspiring lawyers who are volunteering their skills and knowledge to help others in need. They have no intention to stop volunteering even after they graduate from the School of Law at the Singapore University of Social Sciences (SUSS).
                                        <br/><br/>
                                        Everyone has their own superpowers.
                                        <br/>
                                        Yes, you too! Put your skills into good use and help those in need, just like our superheroes!
                                        </p>
                                    </div>
                                    <div className="justify-content-right col-12 d-flex flex-row" style={{marginTop:"2%"}}>
                                        <button className="theme-btn-auto theme-hover" disabled><i className="btn-arrow-disabled left"/></button>
                                        <button onClick={()=>showDetail(currentDetail)}  className="theme-btn-auto theme-hover" style={{marginLeft:"4%"}}><i className="btn-arrow right"/></button>
                                    </div>
                                </div>
                            </div>

                            <div style={{display:displayDetail ? "flex" : "none"}} className="animated fadeIn flex-column">
                                <div className="col" style={{marginTop: '5vh', marginBottom: '-40vh'}}>
                                    <ControlHint isAbsolute={false} classes="mt-1" text="Tap on the image to find out more"></ControlHint>
                                </div> 
                                <div className="col justify-content-center align-self-center">
                                    <div className="row ">
                                        <div className='col-12'>
                                            <h1 className="header b"><span>{data.detail[currentDetail].name}</span></h1>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-12 ">
                                            <div className=" overflow-hidden border-left-insert">
                                                <span style={{animationDelay:"0.2s"}}  className="paragraph">{data.detail[currentDetail].intro}</span>
                                            </div>
                                        </div>

                                        {currentDetail == 0 ? 
                                        (
                                            <div className="justify-content-right col-12 d-flex flex-row" style={{marginTop:"4%"}}>
                                                <button onClick={()=>hideDetail()}  className="theme-btn-auto"><i className="btn-arrow left"/></button>
                                                <button onClick={()=>showDetail(currentDetail+1)}  className="theme-btn-auto" style={{marginLeft:"4%"}}><i className="btn-arrow right"/></button>
                                            </div>
                                        ):(
                                            <div className="justify-content-right col-12 d-flex flex-row" style={{marginTop:"4%"}}>

                                            <button onClick={()=>showDetail(currentDetail-1)}  className="theme-btn-auto"><i className="btn-arrow left"/></button>
                                                {currentDetail < 2 ? (
                                                    <button onClick={()=>showDetail(currentDetail+1)}  className="theme-btn-auto" style={{marginLeft:"4%"}}><i className="btn-arrow right"/></button>
                                                ):(
                                                    <button className="theme-btn-auto" style={{marginLeft:"4%"}} disabled><i className="btn-arrow-disabled right"/></button>
                                                )}
                                            </div>
                                        )}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-5 d-flex flex-column justify-content-center">
                            
                            <div className="row flex-nowrap d-flex justify-content-around">
                                <div style={{display:displayDetail ? "none" : "flex"}} className="student-card-mobile col-auto">
                                    <img className="student-group-img" alt="student group" src={hero_group_mobile}/>
                                </div>

                                <div style={{display: displayDetail ? "flex" : 'none'}} className="student-detail-wrapper animated fadeIn">
                                    
                                    <div className="photo-wrapper">
                                        <img className="hero-photo" onClick={()=>showMobileDetail(currentDetail)}  alt="student detail" src={data.detail[currentDetail].animation_mobile}/>
                                        {/* <button onClick={()=>showMobileDetail(currentDetail)}  className="theme-btn-auto theme-hover btn-back">Find Out More</button> */}
                                    </div>
                                </div>
                            </div>

                            <div style={{display:displayDetail ? "flex" : "none"}} className="indicator-wrapper">
                                <div  onClick={()=>showDetail(0)} className={`mt-2 indicator ${topCardIndex===1 ? "active" : ""}`}></div>
                                <div onClick={()=>showDetail(1)} className={`mt-2 indicator ${topCardIndex===2 ? "active" : ""}`}></div>
                                <div onClick={()=>showDetail(2)}  className={`mt-2 indicator ${topCardIndex===3 ? "active" : ""}`}></div>
                            </div>
                            
                        </div>
                    </div>

                    <div className="row h-100" style={{display: displayDetailMobile ? "flex" : 'none'}} >
                        <div className="col-12">    
                            <div className="row flex-nowrap d-flex justify-content-around">
                                <div className="student-detail-wrapper animated fadeIn">
                                    <ControlHint isAbsolute={false} classes="mt-1 col-4 hint-wrapper" text="Tap on buttons to listen"></ControlHint> 
                                    <div className="photo-wrapper-mobile-audio col-8 d-flex flex-column justify-content-center">
                                        <img className="hero-photo" alt="student detail" src={data.detail[currentDetail].img_mobile}/>
                                        <button onClick={()=>hideDetailMobile()}  className="theme-btn-auto theme-hover btn-back">Close</button>
                                    </div>

                                    <AudioButtons positions={audioBtnPosMobile[0]} playSpeech={playSpeech} indexes={audioIndexArr[currentDetail]}></AudioButtons>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div style={{display:displayDetailMobile?"none":"flex"}}>
                    {topRightBtn}
                </div>

                <audio id="audio" controls={false} >    
                </audio>
                <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
                <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/superhero`} des={"Share the exhibit with your friends!"}></ShareModal>
            </div>
        ):(
            <div className="full-screen animated fadeIn">
                <div  className="full-screen-black-bg__half position-absolute z-index-0">     
                </div>

                <div   className="z-index-2 container super-hero-container h-100 ">
                    <div className="row h-100">
                        <div className="col-4 row ">
                            
                            <div style={{display:displayDetail ? "none" : "flex"}} className="col align-self-center animated fadeIn  flex-column justify-content-center">
                                <div className="row">
                                    <div className='col-12'>
                                        <h1 className="header overflow-hidden"><span className="border-bottom-insert">{data.header}</span></h1>
                                    </div>
                                </div>

                                <div className="row  ">
                                    <div className="col-12">
                                        <p className="paragraph">{data.content}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div style={{display:displayDetail ? "flex" : "none"}} className="col align-self-center animated fadeIn flex-column justify-content-center">
                                <div className="row ">
                                    <div className='col-12'>
                                        <h1 className="header b"><span>{data.detail[currentDetail].name}</span></h1>
                                    </div>
                                </div>

                                <div className="row ">
                                    <div className="col-12 ">
                                        <div className=" overflow-hidden border-left-insert">
                                            <span style={{animationDelay:"0.2s"}}  className="paragraph">{data.detail[currentDetail].intro}</span>
                                        </div>
                                        
                                    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-8 d-flex flex-column justify-content-center">
                            
                            <div className="row flex-nowrap d-flex justify-content-around">
                                <div onMouseLeave={()=>{setIsHovering(0)}} onMouseEnter={()=>{setIsHovering(1)}} onClick={()=>showDetail(0)} className={`col-auto clickable student-card ${displayDetail ? "darken unclickable" : ""}` }>
                                    <img className={`student-img ${isHovering===1 ? "animated fadeOut" : "animated fadeIn"}`} alt="student" src={data.detail[0].img_grey}></img>
                                    <img className={` student-animation ${isHovering===1 ? "animated fadeIn" : "animated fadeOut"}`} alt="student" src={data.detail[0].animation}></img>
                                </div>
                                <div onMouseLeave={()=>{setIsHovering(0)}} onMouseEnter={()=>{setIsHovering(2)}} onClick={()=>showDetail(1)} className={`col-auto clickable student-card ${displayDetail ? "darken unclickable" : ""}` }>
                                    <img className={`student-img ${isHovering===2 ? "animated fadeOut" : "animated fadeIn"}`} alt="student" src={data.detail[1].img_grey}></img>
                                    <img className={` student-animation ${isHovering===2 ? "animated fadeIn" : "animated fadeOut"}`} alt="student" src={data.detail[1].animation}></img>
                                </div>
                                <div onMouseLeave={()=>{setIsHovering(0)}} onMouseEnter={()=>{setIsHovering(3)}} onClick={()=>showDetail(2)} className={`col-auto clickable student-card ${displayDetail ? "darken unclickable" : ""}` }>
                                <img className={`student-img ${isHovering===3 ? "animated fadeOut" : "animated fadeIn"}`} alt="student" src={data.detail[2].img_grey}></img>
                                    <img className={` student-animation ${isHovering===3 ? "animated fadeIn" : "animated fadeOut"}`} alt="student" src={data.detail[2].animation}></img>
                                </div>

                                <div  style={{display: displayDetail ? "flex" : 'none'}} className="student-detail-wrapper animated fadeIn">
                                    
                                    <div className="photo-wrapper">
                                        <img  alt="student_detail" src={data.detail[currentDetail].img} className="hero-photo"></img>
                                        <button onClick={()=>hideDetail()}  className="theme-btn-auto theme-hover btn-back">Close</button>
                                    </div>
                                    

                                    <AudioButtons positions={audioBtnPos[currentDetail]} playSpeech={playSpeech} indexes={audioIndexArr[currentDetail]}></AudioButtons>

                                    <ControlHint isAbsolute={false} classes="mt-2"    text="Click on the icons to listen to them"></ControlHint>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div style={{display:displayDetail?"none":""}}  >
                    {topRightBtn}
                </div>

                <audio id="audio" controls={false} >    
                </audio>

                <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
                <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/superhero`} des={"Share the exhibit with your friends!"}></ShareModal>
            </div>
        )}
    </div>
    )
}
