import React from "react";

export default function FoodSection(props){

    const {food_name,food_des}=props

    return (<div className="food-section-wrapper">
        <h1 className="header pre-line">{food_name}</h1>
        <div className="small-text border-left-animation"><span style={{animationDelay:"0.4s"}}>{food_des}</span></div>

    </div>)
}