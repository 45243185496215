var MATTERPORT_SDK_KEY;
var API_URL_FEEDBACK;
var API_URL_SESSION_INFO;
var API_URL_CARING_SCALE;
var API_URL_ARTWORK;
var ZONE_1;
var ZONE_2;
var ZONE_3;
var ZONE_4;
var ZONE_5;
var DOMAIN;
var BASENAME;
var ROOT_URL;

if (process.env.NODE_ENV === 'development') {
    API_URL_FEEDBACK=process.env.REACT_APP_DEV_API_URL_FEEDBACK;
    API_URL_SESSION_INFO=process.env.REACT_APP_DEV_API_URL_SESSION_INFO;
    API_URL_CARING_SCALE=process.env.REACT_APP_DEV_API_URL_CARING_SCALE;
    API_URL_ARTWORK=process.env.REACT_APP_PROD_API_URL_ARTWORK;

    ZONE_1=process.env.REACT_APP_PDF_1;
    ZONE_2=process.env.REACT_APP_PDF_2;
    ZONE_3=process.env.REACT_APP_PDF_3;
    ZONE_4=process.env.REACT_APP_PDF_4;
    ZONE_5=process.env.REACT_APP_PDF_5;

    MATTERPORT_SDK_KEY=process.env.REACT_APP_DEV_MATTERPORT_SDK_KEY;
    
    DOMAIN=window.location.host;
    BASENAME=process.env.REACT_APP_DEV_BASENAME;
       
}

else{

    API_URL_FEEDBACK=process.env.REACT_APP_PROD_API_URL_FEEDBACK;
    API_URL_SESSION_INFO=process.env.REACT_APP_PROD_API_URL_SESSION_INFO;
    API_URL_CARING_SCALE=process.env.REACT_APP_PROD_API_URL_CARING_SCALE;
    API_URL_ARTWORK=process.env.REACT_APP_PROD_API_URL_ARTWORK;
    ZONE_1=process.env.REACT_APP_PDF_1;
    ZONE_2=process.env.REACT_APP_PDF_2;
    ZONE_3=process.env.REACT_APP_PDF_3;
    ZONE_4=process.env.REACT_APP_PDF_4;
    ZONE_5=process.env.REACT_APP_PDF_5;
    MATTERPORT_SDK_KEY=process.env.REACT_APP_PROD_MATTERPORT_SDK_KEY;
    DOMAIN=process.env.REACT_APP_PROD_DOMAIN;
    BASENAME=process.env.REACT_APP_PROD_BASENAME;
    
}
ROOT_URL=DOMAIN+BASENAME+"";

export {API_URL_ARTWORK,API_URL_FEEDBACK,API_URL_SESSION_INFO,MATTERPORT_SDK_KEY,DOMAIN,BASENAME,ROOT_URL,API_URL_CARING_SCALE,ZONE_1,ZONE_2,ZONE_3,ZONE_4,ZONE_5}




window.DOMAIN=window.location.host  //window.location.hostname