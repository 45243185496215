import React,{useState} from "react";

import FoodSection from "./character_card/food_section";
import DetailSection from "./character_card/detail_section";

import QuoteBox from "./character_card/quote_box";


export default function CharacterCard(props){
    const {data,cardType} = props;

   
    
    const {back_image,front_image,name,intro,food_name,food_des,quote,year,occupation}=data;
    
    const [active,setActive]=useState(false)

    var left,right,top,bottom;


    if (cardType===0){
        left="15px";
        top="20%"
    }

    else{
        bottom="20px";
        right="15px";
    }

    

    

   

    return (
    
    <div   onClick={()=>setActive(!active)} className={`col-auto  flip-card ${active ? "active" : "inactive"}`}>
        <div className="flip-card-inner">
            <div className={`flip-card-front`}>
            
                <div style={{height:'90%'}} className={`position-relative d-flex flex-column ${cardType===0 ? "justify-content-end" : "justify-content-start"}`}>
                    <img src={front_image} className={`type__${cardType}`} alt="photo"></img>
                    <FoodSection food_name={food_name} food_des={food_des}></FoodSection>
                </div>

                <div className="position-relative bottom-bar" style={{height:'10%'}}>
                    <span>Who am I</span>
                </div>
                
                
            </div>

            <div className={`flip-card-back d-flex flex-column ${cardType===0 ? "justify-content-end" : "justify-content-start"}`}>
                <img src={back_image} className={`type__${cardType}`} alt="photo"></img>
                <DetailSection active={active} name={name} year={year} occupation={occupation} intro={intro}></DetailSection>

                {/* <QuoteBox left={left} right={right} top={top} bottom={bottom} text={quote}></QuoteBox> */}
            </div>
        </div>
    </div>
    )
}


