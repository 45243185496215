import React,{useState,useEffect,useRef} from "react";

import {main_data} from "../store"
import {btn_white_cross,btn_black_cross} from "../../utils/images"
// import userEvent from "@testing-library/user-event";
import $ from 'jquery'
import {detectLeftButton,setStateWithDelay,navigateFunctionSet} from '../../utils/utility';

import UsefulIcons from "./useful_icons"
import ShareModal from "./share_modal"
import {ROOT_URL} from "../../config"
import BackMainButton from "./overlay_content/back_main_button"

import ControlHint from "./overlay_content/control_hint"
import { isMobileOnly,isMobile } from "react-device-detect";


const data=main_data.story_cards
// const card_images=[story_card_02,story_card_01,story_card_02,story_card_01,story_card_02]


export default function StoryCards(props){

    const {display,setDisplay,sdk,setDisplayFourCards,openCallback,closeCallback,isStandalone}=props;
    const [displayShareModal, setDisplayShareModal] = useState(false);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={display?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>):(<BackMainButton display={true}/>);

    const [topCardIndex,setTopCardIndex]=useState(0)
    const topCardIndexRef=useRef({});
    topCardIndexRef.current=topCardIndex;

    const [cardPos,setCardPos]=useState({0:[0,0],
        1:[0,0],2:[0,0],3:[0,0]});

    const cardPosRef=useRef({});
    cardPosRef.current=cardPos;
    
    // const [cardActive,setCardActive]=useState(0);
    const [cardActive,setCardActive]=useState(0);
    const cardActiveRef=useRef({});
    cardActiveRef.current=cardActive;

    const [dragTarget,setDragTarget]=useState(undefined);
    const dragTargetRef=useRef({});
    dragTargetRef.current=dragTarget;

    const [targetIdState,setTargetIdState]=useState(undefined)
    const [originXState,setOriginXState]=useState(0)
    const [originYState,setOriginYState]=useState(0)

    var onClose=()=>{
        setDisplay(false);
        
    }

    var onContinue=()=>{
        onClose()
        navigateFunctionSet(sdk,"EUDkLgBzTcE")
        
        setStateWithDelay(setDisplayFourCards,true,1500)
    }
    var originX;
    var originY;
    var target;
    var targetId;

    function refreshText(){
        $('.text-wrapper .refreshable').css('display','none')
        setTimeout(()=>{$('.text-wrapper .refreshable').css('display','block');},100)
    }

    var handleIndexClick=(index)=>{
        const newCardPos = {...cardPosRef.current};
        for (var i=0;i<4;i++){
            if (i<index){
                
                newCardPos[i] = [-1000,-2000];
            }
            else{
                newCardPos[i] = [0,0];
            }
        }
        $('.card').addClass('transition-transform')
        setCardPos(newCardPos);
        handleCardActiveUpdate(index);
        setTopCardIndex(index)
        refreshText();
    }


    function onTouchStartCard(e){
        
        var touch=e.touches[0];

        
        setDragTarget($(touch.target));
        $(touch.target).removeClass('transition-transform')
        var targetId=parseInt($(touch.target).attr('draggable-id'));
        setTargetIdState(targetId)
        
        
        if (cardActive===targetId){
            
            setOriginXState(touch.clientX)
            setOriginYState(touch.clientY)
            // console.log(touch.clientX)
            
            
            
        }
    }

    function onTouchMoveCard(e){
        var touch=e.touches[0];

        
       
        
        if(dragTarget!==undefined){
            
            if (cardActive===targetIdState){
               
                
                
                   
                var newX = Math.floor(touch.clientX-originXState);
                var newY = Math.floor(touch.clientY-originYState);

                
                
                
                
                handleCardPosUpdate(targetIdState,[newX,newY])
                
                // $(e.target).css({'transform' : 'translate(' + newX +'px, ' + newY + 'px)'})
                    
               
            }
        }
    }

    function onTouchEndCard(e){
        var touch=e.changedTouches[0];
        // console.log(changedTouches)

        dragTarget.addClass('transition-transform');
            if(Math.abs(touch.clientX-originXState)<200 && Math.abs(touch.clientY-originYState)<200 || topCardIndex===3){
                
                
                handleCardPosUpdate(targetIdState,[0,0]);
                
                
            }
            else{
                
                handleCardPosUpdate(targetIdState,[-1000,-2000]);
                handleCardActiveUpdate(cardActive+1)
                setTopCardIndex(topCardIndex+1)

                
                refreshText();
                
                

            }

            setOriginXState(0)
            setOriginYState(0)
            
            setTargetIdState(undefined)
            setDragTarget(undefined)
            
    }


    

    useEffect(() => {

        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
        
        
        return () => {
            
        };
    }, [display]);

    useEffect(() => {
       
        
        
        $(".card").mousedown((e)=>{

            
            
            setDragTarget($(e.target));
            $(e.target).removeClass('transition-transform')
            targetId=parseInt($(e.target).attr('draggable-id'));
            
            if (cardActiveRef.current===targetId){
                
                originX=e.clientX;
                originY=e.clientY;
                
                
                
            }
        })


        $('.card').mousemove((e)=>{

            if(dragTargetRef.current!==undefined){
                if (cardActiveRef.current===targetId){
                    var leftBut =  detectLeftButton(e)
                    
                    if (leftBut == 1){
                       
                        var newX = Math.floor(e.clientX-originX);
                        var newY = Math.floor(e.clientY-originY);
                       
                        
                        handleCardPosUpdate(targetId,[newX,newY])
                        
                        // $(e.target).css({'transform' : 'translate(' + newX +'px, ' + newY + 'px)'})
                        
                   }
                }
            }
            })

        $('.card').mouseup((e)=>{
            
            

            
            dragTargetRef.current.addClass('transition-transform');
            if(Math.abs(e.clientX-originX)<200 && Math.abs(e.clientY-originY)<200 || topCardIndexRef.current===3){
                
                
                handleCardPosUpdate(targetId,[0,0]);
                
                
            }
            else{
                
                handleCardPosUpdate(targetId,[-1000,-2000]);
                handleCardActiveUpdate(cardActiveRef.current+1)
                setTopCardIndex(topCardIndexRef.current+1)

                
                refreshText();
                
                

            }

            originX=0;
            originY=0;
            target=undefined;
            targetId=undefined;
            setDragTarget(undefined)
            
        })

        


        return () => {
            $('.cards').off("mouseup").off("mousedown").off("mousemove");
        };
    }, [display]);


    const handleCardPosUpdate = (index, pos) => {
        
        const newCardPos = {...cardPosRef.current};
        newCardPos[index] = pos;
        
        setCardPos(newCardPos);
        
        
      }

    const handleCardActiveUpdate= (newActive) => {
        
        setCardActive(newActive);
        
        
      }

      const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone1_Intro.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone1_Intro.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    if (display===false ){
        return(<></>)
    }

    return (
    <div className="full-screen animated fadeIn">
        <div className="full-screen-black-bg__half position-absolute z-index-0 " />
        <div className="z-index-2 container story-cards-container h-100 d-flex ">
            
            <div className="row w-100 h-100">
                <div className="col-5 text-wrapper animated fadeIn d-flex flex-column justify-content-center">
                    <div className="row mt-2 refreshable animated fadeIn">
                        <div className="col-12">
                            <h1 className="header border-bottom-insert"><span className="pre-line">{data[topCardIndex].header}</span></h1>
                        </div>
                    </div>

                    <div className="row animated fadeIn">
                        
                        <div className="col-auto">
                         {isMobileOnly? (
                            <ControlHint classes="refreshable" text={`Tap on the images to uncover the story`} isAbsolute={false}></ControlHint>
                         ):(
                            <ControlHint classes="refreshable" text={`Drag the images to uncover the story`} isAbsolute={false}></ControlHint>
                         )}
                        </div>
                        
                    </div>

                    <div className="row mt-2 refreshable animated fadeIn">
                        <div className="col-12">
                            <div style={{overflow:"hidden",textAlign:"start"}} className="paragraph  position-relative">
                                <span className="pre-line">{data[topCardIndex].text}</span>
                            </div>
                        </div>
                    </div>

                    <div style={{display:topCardIndex===3 ? "" : "none",animationDelay:"1s"}} className="row animated fadeIn mt-3">
                        <div className="col-auto">
                        <button onClick={()=>{onContinue()}}  className=" theme-btn-auto theme-hover ">Who are they?</button>
                        </div>
                    </div>
                    
                </div>
                <div className="col-6 offset-1 d-flex flex-column justify-content-center">
                    
                    <div className="row " >
                        <div className="offset-sm-1 col-11 ">
                            {isMobileOnly? (
                                <div className="cards-wrapper">
                                
                                    <div onClick={()=>handleIndexClick(1)} className="card" style={{transform:`translate(${cardPosRef.current[0][0]}px,${cardPosRef.current[0][1]}px)`}} ><img alt="image" src={data[0].image} /></div>
                                    <div onClick={()=>handleIndexClick(2)} className="card" style={{transform:`translate(${cardPosRef.current[1][0]}px,${cardPosRef.current[1][1]}px)`}} ><img alt="image" src={data[1].image} /></div>
                                    <div onClick={()=>handleIndexClick(3)} className="card" style={{transform:`translate(${cardPosRef.current[2][0]}px,${cardPosRef.current[2][1]}px)`}} ><img alt="image" src={data[2].image} /></div>
                                    <div onClick={()=>handleIndexClick(0)} className="card" style={{transform:`translate(${cardPosRef.current[3][0]}px,${cardPosRef.current[3][1]}px)`}} ><img alt="image" src={data[3].image} /></div>
                                </div>
                            ):(
                                <div className="cards-wrapper">
                                
                                    <div onTouchEnd={(e)=>{onTouchEndCard(e)}} onTouchMove={(e)=>onTouchMoveCard(e)} onTouchStart={(e)=>onTouchStartCard(e)} draggable-id="0" className="card" style={{transform:`translate(${cardPosRef.current[0][0]}px,${cardPosRef.current[0][1]}px)`}} ><img alt="image" src={data[0].image} /></div>
                                    <div onTouchEnd={(e)=>{onTouchEndCard(e)}} onTouchMove={(e)=>onTouchMoveCard(e)} onTouchStart={(e)=>onTouchStartCard(e)} draggable-id="1" className="card" style={{transform:`translate(${cardPosRef.current[1][0]}px,${cardPosRef.current[1][1]}px)`}} ><img alt="image" src={data[1].image} /></div>
                                    <div onTouchEnd={(e)=>{onTouchEndCard(e)}} onTouchMove={(e)=>onTouchMoveCard(e)} onTouchStart={(e)=>onTouchStartCard(e)} draggable-id="2" className="card" style={{transform:`translate(${cardPosRef.current[2][0]}px,${cardPosRef.current[2][1]}px)`}} ><img alt="image" src={data[2].image} /></div>
                                    {/* <div draggable-id="3" className="card" style={{transform:`translate(${cardPosRef.current[3][0]}px,${cardPosRef.current[3][1]}px)`}} ><img alt="image" src={card_images[3]} /></div> */}
                                    <div  className="card" style={{transform:`translate(${cardPosRef.current[3][0]}px,${cardPosRef.current[3][1]}px)`}} ><img alt="image" src={data[3].image} /></div>
                                </div>
                            )}
                        
                        </div>
                        
                    </div>

                    
                </div>
            </div>

            <div className="indicator-wrapper">
                <div onClick={()=>handleIndexClick(0)} className={`indicator ${topCardIndex===0 ? "active" : ""}`}></div>
                <div onClick={()=>handleIndexClick(1)} className={`mt-2 indicator ${topCardIndex===1 ? "active" : ""}`}></div>
                <div onClick={()=>handleIndexClick(2)} className={`mt-2 indicator ${topCardIndex===2 ? "active" : ""}`}></div>
                <div onClick={()=>handleIndexClick(3)}  className={`mt-2 indicator ${topCardIndex===3 ? "active" : ""}`}></div>
                
            </div>

            {/* <div className="btn-top-right">
                    <a onClick={()=>onClose()}><img src={btn_white_cross} alt="white cross"></img></a>
                 </div> */}
        {topRightBtn}
            
        </div>
        <UsefulIcons displayQues={false} displayShare={!displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
        <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/what`} des={"Share the exhibit with your friends!"}></ShareModal>
    </div>)
}