import React from "react"
import {Link} from "react-router-dom";

export default function backMainButton(props){

    const {display}=props;

    return (<Link to={""}><button style={{display:display===undefined || display ===true?"" :"none",zIndex:"20"}}  className="animated fadeIn top-right btn btn-orange">
     
    Explore More
    
</button></Link>)
}