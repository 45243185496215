import React,{useState,useEffect,useRef} from "react";

import UsefulIcons from "./useful_icons"

import {btn_black_cross,btn_white_cross} from "../../utils/images"
import {library_fly_through} from "../../utils/videos"

import ShareModal from "./share_modal"
import BackMainButton from "./overlay_content/back_main_button"
import {ROOT_URL} from "../../config"
import {isMobileOnly, isMobile} from 'react-device-detect'


export default function Library(props){
    const {display,setDisplay,isStandalone,openCallback,closeCallback}=props;
    const [vidEnd,setVidEnd]=useState(false);
    const [displayShareModal, setDisplayShareModal] = useState(false);

    var topRightBtn= isStandalone===undefined || isStandalone===false ? (<div style={{display:displayShareModal?"none":""}} className="btn-top-right"  >
    <a onClick={()=>onClose()}><img src={vidEnd?btn_white_cross:btn_black_cross} alt="black cross"></img></a>
</div>)
:   (<BackMainButton display={vidEnd}/>);

    useEffect(() => {

        if(!isStandalone){
            if(display){
                openCallback();
            }
            else{
                closeCallback();
            }
        }
        
        return () => {
            
        };
    }, [display]);


    var onClose=()=>{
        
        setDisplay(false);
        setVidEnd(false);

    }

    var restart=()=>{
        setVidEnd(false);
        var vid=document.querySelector('.library-model');
        vid.currentTime=0;
        vid.play();
    }

    var restartMobile=()=>{
        setVidEnd(false);
        var vid=document.querySelector('.library-model-mobile');
        vid.currentTime=0;
        vid.play();
    }

    const handleReadMore=()=>{
        if(isMobile){
            const newWindow = window.open("https://tcktl.sg/pdf/Zone2_Legaled.png", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open("https://tcktl.sg/pdf/Zone2_Legaled.pdf", '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }
   
    if(!display){
        return <></>;
    }

    return (
    
    <div className="full-screen animated fadeIn library-container">

        {isMobileOnly? (
            <div className="video-wrapper-mobile position-relative">
                <video onEnded={()=>{setVidEnd(true)}} controls={false} webkit-playsinline="true" playsInline={true} autoPlay muted className="library-model-mobile">
                    <source src={library_fly_through}></source>
                </video>

                <div style={{display:vidEnd? "" :"none",top:'0',left:'0'}} className="full-screen-black-bg__half position-absolute w-100 h-100 animated fadeIn"></div>

            </div>
        ):(
            <div className="video-wrapper position-relative">
                <video onEnded={()=>{setVidEnd(true)}} controls={false} autoPlay muted className="library-model">
                    <source src={library_fly_through}></source>
                </video>

                <div style={{display:vidEnd? "" :"none",top:'0',left:'0'}} className="full-screen-black-bg__half position-absolute w-100 h-100 animated fadeIn"></div>

            </div>
        )}
        
        
        

        <div style={{display:vidEnd? "" :"none"}} className="intro-container  container-fluid animated fadeIn">
            <div className="row w-100 justify-content-center">
                <div className=" col-lg-5 col-md-7 col-10">
                    <div className="w-100 header border-bottom-insert text-center">
                        <span>CJ Koh Law Library</span>
                    </div>
                </div>
            </div>

            <div className="row mt-2 w-100 justify-content-center">
                <div className="col-lg-5 col-md-7 col-10">
                    <div className="paragraph pre-line">
                        <span>{`The CJ Koh Law Library at the National University of Singapore (NUS) is a rich and vital repository of knowledge for the students, faculty, and researchers of the NUS Faculty of Law.

Legal education was something that CJ held close to his heart, for he was a dedicated and brilliant lawyer and magistrate who loved the discipline.
`}</span>
                    </div>
                </div>
            </div>

            <div className="row mt-4 w-100 justify-content-center">
                <div className="col-lg-5 col-md-7 col-10 d-flex justify-content-center">

                    <div style={{gap:"20px"}} className="row w-100 justify-content-center">
                        <div className="col-5 ">
                            <a rel="noopener noreferrer" target="_blank" href="https://libportal.nus.edu.sg/frontend/ms/c-j-koh-law-library/about-c-j-koh-law-library">
                                <button className="w-100 theme-btn-auto theme-hover">Find Out More</button>
                            </a>
                        </div>

                        <div className="col-5 ">
                            {isMobileOnly ? (
                                <button onClick={()=>{restartMobile()}} className="w-100 h-100 theme-btn-auto theme-hover">Play Again</button>
                            ):(
                                <button onClick={()=>{restart()}} className="w-100 h-100 theme-btn-auto theme-hover">Play Again</button>
                            )}
                        </div>
                    </div>
                </div>

            </div>
            <UsefulIcons displayQues={false} displayShare={vidEnd && !displayShareModal}  onClickShare={()=>setDisplayShareModal(true)} onClickRead={()=>handleReadMore()}></UsefulIcons>
        </div>

        
        <ShareModal display={displayShareModal} setDisplay={setDisplayShareModal} link={`${ROOT_URL}/library`} des={"Spread the library with your friends!"}></ShareModal>
        {topRightBtn}
    </div>)
}